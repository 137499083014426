import React, { useState, useEffect } from "react";
import {
  FaGavel,
  FaUser,
  FaPoll,
  FaBars,
} from "react-icons/fa";
import { RiDashboardFill, RiSettingsLine } from "react-icons/ri";
import { BiSolidUserBadge } from "react-icons/bi";
import { LiaUserLockSolid } from "react-icons/lia";
import { IoClose } from "react-icons/io5";
import { LuArrowLeftToLine } from "react-icons/lu";
import { MdExpandMore } from "react-icons/md";
import Logo from "../../../img/logo.svg";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ isCollapsed, onToggleCollapse }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const location = useLocation();

  const menuItems = [
    { name: "Dashboard", icon: <RiDashboardFill />, path: "/" },
    { name: "Bids", icon: <FaGavel />, path: "/bids" },
    { 
      name: "Buyers", 
      icon: <FaUser />, 
      subItems: [
        { name: "Individual", path: "/buyer/Indivisual" },
        { name: "Group", path: "/buyer/GroupBuyer" },
      ],
    },
    { 
      name: "Surveys", 
      icon: <FaPoll />, 
      subItems: [
        { name: "Survey List", path: "/surveys/list" },
        { name: "Surveyors", path: "/surveys/surveyors" },
      ],
    },
    { name: "Profile", icon: <BiSolidUserBadge />, path: "/profile" },
    { name: "Managers", icon: <LiaUserLockSolid />, path: "/managers" },
    { name: "Settings", icon: <RiSettingsLine />, path: "/setting" },
  ];

  useEffect(() => {
    const currentPath = location.pathname;

    const activeMenuItem = menuItems.find(item => item.path === currentPath);

    const activeSubItem = menuItems
      .flatMap(item => item.subItems || [])
      .find(subItem => subItem.path === currentPath);

    if (activeSubItem) {
      setActiveSubMenuItem(activeSubItem.name);
      setActiveItem(
        menuItems.find(item => item.subItems && item.subItems.some(sub => sub.path === activeSubItem.path))?.name
      );
      setOpenSubMenu(
        menuItems.find(item => item.subItems && item.subItems.some(sub => sub.path === activeSubItem.path))?.name
      );
    } else if (activeMenuItem) {
      setActiveItem(activeMenuItem.name);
      setOpenSubMenu(activeMenuItem.subItems ? activeMenuItem.name : null);
    } else {
      setActiveItem(null);
      setActiveSubMenuItem(null);
    }
  }, [location.pathname]);

  const handleItemClick = (item) => {
    setActiveItem(item.name);
    setOpenSubMenu(openSubMenu === item.name ? null : item.name);
    setActiveSubMenuItem(null);
  };

  const handleSubItemClick = (subItem) => {
    setActiveSubMenuItem(subItem.name);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={`flex flex-col transition-all duration-300 ${
      isCollapsed ? "lg:w-16" : "lg:w-64"
    } ${isMobileMenuOpen ? "w-60" : "w-16"} lg:w-auto`}>
      <div className="p-4 flex justify-between items-center">
        {!isCollapsed && <img src={Logo} alt="logo" className="lg:block mt-5 hidden" />}
        <button className="lg:hidden text-indigo-700" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <IoClose className="text-[25px]" /> : <FaBars className="text-[25px]" />}
        </button>
      </div>

      <nav className={`flex-1 p-4 ${isMobileMenuOpen ? "block" : "hidden"} lg:block`}>
        <ul className="space-y-2">
          {menuItems.map((item) => (
            <li key={item.name}>
              <div>
                <Link
                  to={item.path}
                  className={`flex items-center justify-between p-2 rounded-md ${
                    activeItem === item.name ? "bg-[#1955EB] text-white" : "text-[#9FACF0] hover:text-[#1955EB]"
                  }`}
                  onClick={() => handleItemClick(item)}
                >
                  <span className="flex items-center">
                    <span className="mr-2">{item.icon}</span>
                    {(!isCollapsed || isMobileMenuOpen) && <span>{item.name}</span>}
                  </span>
                  {item.subItems && (!isCollapsed || isMobileMenuOpen) && (
                    <MdExpandMore className={`transform text-[25px] ${openSubMenu === item.name ? "rotate-180" : ""}`} />
                  )}
                </Link>
                {item.subItems && openSubMenu === item.name && (!isCollapsed || isMobileMenuOpen) && (
                  <ul className="bg-white text-start p-4 shadow-lg pt-2 space-y-1">
                    {item.subItems.map((subItem) => (
                      <li key={subItem.name}>
                        <Link
                          to={subItem.path}
                          className={`block px-2 py-1 rounded-md ${
                            activeSubMenuItem === subItem.name
                              ? " text-[#1955EB] font-semibold"
                              : "text-[#9FACF0] hover:bg-[#1955EB] hover:text-white"
                          }`}
                          onClick={() => handleSubItemClick(subItem)}
                        >
                          {subItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </li>
          ))}
        </ul>
{/* 
        <div className="lg:hidden mt-auto">
          <button
            className="w-full bg-indigo-200 text-indigo-700 py-2 px-4 rounded-md flex items-center justify-center"
            onClick={onToggleCollapse}
          >
            <LuArrowLeftToLine className={`transform ${isCollapsed ? "rotate-180" : "rotate-0"}`} />
            {!isCollapsed && <span className="ml-2">Collapse</span>}
          </button>
        </div> */}
      </nav>

      <div className="p-4 lg:block md:hidden hidden">
        <button
          className="w-full bg-[#9FACF0] text-[20px] font-medium text-[#FFFFFF] py-2 px-4 rounded-[4px] flex items-center justify-center"
          onClick={onToggleCollapse}
        >
          <LuArrowLeftToLine className={`transform ${isCollapsed ? "rotate-180" : "rotate-0"}`} />
          {!isCollapsed && <span className="ml-2">Collapse</span>}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
