import React, { useEffect, useState } from 'react';
import { Table, Button, Checkbox, Input, Modal, Spin } from 'antd';
import { EyeOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { FaChevronDown, FaFilter } from 'react-icons/fa';
import { LuArrowUpDown } from 'react-icons/lu';
import { GoHome } from "react-icons/go";
import './SurveyRequest.css';
import { IoMdClose } from "react-icons/io";
import SurveyRequestsAction from "../surveyrequest/SurveyRequestAction"
import filterIcon from "../../../../assets/icons/filter.svg";

import Header from "../../../header"
import { Link } from 'react-router-dom';
import Bids from "../Bids"
import { getAllBidRequest, updateBidStatus } from '../../../../api/BidRequest';
import sortIcon from "../../../../assets/icons/sort.svg";
import { MdCircle, MdOutlineCircle } from 'react-icons/md';
import FilterModal from '../FilterModal';
import ErrorLogout from '../../../../helpers/ErrorLogout';
import Pagination from '../../../common/Pagination';

const { Search } = Input;

const SurveyRequests = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [searchText, setSearchText] = useState('');
  const [showSurveyRequestsAction, setShowSurveyRequestsAction] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showBids, setShowBids] = useState(false);
  const [searchValue, setSearchValue] = useState("")
  const [searchTerm, setSearchTerm] = useState(searchValue);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [subCategory, setSubCategory] = useState([]);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState(null);
  const [bidRange, setBidRange] = useState([1000, 10000000]);
  const [loader, setLoader] = useState(false);
  const [bids, setBids] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(5);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trigger, setTrigger] = useState(false)


  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };






  const [sortBy, setSortBy] = useState({
    key: "1",
    label: "Recently added",
    value: "createdDate:desc",

    icon: <MdCircle size={20} />,
  });



  const handleOpensetShowSurveyRequestsAction = () => {
    setShowSurveyRequestsAction(true);
  };


  useEffect(() => {
    const bidsCall = async () => {
      try {
        setLoader(true);
        const { data } = await getAllBidRequest({
          q: searchTerm,
          subCategory: subCategory,
          bidRange: bidRange,
          type: type === "Public" ? true : type === "Group" ? false : null,
          sort: sortBy.value,
          page: currentPage,
          limit: limit,

        });
        if (data?.success) {
          console.log(data);
          setBids(data?.bidList);
          setTotalPages(data?.totalPages);
          setCurrentPage(data?.currentPage);
          setTotalItems(data?.totalItems);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        console.log(error);
        console.log(error.message);
        if (error?.response?.status === 401) {
          ErrorLogout(error);
        }
      }
    };
    bidsCall();
  }, [searchTerm, sortBy, limit, currentPage, subCategory, type, bidRange, trigger]);

  if (showSurveyRequestsAction) {
    return <SurveyRequestsAction />;
  }



  const showModal = () => {
    setIsModalOpen(true);
  };


  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOpenBids = () => {
    setShowBids(true);
  };

  if (showBids) {
    return <Bids />;
  }
//  ------- FUNCTION DELETE AND APPROVE------------
const handleBidApproveStatus = async (id, status) => {
  try {
      Modal.confirm({
          title: `Confirm ${status.charAt(0).toUpperCase() + status.slice(1)} Status`,
          content: `Are you sure you want to ${status} this bid? This action cannot be undone.`,
          onOk: async () => {
              try {
                  const { data } = await updateBidStatus(id, status)
                  setTrigger(!trigger)
                  Modal.success({
                      title: 'Status Updated Successfully',
                      content: `The bid status has been updated to ${status} successfully.`,
                  });
              } catch (error) {
                  console.log(error);
                  console.log(error.message);
                  if (error?.response?.status === 401) {
                      ErrorLogout(error);
                  }
              }
          },
          onCancel: () => {
              console.log('Cancel');
          },
      });
  } catch (error) {
      console.log(error);
      console.log(error.message);
      if (error?.response?.status === 401) {
          ErrorLogout(error);
      }
  }
}

  // Function to handle button clicks

  const handleLimit = (e) => {
    const selectedValue = parseInt(e.target.value); // Convert value to integer if needed
    setLimit(selectedValue); // Update state with the selected value
    setCurrentPage(1);
  };


  // ------ SORT BY ITEMS--------
  const sortItems = [
    {
      key: "1",
      label: "Recently added",
      value: "createdDate:desc",
      icon:
        sortBy.key === "1" ? (
          <MdCircle
            size={18}
            className="text-blue-500 border w-fit rounded-full border-gray-500"
          />
        ) : (
          <MdOutlineCircle size={18} className="text-gray-500" />
        ),
    },
    {
      key: "2",
      label: "Price-High to Low",
      value: "bidLivePrice:desc",
      icon:
        sortBy.key === "2" ? (
          <MdCircle
            size={18}
            className="text-blue-500 border w-fit rounded-full border-gray-500"
          />
        ) : (
          <MdOutlineCircle size={18} className="text-gray-500" />
        ),
    },
    {
      key: "3",
      label: "Price-Low to High",
      value: "bidLivePrice:asc",
      icon:
        sortBy.key === "3" ? (
          <MdCircle
            size={18}
            className="text-blue-500 border w-fit rounded-full border-gray-500"
          />
        ) : (
          <MdOutlineCircle size={18} className="text-gray-500" />
        ),
    },
    {
      key: "4",
      label: "Most active",
      value: "bidCount:desc",
      icon:
        sortBy.key === "4" ? (
          <MdCircle
            size={18}
            className="text-blue-500 border w-fit rounded-full border-gray-500"
          />
        ) : (
          <MdOutlineCircle size={18} className="text-gray-500" />
        ),
    },
  ];

  const handleSort = (item) => {
    setSortBy(item);
  };
  const handleRemoveCategory = (categoryToRemove) => {
    setSubCategory(subCategory.filter((filter) => filter !== categoryToRemove));
  };

  const handleClearFilter = () => {
    setSubCategory([]);
    setType("All");
    setBidRange([1000, 100000]);
  };
  const onSearch = (value) => setSearchTerm(value);


  const columns = [
    {
      title: () => <Checkbox />,
      key: 'select',
      render: () => <Checkbox />,
      width: 50,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Surveyor Name
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'surveyorName',
      key: 'surveyorName',
      render: text => <span className="text-[#313131] text-[13px] font-medium">surveyorName</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Client Name
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'clientName',
      key: 'clientName',
      render: text => <span className="text-[#313131] text-[13px] font-medium">Client Name</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Product Title
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'productName',
      key: 'productName',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Category
          <FaFilter className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Expected Price
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'expectedPrice',
      key: 'expectedPrice',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: 'Bid',
      key: 'bid',
      render: (_, record) => (
        <>
          <Button 
            onClick={() => handleBidApproveStatus(record._id, "live")}
            type="primary" className="bg-[#1955EB] hover:border-[#1955EB] text-white text-[13px] font-semibold">
              Approve as Bid
          </Button>
          {/* Add another button here if needed */}
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: () => (
        <div className="flex space-x-2">

          {/* <Link to="/surveyrequstaction"> */}
          <Button icon={<EyeOutlined />} onClick={handleOpensetShowSurveyRequestsAction} className="bg-[#0445C3] text-white hover:border-[#0445C3]" />
          {/* </Link> */}
          <Button icon={<DeleteOutlined />} onClick={showModal} className="bg-[#FF4545] text-white hover:border-[#FF4545]" />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base">
          <Link to="/">    <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          {/* <Link to="/bids"> */}
          <span onClick={handleOpenBids}
            className="ml-2 text-[#C7C7C7] cursor-pointer text-base md:text-lg font-medium">
            / Bids
          </span>
          {/* </Link> */}
          <span className="ml-2 text-[#313131] cursor-pointer text-base md:text-lg font-medium">
            / Survey Request
          </span>
        </div>
      </div>
      {/* ------- FILTER-------- */}
      <div className="lg:flex md:flex lg:space-x-2 ml-auto items-center mb-4">
        <div className="lg:flex items-center gap-3 ">
          <div>
            <Search
              defaultValue={searchValue}
              placeholder="Search Bids, Category etc..."
              allowClear
              onSearch={onSearch}
              className="w-80 md:w-80 lg:w-80 "

            />
          </div>
          <div className="flex space-x-2 lg:mt-0 md:mt-0 mt-3 items-center">
            <button className="bg-white flex lg:w-48 md:w-48 text-[14px] lg:text-[16px] md:text-[16px]   gap-1 items-center lg:h-full md:h-10 h-10 rounded-[4px] lg:px-3 md:px-2 px-2 lg:py-[5px] md:py-1  border-[#A3BBFF] font-normal  border-[1px]">
              <div className="relative inline-block">
                <div
                  className="flex items-center cursor-pointer lg:px-7 md:px-7 "
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <img src={sortIcon} alt="Sort Icon" className="mr-2" />
                  Sort By
                  <FaChevronDown
                    className={`ml-2 transition-transform duration-300 ${isOpen ? "transform rotate-180" : ""
                      }`}
                  />
                </div>
                {isOpen && (
                  <ul className="absolute lg:left-0  lg:right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
                    {sortItems.map((item) => (
                      <li
                        key={item.key}
                        onClick={() => handleSort(item)}
                        className="flex items-center p-2 hover:bg-gray-100 hover:font-medium hover:text-gray-900 cursor-pointer"
                      >
                        <span className="mr-2">{item.icon}</span>
                        <span className="text-sm text-gray-700">
                          {item.label}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </button>
            <div className="flex items-center gap-3 lg:mt-0  md:mt-0">
              <button
                className="bg-white flex gap-1 items-center rounded-[4px] text-[14px] lg:text-[16px] md:text-[16px] lg:h-full md:h-10 h-10 lg:px-5 md:px-5 px-3 lg:py-[3px]  border-[#A3BBFF] font-normal  border-[1px]"
                onClick={() => setIsModalVisible(true)}
              >
                <img src={filterIcon} alt="" />
                Filter
              </button>

            </div>

          </div>

          <div className="flex justify-between">
            <div className="flex space-x-2 ">
              {subCategory.map((filter, index) => (
                <div
                  key={index}
                  className="flex items-center bg-gray-200 lg:px-4 lg:py-2 md:px-4 md:py-2  rounded cursor-pointer"
                  onClick={() => handleRemoveCategory(filter)}
                >
                  <span>{filter}</span>
                  <span className="ml-2 text-red-500 font-bold">&times;</span>
                </div>
              ))}
            </div>
            <div className="flex justify-end ml-2">
              {subCategory.length > 0 ? (
                <button
                  className="text-[#1955EB] underline mr-6  lg:text-[20px] md:text-[20px] font-semibold mt-4"
                  onClick={() => handleClearFilter()}
                >
                  Clear Filter
                </button>
              ) : (
                ""
              )}

            </div>
          </div>
        </div>
      </div>

      <Spin spinning={loader}>
        <div className="pt-[0px] ">
          <Table
            columns={columns}
            dataSource={bids}
            pagination={false} // Disable pagination
         
            className="custom-table-header lg:w-full overflow-auto w-[280px]"
          />
             <div className="">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              limit={limit}
              totalItem={totalItems}
              onPageChange={handlePageChange}
              handleLimit={handleLimit}

            />
          </div>

          <FilterModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            setSubCategory={setSubCategory}
            subCategory={subCategory}
            type={type}
            setType={setType}
            bidRange={bidRange}
            setBidRange={setBidRange}
          />
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            className=""
          >
            <div className="flex flex-col items-center py-3">
              <div className="flex items-center mb-4">
                <IoMdClose className="text-white p-1 rounded-full bg-[#FF4545] text-4xl" />
              </div>
              <div className="text-center text-[16px] font-medium">
                <p className="text-[#C7C7C7] text-[16px] font-medium">Survey #4345892394</p>
                <p className="text-[#313131] text-[24px] font-medium">Survey Request Rejected</p>
              </div>
              <div className="flex justify-center items-center gap-4 w-full  mt-5">
                <Input
                  type="text"
                  placeholder="Write reason"
                  className="border border-[#9FACF0] h-10 rounded-[8px] p-2 text-[16px] "
                />

                <div className="flex">
                  <Button onClick={handleCancel} className="bg-[#1955EB] lg:w-28 rounded-[8px] h-10 text-white text-[18px] font-medium">
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div >
          </Spin>
      </>
      );
};

      export default SurveyRequests;
