import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, DatePicker, TimePicker, message } from 'antd';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { GoHome } from "react-icons/go";
import { Link } from 'react-router-dom';
import Survey from "./Survey";
import { getSurveyorNames } from '../../../api/SurveyorApiRequest';
import moment from 'moment'; // Make sure to import moment
import { createSurvey, getSingleSurvey, updateSurvey } from '../../../api/SurveyRequest';

const { TextArea } = Input;
const { Option } = Select;

const AssignSurveyForm = ({page, id}) => {
  const [form] = Form.useForm();
  const [showSurveyList, setShowSurveyList] = useState(false);
  const [surveyors, setSurveyors] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getSurveyorNames();
        setSurveyors(data.surveyors);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch surveyors");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        setBtnLoader(true)
        const { data } = await getSingleSurvey(id);
        if (data.success && data.singleSurvey) {
          const survey = data.singleSurvey;
          const completionDateTime = moment(survey.completionDate);
          
          form.setFieldsValue({
            surveyor: survey.surveyorID,
            clientName: survey.clientName,
            clientEmail: survey.clientEmail,
            phonePrimary: survey.phonePrimary.toString(),
            phoneSecondary: survey.phoneSecondary ? survey.phoneSecondary.toString() : undefined,
            instruction: survey.instruction,
            locationUrl: survey.locationUrl,
            address: survey.address,
            completionDate: completionDateTime,
            completionTime: completionDateTime,
          });
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
        message.error("Failed to fetch survey data");
      } finally {
        setBtnLoader(false)
      }
    };

    if (page === 'edit' && id) {
      fetchSurvey();
    }
  }, [page, id, form]);

  const handleOpenSurveyList = () => {
    setShowSurveyList(true);
  };

  const onFinish = async (values) => {
    try {
      const completionDateTime = moment(`${values.completionDate.format('YYYY-MM-DD')} ${values.completionTime.format('HH:mm')}`);
      
      const surveyData = {
        surveyorID: values.surveyor,
        clientName: values.clientName,
        clientEmail: values.clientEmail,
        phonePrimary: values.phonePrimary,
        phoneSecondary: values.phoneSecondary,
        instruction: values.instruction,
        locationUrl: values.locationUrl,
        address: values.address,
        completionDate: completionDateTime.toISOString(),
      };
      if(page==="edit" && id){
        await updateSurvey(id,surveyData )
        // message.success('Surveyor updated successfully');
        // setShowSurveyList(true)
      }else {
        const response = await createSurvey(surveyData)// Adjust the URL as needed
        // message.success('Surveyor updated successfully');
        // setShowSurveyList(true)
        console.log(response);
      }
     
      
      message.success('Survey assigned successfully');
      setShowSurveyList(true)
      form.resetFields();
    } catch (error) {
      console.error('Error creating survey:', error);
      message.error('Failed to assign survey');
    }
  };

  if (showSurveyList) {
    return <Survey />;
  }

  return (
    <div className="">
      {/* ... (previous code for breadcrumbs) ... */}
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
          <Link to="/"> <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          <span onClick={handleOpenSurveyList} className="ml-2 cursor-pointer text-[#C7C7C7] text-base md:text-lg font-medium">
            / Surveys-Survey list
          </span>
          <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
            / Assign Survey
          </span>
        </div>
      </div>
      <Form form={form} layout="vertical" className="space-y-6" onFinish={onFinish}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:w-2/3 gap-6">
          <div>
            <Form.Item
              label="Surveyor"
              name="surveyor"
              className="col-span-1"
              labelCol={{ className: 'text-[#4A4A4C] text-start text-[14px] font-medium' }}
              rules={[{ required: true, message: 'Please select a surveyor' }]}
            >
              <Select
                placeholder="Select Surveyor"
                className="w-full border-[#A3BBFF] text-start h-10"
              >
                {surveyors.map(surveyor => (
                  <Option key={surveyor._id} value={surveyor._id}>{surveyor.name}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Completion Date"
              name="completionDate"
              className="col-span-1"
              labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
              rules={[{ required: true, message: 'Please select a completion date' }]}
            >
              <DatePicker
                placeholder="DD/MM/YYYY"
                suffixIcon={<CalendarOutlined />}
                className="w-full border-[#A3BBFF] h-10"
              />
            </Form.Item>

            <Form.Item
              label="Completion Time"
              name="completionTime"
              className="col-span-1"
              labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
              rules={[{ required: true, message: 'Please select a completion time' }]}
            >
              <TimePicker
                placeholder="HH:MM"
                suffixIcon={<ClockCircleOutlined />}
                className="w-full border-[#A3BBFF] h-10"
              />
            </Form.Item>
          </div>

          <div className="w-full flex items-end">
            <Form.Item
              label="Instructions to Surveyor"
              name="instruction"
              className="w-full"
              labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
            >
              <TextArea rows={4} placeholder="Write your instructions" className="w-full border-[#A3BBFF]" />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          label="Client Name"
          name="clientName"
          className="col-span-1 w-2/3"
          labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
          rules={[{ required: true, message: 'Please enter client name' }]}
        >
          <Input placeholder="Enter client name" className="border-[#A3BBFF] h-10" />
        </Form.Item>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:w-2/3 gap-3">
          <div>
            <Form.Item
              label="Contact Number"
              name="phonePrimary"
              className="col-span-1"
              labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
              rules={[{ required: true, message: 'Please enter contact number' }]}
            >
              <Input placeholder="Enter contact number" className="border-[#A3BBFF] h-10" />
            </Form.Item>

            <Form.Item
              label="Additional Number"
              name="phoneSecondary"
              className="col-span-1"
              labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
            >
              <Input placeholder="Enter additional number" className="border-[#A3BBFF] h-10" />
            </Form.Item>

            <Form.Item
              label="Email Id"
              name="clientEmail"
              className="col-span-1"
              labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
              rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input placeholder="Enter email id" className="border-[#A3BBFF] h-10" />
            </Form.Item>

            <Form.Item
              label="Location"
              name="locationUrl"
              className="col-span-1"
              labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
            >
              <Input placeholder="Enter location URL" className="border-[#A3BBFF] h-10" />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              label="Address"
              name="address"
              className="col-span-1"
              labelCol={{ className: 'text-[#4A4A4C] text-[14px] font-medium' }}
              rules={[{ required: true, message: 'Please enter address' }]}
            >
              <TextArea rows={4} placeholder="Enter address" className="border-[#A3BBFF]" />
            </Form.Item>
          </div>
        </div>

        <div className="mt-6 flex flex-col md:flex-row md:space-x-4">
        
           <Button loading={btnLoader} onClick={() => form.resetFields()} className="w-full md:w-1/2 h-10 border font-semibold border-[#313131] text-[#313131] rounded-lg text-sm md:text-base">
            Discard
          </Button>
          <Button loading={btnLoader} type="primary" htmlType="submit" className="mt-4 md:mt-0 w-full md:w-1/2 h-10 text-white text-sm md:text-base font-semibold rounded-lg">
            {page === 'edit' ? 'Update' : 'Assign'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AssignSurveyForm;