import { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

import PropTypes from "prop-types";
import pdfIcon from "../../../../assets/icons/pdf.svg";
import downloadIcon from "../../../../assets/icons/download.svg";

function Accordion({ description, specification, files }) {
  const [isOpen, setIsOpen] = useState(false);

  const [isOpen1, setIsOpen1] = useState(false);

  const [isOpen2, setIsOpen2] = useState(false);
  // const handleDownload = (url, key) => {
  //   const filename = `${key}.pdf`; // Replace with desired file name
  //   console.log(url);
    
  //   downloadFile(url, filename);
  // };
  // const downloadFile = (url, filename) => {
  //   fetch(url)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const blobURL = window.URL.createObjectURL(new Blob([blob]));
  //       const a = document.createElement("a");
  //       a.href = blobURL;
  //       a.download = filename;
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //     })
  //     .catch((error) => console.error("Error downloading file:", error));
  // };
  const handleDownload = (url , key) => {
    // console.log(`Attempting to open: ${url}`);
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
      <div className=" rounded-md mb-4 mt-5">
        <div
          className="flex justify-between items-center px-10 py-5 border-b border-[#000000] cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="text-[#313131]  font-semibold lg:text-[24px] md:text-[24px] text-[20px]">
            Description
          </div>
          <div className="text-gray-600">
            {isOpen ? (
              <IoIosArrowUp className="text-[#0057FF] text-[25px]" />
            ) : (
              <IoIosArrowDown className="text-[#0057FF] text-[25px]" />
            )}
          </div>
        </div>
        {isOpen && (
          <div className="px-10 py-10 text-[#878787] text-[16px] ">
            <p className="mb-4">{description}</p>
          </div>
        )}
      </div>
      <div className=" rounded-md mb-4">
        <div
          className="flex justify-between items-center px-10 py-5 border-b border-[#000000] cursor-pointer"
          onClick={() => setIsOpen1(!isOpen1)}
        >
          <div className="text-[#313131]  font-semibold lg:text-[24px] md:text-[24px] text-[20px]">
            Specifications
          </div>
          <div className="text-gray-600">
            {isOpen1 ? (
              <IoIosArrowUp className="text-[#0057FF] text-[25px]" />
            ) : (
              <IoIosArrowDown className="text-[#0057FF] text-[25px]" />
            )}
          </div>
        </div>
        {isOpen1 && (
          <div className="px-10 py-10">
            {/* <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-8">
              <div>
                <div className="flex items-center gap-3">
                  <FaCar className="text-[#878787] text-[30px] " />
                  <p className="text-[#4A4A4C] font-normal text-[16px]">Make</p>
                </div>
                <p className="text-[#313131] pt-2 text-[24px] font-semibold">
                  Dodge
                </p>
              </div>
              <div>
                <div className="flex items-center gap-3">
                  <FaCarSide className="text-[#878787] text-[30px] " />
                  <p className="text-[#4A4A4C] font-normal text-[16px]">
                    Model
                  </p>
                </div>
                <p className="text-[#313131] pt-2 text-[24px] font-semibold">
                  Challenger SRT
                </p>
              </div>
              <div>
                <div className="flex items-center gap-3">
                  <FaCalendarAlt className="text-[#878787] text-[30px] " />
                  <p className="text-[#4A4A4C] font-normal text-[16px]">Year</p>
                </div>
                <p className="text-[#313131] pt-2 text-[24px] font-semibold">
                  1999
                </p>
              </div>
              <div>
                <div className="flex items-center gap-3">
                  <FaCogs className="text-[#878787] text-[30px] " />
                  <p className="text-[#4A4A4C] font-normal text-[16px]">
                    Transmission
                  </p>
                </div>
                <p className="text-[#313131] pt-2 text-[24px] font-semibold">
                  Manual
                </p>
              </div>
              <div className="mt-5">
                <div className="flex items-center gap-3">
                  <RiDoorOpenFill className="text-[#878787] text-[30px] " />
                  <p className="text-[#4A4A4C] font-normal text-[16px]">
                    Doors
                  </p>
                </div>
                <p className="text-[#313131] pt-2 text-[24px] font-semibold">
                  2
                </p>
              </div>
              <div className="mt-5">
                <div className="flex items-center gap-3">
                  <FaGasPump className="text-[#878787] text-[30px] " />
                  <p className="text-[#4A4A4C] font-normal text-[16px]">
                    Mileage
                  </p>
                </div>
                <p className="text-[#313131] pt-2 text-[24px] font-semibold">
                  8 KMPL
                </p>
              </div>
            </div> */}

            <div className="space-y-2">
              {specification.map((item, index) => (
                <div key={index} className="flex items-center py-2 gap-4">
                  <p className="text-[#4A4A4C] text-[16px] w-[200px] font-medium">
                    {item.name}
                  </p>
                  <p className="text-[#313131] text-[15px] font-medium">
                    {item.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
     {files.length>0&& <div className=" rounded-md mb-4">
        <div
          className="flex justify-between items-center px-10 py-5 border-b border-[#000000] cursor-pointer"
          onClick={() => setIsOpen2(!isOpen2)}
        >
          <div className="text-[#313131]  font-semibold lg:text-[24px] md:text-[24px] text-[20px]">
            Inspection Report and certification
          </div>
          <div className="text-gray-600">
            {isOpen2 ? (
              <IoIosArrowUp className="text-[#0057FF] text-[25px]" />
            ) : (
              <IoIosArrowDown className="text-[#0057FF] text-[25px]" />
            )}
          </div>
        </div>
        {/* {isOpen2 && (
          <div className="grid grid-cols-1 sm:grid-cols-4 px-10 py-10 gap-56">
            {files.map((file, index) => {
              console.log(file,"2121212");
              
              return (
                <button
                  onClick={() => handleDownload(file.url, file.key)}
                  key={index}
                  className="flex items-center gap-3  justify-between w-80  px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <img src={pdfIcon} alt="" />

                  <p className="text-[#4A4A4C] text-[20px] font-medium">
                    {file.key}
                  </p>
                  <img src={downloadIcon} alt="" />
                </button>
              );
            })}
          </div>
        )} */}
         {isOpen2 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
          {files.map((file, index) => (
            <button
              key={index}
              onClick={() => handleDownload(file.url, file.key)}
              className="flex items-center justify-between w-full px-4 py-3 bg-white border border-gray-300 rounded-lg shadow-sm text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            >
              <div className="flex items-center space-x-3">
                <img src={pdfIcon} alt="PDF" className="w-6 h-6" />
                <span className="text-[#4A4A4C] font-medium truncate">
                  {file.key}
                </span>
              </div>
              <img src={downloadIcon} alt="Download" className="w-5 h-5" />
            </button>
          ))}
        </div>
      )}
 ;
      </div>
}
    </>
  );
}

Accordion.propTypes = {
  description: PropTypes.object,
  specification: PropTypes.object,
  files: PropTypes.object,
};
export default Accordion;
