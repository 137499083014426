import React, { useEffect, useState } from 'react';
import { GoHome } from "react-icons/go";

import { FaChevronDown } from 'react-icons/fa';

import SurveyRequests from './surveyrequest/SurveyRequets';
import { Link } from 'react-router-dom';
import sortIcon from "../../../assets/icons/sort.svg";
import filterIcon from "../../../assets/icons/filter.svg";




import { MdCircle, MdOutlineCircle } from 'react-icons/md';
import FilterModal from './FilterModal';
import { Input, Spin } from 'antd';
import ErrorLogout from '../../../helpers/ErrorLogout';
import { getAllBid } from '../../../api/BidRequest';
import Pagination from '../../common/Pagination';
import ProductCard from './ProductCard';
import SingleBidDetails from './singleBid/SingleBidDetails';

const { Search } = Input;


const TabBar = () => {
  
    const [activeTab, setActiveTab] = useState( '' ); // Update initial state to 'all'
    const [tabFilter, setTabFilter] = useState(''); // Update initial state to 'all'
    // ...
  // const tabs = ['All', 'Live', 'On Hold', 'Cancelled'];
  const tabs = [
    { label: 'All', value: '' },
    { label: 'Live', value: 'live' },
    { label: 'On Hold', value: 'hold' },
    { label: 'Cancelled', value: 'cancel' },
  ];
  const [showSurveyRequest, setShowSurveyRequest] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState({
    key: "1",
    label: "Recently added",
    value: "createdDate:desc",

    icon: <MdCircle size={20} />,
  });
  const [searchValue, setSearchValue] = useState("")
  const [subCategory, setSubCategory] = useState([]);
  const [type, setType] = useState(null);
  const [bidRange, setBidRange] = useState([1000, 10000000]);
  const [searchTerm, setSearchTerm] = useState(searchValue);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false)
  const [bids, setBids] = useState([]);
  const [totalPages, setTotalPages] = useState(5);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [trigger, setTrigger] = useState(false);
  const [showSingleProduct, setShowSingleProduct] = useState(false)
  const [bidID, setBidID] = useState("")

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Function to handle button clicks

  const handleLimit = (e) => {
    const selectedValue = parseInt(e.target.value); // Convert value to integer if needed
    setLimit(selectedValue); // Update state with the selected value
    setCurrentPage(1);
  };


  // ------ SORT BY ITEMS--------
  const sortItems = [
    {
      key: "1",
      label: "Recently added",
      value: "createdDate:desc",
      icon:
        sortBy.key === "1" ? (
          <MdCircle
            size={18}
            className="text-blue-500 border w-fit rounded-full border-gray-500"
          />
        ) : (
          <MdOutlineCircle size={18} className="text-gray-500" />
        ),
    },
    {
      key: "2",
      label: "Price-High to Low",
      value: "bidLivePrice:desc",
      icon:
        sortBy.key === "2" ? (
          <MdCircle
            size={18}
            className="text-blue-500 border w-fit rounded-full border-gray-500"
          />
        ) : (
          <MdOutlineCircle size={18} className="text-gray-500" />
        ),
    },
    {
      key: "3",
      label: "Price-Low to High",
      value: "bidLivePrice:asc",
      icon:
        sortBy.key === "3" ? (
          <MdCircle
            size={18}
            className="text-blue-500 border w-fit rounded-full border-gray-500"
          />
        ) : (
          <MdOutlineCircle size={18} className="text-gray-500" />
        ),
    },
    {
      key: "4",
      label: "Most active",
      value: "bidCount:desc",
      icon:
        sortBy.key === "4" ? (
          <MdCircle
            size={18}
            className="text-blue-500 border w-fit rounded-full border-gray-500"
          />
        ) : (
          <MdOutlineCircle size={18} className="text-gray-500" />
        ),
    },
  ];

  const handleSort = (item) => {
    setSortBy(item);
  };
  const handleRemoveCategory = (categoryToRemove) => {
    setSubCategory(subCategory.filter((filter) => filter !== categoryToRemove));
  };

  const handleClearFilter = () => {
    setSubCategory([]);
    setType("All");
    setBidRange([1000, 100000]);
  };
  const onSearch = (value) => setSearchTerm(value);

  useEffect(() => {
    const bidsCall = async () => {
      try {
        setLoader(true);
        const { data } = await getAllBid({
          q: searchTerm,
          subCategory: subCategory,
          bidRange: bidRange,
          type: type === "Public" ? true : type === "Group" ? false : null,
          sort: sortBy.value,
          page: currentPage,
          limit: limit,
          bidStatus: tabFilter
        });
        if (data?.success) {
        
          setBids(data?.bidList);
          setTotalPages(data?.totalPages);
          setCurrentPage(data?.currentPage);
          setTotalItems(data?.totalItems);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        console.log(error);
        console.log(error.message);
        if (error?.response?.status === 401) {
          ErrorLogout(error);
        }
      }
    };
    bidsCall();
  }, [searchTerm, sortBy, limit, currentPage, subCategory, type, bidRange, tabFilter, trigger]);

  const handleOpenSurveyRequest = () => {
    setShowSurveyRequest(true);
  };



  if (showSurveyRequest) {
    return <SurveyRequests />;
  }

  if (showSingleProduct) {
    return <SingleBidDetails setShowSingleProduct={setShowSingleProduct} bidID={bidID} />
  }

  return (
    <>
      <div >
        <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white  ">
          <div className="mb-4 flex items-center text-sm md:text-base">
            <Link to="./">   <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
            <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
              / Bids
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex flex-wrap border border-[#9FACF0]  p-2 w-full md:w-fit rounded-[8px] justify-between mb-6">
              {tabs.map((tab) => (
                <button
                  key={tab.value}
                  className={`px-4 py-2 mb-2 md:mb-0 md:mr-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
                  ${activeTab === tab.value
                      ? "bg-[#1955EB] text-white font-medium"
                      : "bg-[#F6F8FF] text-black font-medium hover:bg-[#F6F8FF]"}`}
                  onClick={() => {
                    setActiveTab(tab.value)
                    setTabFilter(tab.value)
                    setCurrentPage(1);

                  }}
                >
                  {tab.label}
                </button>
              ))}
            </div>
            {/* <Link to="/surveyrequest">  */}
            <div className="flex items-center flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2 border border-[#9FACF0] p-2 w-full md:w-fit rounded-[8px] justify-between mb-6">
              <button className="px-4 py-2 text-black text-[16px] font-semibold w-full md:w-auto">
                Survey Requests <span className='text-[#1955EB]'>21</span>
              </button>
              <button
                className="px-5 py-2 text-[16px] font-medium text-white bg-[#1955EB] rounded-md hover:bg-white hover:text-[#1955EB] w-full md:w-auto"
                onClick={handleOpenSurveyRequest}
              >
                Open
              </button>
            </div>
            {/* </Link> */}
          </div>
        </div>
        <Spin spinning={loader}>
          <div className="pt-[5px]  space-y-10">

            {/* -------------- FILTER------------ */}

            <div className="lg:flex md:flex lg:space-x-2 ml-auto items-center ">
              <div className="lg:flex items-center gap-3 ">
                <div>
                  <Search
                    defaultValue={searchValue}
                    placeholder="Search Bids, Category etc..."
                    allowClear
                    onSearch={onSearch}
                    className="w-80 md:w-80 lg:w-80 "

                  />
                </div>
                <div className="flex space-x-2 lg:mt-0 md:mt-0 mt-3 items-center">
                  <button className="bg-white flex lg:w-48 md:w-48 text-[14px] lg:text-[16px] md:text-[16px]   gap-1 items-center lg:h-full md:h-10 h-10 rounded-[4px] lg:px-3 md:px-2 px-2 lg:py-[5px] md:py-1  border-[#A3BBFF] font-normal  border-[1px]">
                    <div className="relative inline-block">
                      <div
                        className="flex items-center cursor-pointer lg:px-7 md:px-7 "
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <img src={sortIcon} alt="Sort Icon" className="mr-2" />
                        Sort By
                        <FaChevronDown
                          className={`ml-2 transition-transform duration-300 ${isOpen ? "transform rotate-180" : ""
                            }`}
                        />
                      </div>
                      {isOpen && (
                        <ul className="absolute lg:left-0  lg:right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
                          {sortItems.map((item) => (
                            <li
                              key={item.key}
                              onClick={() => handleSort(item)}
                              className="flex items-center p-2 hover:bg-gray-100 hover:font-medium hover:text-gray-900 cursor-pointer"
                            >
                              <span className="mr-2">{item.icon}</span>
                              <span className="text-sm text-gray-700">
                                {item.label}
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </button>
                  <div className="flex items-center gap-3 lg:mt-0  md:mt-0">
                    <button
                      className="bg-white flex gap-1 items-center rounded-[4px] text-[14px] lg:text-[16px] md:text-[16px] lg:h-full md:h-10 h-10 lg:px-5 md:px-5 px-3 lg:py-[3px]  border-[#A3BBFF] font-normal  border-[1px]"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <img src={filterIcon} alt="" />
                      Filter
                    </button>

                  </div>

                </div>

                <div className="flex justify-between">
                  <div className="flex space-x-2 ">
                    {subCategory.map((filter, index) => (
                      <div
                        key={index}
                        className="flex items-center bg-gray-200 lg:px-4 lg:py-2 md:px-4 md:py-2  rounded cursor-pointer"
                        onClick={() => handleRemoveCategory(filter)}
                      >
                        <span>{filter}</span>
                        <span className="ml-2 text-red-500 font-bold">&times;</span>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end ml-2">
                    {subCategory.length > 0 ? (
                      <button
                        className="text-[#1955EB] underline mr-6  lg:text-[20px] md:text-[20px] font-semibold mt-4"
                        onClick={() => handleClearFilter()}
                      >
                        Clear Filter
                      </button>
                    ) : (
                      ""
                    )}

                  </div>
                </div>
              </div>
            </div>

            {/* {activeTab === 'All' && ( */}
            <div className="flex flex-col  overflow-auto  space-y-4 md:space-y-10">

              {bids.map(item => (
                <ProductCard item={item} trigger={trigger} setTrigger={setTrigger} setShowSingleProduct={setShowSingleProduct} setBidID={setBidID} />

              ))}
            </div>
            {/* )} */}
            {/* Pagination Component */}

          </div>
          <div className=" mt-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              limit={limit}
              totalItem={totalItems}
              onPageChange={handlePageChange}
              handleLimit={handleLimit}

            />
          </div>
          <FilterModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            setSubCategory={setSubCategory}
            subCategory={subCategory}
            type={type}
            setType={setType}
            bidRange={bidRange}
            setBidRange={setBidRange}
          />
        </Spin>
      </div>
    </>
  );
};

export default TabBar;
