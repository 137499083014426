
import Accordian from "./Accordian";

import { BiCategory } from "react-icons/bi";
import { IoMdTime } from "react-icons/io";
import { HiOutlineUserGroup } from "react-icons/hi";
import { SlCalender } from "react-icons/sl";


import { PiCurrencyInrBold} from "react-icons/pi";
import { useTimer } from "react-timer-hook";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { getSingleBid } from "../../../../api/BidRequest";
import ErrorLogout from "../../../../helpers/ErrorLogout";
import { GoHome } from "react-icons/go";
import { Link } from "react-router-dom";



function SingleBidDetails({setShowSingleProduct, bidID}) {

  
  const [singleBid, setSingleBid] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setMainImage(singleBid?.productImage[0]);
  }, [singleBid]);
  useEffect(() => {
    if(bidID === "") {
        setShowSingleProduct(false);
      return; // exit the function early
    } else{
        const bidCall = async () => {
            try {
              setLoader(true);
      
              const { data } = await getSingleBid(bidID);
              console.log(data, "data");
              if (data?.success) {
                setLoader(false);
                setSingleBid(data?.singleBid);
              }
            } catch (error) {
              setLoader(false);
      
              console.log(error);
              console.log(error.message);
              if (error?.response?.status === 401) {
                ErrorLogout(error);
              }
            }
          };
          bidCall();
    }
   
   
  }, [bidID]);

 
  const expiryTimestamp = new Date(singleBid?.bidEnd);
  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  console.log(seconds, minutes, hours, days, isRunning);
  if (!singleBid) {
    return <div>Loading...</div>;
  }
  return (
    <Spin spinning={loader}>
      <div className="container">
         <div className="mb-4 flex items-center text-sm md:text-base">
          <Link to="/">    <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          {/* <Link to="/bids"> */}
          <span 
          onClick={() => setShowSingleProduct(false)}
            className="ml-2 text-[#C7C7C7] cursor-pointer text-base md:text-lg font-medium">
            / Bids
          </span>
          {/* </Link> */}
          <span className="ml-2 text-[#313131] cursor-pointer text-base md:text-lg font-medium">
            / {bidID}
          </span>
        </div>

    
        <div className="lg:flex md:flex lg:space-x-6 mt-[30px]">
          <div className="lg:w-[50%]  object-contain mx-auto lg:p-3">
            <div className="mb-4 ">
              <img
                src={mainImage}
                alt="img"
                className="w-full h-[370px] object-cover rounded-lg shadow-lg"
              />
            </div>

            <div className="grid grid-cols-5 mt-5 gap-2">
              {singleBid.productImage.map((img, index) => (
                <button
                  key={index}
                  onClick={() => setMainImage(img)}
                  className={`focus:outline-none ${
                    mainImage === img ? "" : ""
                  }`}
                >
                  <img
                    src={img}
                    alt="img"
                    className="w-full h-20 object-cover rounded-md"
                  />
                </button>
              ))}
            </div>
          </div>
          <div className="">
            <div className="bg-[#EAEFFF] px-5 mt-2 py-1 rounded w-fit">
              <p className="text-[#313131] text-[16px] font-medium ">
                Lot #{singleBid._id}
              </p>
            </div>
            <div className="flex mt-[50px] lg:mt-0 md:mt-0 justify-between ">
              <div>
                <p className="lg:text-[40px] md:text-[35px] text-[25px] font-bold ">
                  {singleBid.productName}
                </p>
              </div>

           
            </div>
            <div className="flex  mt-[40px] items-center lg:gap-[80px] md:gap-[50px] gap-5 mb-4">
              <div className="flex items-center gap-5">
                <HiOutlineUserGroup className="text-[#1955EB] w-[32px] h-[32px]" />

                <div>
                  <p className="text-[#878787] text-[16px] font-normal">
                    No of bidders
                  </p>
                  <p className="text-[#313131] font-semibold text-[20px]">
                    {singleBid?.bidCount}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-5 ">
                <IoMdTime className="text-[#1955EB] w-[32px] h-[32px]" s />

                <div>
                  <p className="text-[#878787] text-[16px] font-normal">
                    Time Remaining
                  </p>
                  <p className="text-[#313131] font-semibold text-[20px]">
                    {isRunning
                      ? `${days}d: ${hours}h: ${minutes}m: ${seconds}s`
                      : "Expired"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center lg:gap-[90px] md:gap-[50px] gap-10 mt-[40px] mb-4">
              <div className="flex items-center gap-5 mb-4">
                <SlCalender className="text-[#1955EB] w-[32px] h-[32px]" />

                <div>
                  <p className="text-[#878787] text-[16px] font-normal">
                    Ending Date
                  </p>
                  <p className="text-[#313131] font-semibold text-[20px]">
                    {moment(singleBid?.bidEnd).format("DD-MM-YYYY")}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-5 mb-4">
                <BiCategory className="text-[#1955EB] w-[32px] h-[32px]" />

                <div>
                  <p className="text-[#878787] text-[16px] font-normal">
                    Category
                  </p>
                  <p className="text-[#313131] font-semibold text-[20px]">
                    {singleBid?.category}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-5 items-center  justify-between mt-[40px]">
              <div className="">
                <p className="text-[16px] text-black">Current Bid</p>
                <p className=" flex items-center lg:text-[32px] md:text-[22px] text-[18px] font-semibold">
                  {" "}
                  <PiCurrencyInrBold />
                  {singleBid?.bidLivePrice}
                </p>
              </div>

             
            </div>
          </div>
        </div>

        <Accordian
          className="mt-[60px]"
          description={singleBid?.productDescription}
          specification={singleBid?.productSpecification}
          files={singleBid?.files}
        />

      
  
      </div>

    </Spin>
  );
}

export default SingleBidDetails;
