import { useState } from "react";
import { Input, Button, message, Form } from "antd";
import PropTypes from "prop-types";
import { forgotPassword } from "../../../api/CommonRequest";

function EnterPhoneEmail({ setStep, setEmail, setLoginView }) {
  const [loader, setLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const errorMessage = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const handleSendOTP = async (email) => {
    // console.log("email", email);
    try {
      setLoader(true);
      const { data } = await forgotPassword(email);
      if (data?.success) {
        setLoader(false);
        setEmail(email?.email);
        setStep(2);
      } else {
        errorMessage(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      errorMessage(error.message);

      setLoader(false);
    }
  };

  return (
    <>
      <div className="w-full lg:w-4/5 md:w-4/5 mx-auto">
        <h1 className="lg:text-[40px] md:text-[35px] text-[25px] font-bold mb-3 lg:text-start md:text-start text-center">
          Recover<span className="font-normal"> your account</span>
        </h1>
        <p className="text-[#313131] mb-5 font-medium lg:text-[18px] md:text-[16px] text-[14px] lg:text-start md:text-start text-center">
          Enter your registered Email id to receive OTP
        </p>

        <Form name="sign-in" layout="vertical" onFinish={handleSendOTP}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              placeholder="Email"
              className="w-full h-12 text-base border-[#A3BBFF]"
            />
          </Form.Item>

          <Form.Item>
            <div className="flex gap-4 w-full">
              <Button
                className="w-1/2 h-12 border bg-white border-blue-600 text-blue-600 text-base font-semibold hover:bg-slate-400"
                onClick={() => {
                  setLoginView(true);
                }}
              >
                Back to Login
              </Button>
              <Button
                loading={loader}
                type="primary"
                htmlType="submit"
                className="w-1/2 h-12 bg-[#1955EB] text-white text-base font-semibold hover:bg-[#1955EB]"
              >
                Next
              </Button>
            </div>
          </Form.Item>
        </Form>
        {contextHolder}
      </div>
    
    </>
  );
}

EnterPhoneEmail.propTypes = {
  setStep: PropTypes.object,
  setEmail: PropTypes.object,
  setLoginView: PropTypes.object,
};
export default EnterPhoneEmail;
