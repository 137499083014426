import { useState } from "react";
import { Input, Button, message, Form } from "antd";
import PropTypes from "prop-types";
import { resetPassword } from "../../../api/CommonRequest";


function CreateNewPassword({ setStep, emailID }) {
  const [loader, setLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  const errorMessage = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };


  const handleResetPassword = async (password) => {
    password.email = emailID;
    try {
      setLoader(true);
      const { data } = await resetPassword(password);
      if (data?.success) {
        setLoader(false);
        setStep(4);
      } else {
        errorMessage(data.message);
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      errorMessage(error.message);


      setLoader(false);
    }
  };


  return (
    <>
      <h1 className="text-[30px] font-bold lg:text-start md:text-start text-center mb-5">
        Create<span className="font-normal"> new password</span>
      </h1>
      <Form name="sign-in" layout="vertical" onFinish={handleResetPassword}>
        <div className="lg:text-start md:text-start text-center">

        
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Please input your new Password!" },
          ]}
        >
          <Input.Password
            placeholder="New password"
            className="lg:w-[80%] md:w-[80%] w-full mb-1 h-12 text-base border-[#A3BBFF]"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm new password"
            className="lg:w-[80%] md:w-[80%] w-full mb-4 h-12 text-base border-[#A3BBFF]"
            name="confirmPassword"
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={loader}
            type="primary"
            htmlType="submit"
            className="lg:w-[80%] md:w-[80%] w-full mt-[10px] h-12 bg-[#1955EB] text-white text-base font-semibold hover:bg-[#1955EB]"
          >
            Create
          </Button>
        </Form.Item>
        </div>
      </Form>
      {contextHolder}
    </>
  );
}


CreateNewPassword.propTypes = {
  setStep: PropTypes.object,
  emailID: PropTypes.object,
};


export default CreateNewPassword;