import { Button, Modal } from 'antd';
import React, { useRef, useState } from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from "browser-image-compression";


function CropperUploder({ onDataFromChild, uploderButton, identifier }) {
    // console.log(identifier, 'identifier');
    const fileInputRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pending, setPending] = useState([]);
    const [pending1, setPending1] = useState([]);
    const [cropper, setCropper] = useState();
    const [loader, setLoader] = useState(false);


    const getImage = (file) => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result);
            };
            fr.addEventListener("error", (e) => {
                reject(e);
            });
            fr.readAsDataURL(file);
        });
    };


    const onUploadButtonHandleClick = (identifierClick) => {
        document.getElementById(`file-image-input-${identifierClick}`).click();
    };

    const onFileInputChange = async (e) => {
       
        const files = e.target.files;


        if (files.length) {
           
            setPending(await Promise.all(Array.from(files).map((file) => getImage(file))));
            setPending1(files[0])
            setDialogOpen(true);
        }
    };

    const handleCancelUploadModal = () => {
        setDialogOpen(false);
        fileInputRef.current.value = null
    };

    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }


    const onComplete = async () => {
        setLoader(true)
        const blob = await new Promise((resolve, reject) => {
            cropper.getCroppedCanvas().toBlob(resolve);
        });
        
        const options = {
            maxSizeMB: 0.5,
            fileType: "image/webp",
            maxWidthOrHeight: 1920,
        };
        const image = await imageCompression(blob, options);
      

        const base64Img = await blobToBase64(image)
       

        onDataFromChild(image, base64Img)
        setLoader(false)
        const newPending = [...pending];
        newPending.shift();
        if (newPending.length === 0) {
            handleCancelUploadModal()
        }
        setPending(newPending);
    }

    const onWithoutCrop = async () => {
       
        setLoader(true)
        const options = {
            maxSizeMB: 0.5,
            fileType: "image/webp",
            maxWidthOrHeight: 1920,
        };
        const image = await imageCompression(pending1, options);
      

        const base64Img = await blobToBase64(image)
      

        onDataFromChild(image, base64Img)
        setLoader(false)
        handleCancelUploadModal();
    }

    return (
        <div>

            {/* <input
                type="file"
                id={`file-image-input-${identifier}`}
                className="hidden"
                accept="image/*"
                ref={fileInputRef}
                // multiple
                
                onChange={onFileInputChange}
            /> */}
            <input
    type="file"
    id={`file-image-input-${identifier}`}
    className="hidden"
    accept="image/*"
    ref={fileInputRef}
    onChange={onFileInputChange}
    style={{
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: 0,
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        border: 0,
    }}
/>
            <span onClick={() => onUploadButtonHandleClick(identifier)} key={identifier} className='cursor-pointer'>
                {uploderButton}
            </span>

            <Modal open={dialogOpen} onCancel={handleCancelUploadModal} closable={false} maskClosable={false} destroyOnClose={false} footer={false} >
                <div className="bg-white ">
                    <h1 className="mb-4 font-semibold text-base capitalize">{identifier} Crop Image</h1>


                    <Cropper
                        style={{ height: 400, width: "100%" }}
                        src={pending[0]}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                </div>
                <div className="flex justify-end mt-4">
                    <div className='flex gap-2'>
                        <Button onClick={handleCancelUploadModal}>
                            Cancel
                        </Button>
                        <Button
                           
                            onClick={onWithoutCrop}
                            type="none"
                            className="bg-gray-500 hover:bg-gray-400 hover:text-white text-white px-4 py-1 rounded"
                        >
                            Without Crop & Save
                        </Button>
                        <Button
                            loading={loader}
                            onClick={onComplete}
                            type='none'
                            className="bg-blue-500 hover:bg-blue-400 hover:text-white text-white px-4 py-2 rounded"
                        >
                            Crop & Save
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CropperUploder