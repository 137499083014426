import React, { useState } from "react";
import Surveyors from "../../../components/admindashboard/survey/Surveyors";
import Sidebar from "../../../components/admindashboard/sidebar/Sidebar";
import Header from "../../../components/header";

function SurveyorsPage() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="flex bg-[#F4F7FF] min-h-screen w-full">
      <div
        className={`fixed top-0 left-0 h-full z-10 transition-all duration-300 ${
          isCollapsed ? "lg:w-20 md:w-24 w-16" : "lg:w-64 md:w-48 w-fit"
        } bg-[#F4F7FF]`}
      >
        <Sidebar
          isCollapsed={isCollapsed}
          onToggleCollapse={handleToggleCollapse}
        />
      </div>

      <div
        className={`flex-grow transition-all duration-300 ml-0 ${
          isCollapsed ? "lg:pl-20 md:pl-24 pl-16" : "lg:pl-72 md:pl-48 pl-20"
        } bg-[#D2DEFF] lg:min-h-screen flex flex-col items-center justify-center p-6 lg:pr-6`}
      >
        <div className="w-full lg:h-[90vh]  p-4 lg:p-6 md:p-5 bg-white rounded-lg shadow-lg border border-[#9FACF0] flex flex-col">
          <Header className="lg:sticky top-0 lg:z-20" />
          <div className="flex-grow overflow-y-auto scrollbar-hide">
            <Surveyors />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyorsPage;
