import Swal from "sweetalert2";

function ErrorLogout(error) {
  Swal.fire({
    title: error.response.data.message,
    text: "Login again...",
  }).then(() => {
    localStorage.clear();
    window.location.href = "/login";
  });
}

export default ErrorLogout;
