import { axiosPrivate } from "./AxiosPrivate";

export const getAdminProfile = () => axiosPrivate.get('/settings/get-admin-profile')
export const viewAccessManagers = () => axiosPrivate.get('/settings/view-access-managers')
export const adminAccess = (email) => axiosPrivate.put('/settings/admin-access', { email})
export const adminChangePassword = (data) => axiosPrivate.put('/settings/change-password', data)
export const removeAdminAccess = (id) => axiosPrivate.put(`/settings/remove-admin-access/${id}`)
export const updateNotificationPreferences = (data) => axiosPrivate.put('/settings/update-notification-preferences', data)
export const getNotificationPreference = () => axiosPrivate.get('/settings/get-notification-preferences')

