import { Outlet, Navigate } from "react-router-dom";
import useAuthStore from "../store/authStore";

const AdminRouteProtect = () => {
  const { adminRefToken, isAuthenticated } = useAuthStore();

  
  return adminRefToken && isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={"/login"} />
  );
};
export default AdminRouteProtect;
