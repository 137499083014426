import axios from './Axios'
import { axiosPrivate } from "./AxiosPrivate";


export const getAllBid = (paramsData) => axios.get('/bid/getAllBid',{params:paramsData})
export const updateBidStatus = (bidID, status) => axiosPrivate.put(`/bid/updateBidStatus/${bidID}`, { status })
export const getSingleBid = (bidID) => axios.get(`/bid/getSingleBid/${bidID}`)
export const getAllBidRequest = (paramsData) => axios.get('/bid/getAllBidRequest',{params:paramsData})
// export const getMyBid = () => axiosPrivate.get('/user/getMyBid')
// export const placeBid = (bidData) => axiosPrivate.post('/bid/placeBid',bidData)
