import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, Select, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { GoHome } from "react-icons/go";
import Surveyors from "./Surveyors";
import { Link, useNavigate } from 'react-router-dom';
import CropperUploder from '../../imageUploader/CropperUploder';
import { getAllCategory } from '../../../api/AdminRequest';
import { createSurveyor, getSingleSurveyor, updateSurveyor } from '../../../api/SurveyorApiRequest';

const { Option } = Select;

const ProfileForm = ({ page, id }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showSurveyor, setShowSurveyor] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imgPre, setImgPre] = useState('');
  const [categories, setCategories] = useState([]);
  const [btnLoader, setBtnLoader] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllCategory();
        // const formattedCategories = data.categories.map(category => ({
        //   id: category._id,
        //   name: category.name
        // }));
        // setCategories(formattedCategories);
        setCategories(data.categories.map(category => category.name));


      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [page, id, form]);
  useEffect(() => {

    const surveyor = async () => {
      const { data } = await getSingleSurveyor(id);
      console.log(data.singleSurveyor);

      if (data.singleSurveyor) {
        form.setFieldsValue({
          name: data.singleSurveyor.name,
          phone: data.singleSurveyor.phone,
          email: data.singleSurveyor.email,
          category: data.singleSurveyor.category,
          location: data.singleSurveyor.location,
          address: data.singleSurveyor.address,
        });
        console.log(data);

        if (data.singleSurveyor.profileImage) {
          setFileList([{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: data.singleSurveyor.profileImage,
          }]);
          setImgPre(data.singleSurveyor.profileImage);
        }
      }
    }
    if (page === 'edit' && id) {
      surveyor()
    }
  }, [page, id, form]);

  const handleOpenSurveyor = () => {
    setShowSurveyor(true);
  };

  // ------------------ PROFILE IMAGE---------

  const handleDataFromChild = (data, base64Img) => {
    const imgSelected = [
      {
        uid: "-1",
        name: "image.png",
        status: data ? "done" : "",
        url: base64Img,
        thumbUrl: base64Img,
        originFileObj: data,
      },
    ];
    setFileList(imgSelected);
    setImgPre(base64Img);
  };

  const onChange = ({ fileList: newFileList }) => {
    if (newFileList[0]?.status) { newFileList[0].status = "done"; }
    setFileList(newFileList);
    setImgPre(newFileList[0]?.originFileObj ? URL.createObjectURL(newFileList[0]?.originFileObj) : '');
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploderButton = (
    <div className="flex flex-col items-center justify-center w-full h-[150px] border border-dashed border-[#A3BBFF] rounded-md">
      <PlusOutlined style={{ fontSize: '32px', color: '#A3BBFF' }} />
      <p className="mt-2 text-[18px] font-medium text-[#313131]">Upload</p>
    </div>
  );

  const onFinish = async (values) => {
    try {
      setBtnLoader(true)
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (key === 'category') {
          values[key].forEach(item => formData.append('category[]', item));
        } else {
          formData.append(key, values[key]);
        }
      });

      if (fileList[0]?.originFileObj) {
        formData.append('profileImg', fileList[0].originFileObj, "profileImg");
      }
      if (page === "edit" && id) {
        await updateSurveyor(id, formData)
        message.success('Surveyor updated successfully');

      } else {
        await createSurveyor(formData);
        message.success('Surveyor created successfully');
      }
      setShowSurveyor(true);
      // await createSurveyor(formData);
      // message.success('Surveyor created successfully');

    } catch (error) {
      console.error("Error creating surveyor:", error);
      message.error("Failed to create surveyor");
    } finally {
      setBtnLoader(false)
    }
  };

  if (showSurveyor) {
    return <Surveyors />;
  }

  return (
    <>
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
          <Link to="/">  <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          <span onClick={handleOpenSurveyor} className="ml-2 cursor-pointer text-[#C7C7C7] text-base md:text-lg font-medium">
            / Surveys-Surveyor
          </span>
          <span>
            {page === "create" ? " / Create Surveyors" : ` / Surveyor - ${id}`}
          </span>
        </div>
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish} className="space-y-6">
        <div className="flex flex-col lg:flex-row lg:w-2/3 space-x-0 lg:space-x-10 items-center">
          <div className="w-full lg:w-1/4 h-40 flex items-center justify-center mb-4 lg:mb-0">
            {fileList?.length !== 0 ? (
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
              >
                {fileList?.length < 1 && uploderButton}
              </Upload>
            ) : (
              <div className="w-full h-full">
                <CropperUploder
                  onDataFromChild={handleDataFromChild}
                  uploderButton={uploderButton}
                  identifier={'profileImage'}
                />
              </div>
            )}
          </div>
          <div className="flex-1 space-y-3">
            <Form.Item
              name="name"
              label="Full Name"
              rules={[{ required: true, message: 'Please input your full name!' }]}
              className="text-[#4A4A4C] text-[14px] font-medium"
            >
              <Input className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]" />
            </Form.Item>

            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true, message: 'Please select at least one category!' }]}
              className="text-[#4A4A4C] text-[14px] font-medium"
            >
              <Select
                mode="multiple"
                className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]"
              >
                {/* {categories.map(category => (
                  <Option key={category.id} value={category.id}>{category.name}</Option>
                ))} */}
                {categories.map((category, index) => (
                  <Option key={index} value={category}>{category}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="location"
              label="Survey Location"
              rules={[{ required: true, message: 'Please select at least one location!' }]}
              className="text-[#4A4A4C] text-[14px] font-medium"
            >
              <Select
                mode="multiple"
                className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]"
              >
                <Option value="kerala">Kerala</Option>
                <Option value="tamilnadu">Tamil Nadu</Option>
                <Option value="karnataka">Karnataka</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 lg:w-2/3">
          {/* <Form.Item 
            name="address1" 
            label="Address Line 1" 
            rules={[{ required: true, message: 'Please input your address!' }]}
            className="text-[#4A4A4C] text-[14px] font-medium"
          >
            <Input className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]" />
          </Form.Item> */}
          <Form.Item
            name="address"
            label="Address Line 1"
            rules={[{ required: true, message: 'Please input your address!' }]}
            className="text-[#4A4A4C] text-[14px] font-medium"
          >
            <Input className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email Id"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
            className="text-[#4A4A4C] text-[14px] font-medium"
          >
            <Input className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]" />
          </Form.Item>

          {/* <Form.Item 
            name="address2" 
            label="Address Line 2" 
            className="text-[#4A4A4C] text-[14px] font-medium"
          >
            <Input className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]" />
          </Form.Item> */}
          {page === "edit" && id ? (
            ""
          ) :
            (
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: page === 'create', message: 'Please input your password!' },
                  { min: 6, message: 'Password must be at least 6 characters long!' }
                ]}
                className="text-[#4A4A4C] text-[14px] font-medium"
              >
                <Input.Password className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]" />
              </Form.Item>
            )}

          {/* 
          <Form.Item 
            name="address3" 
            label="Address Line 3" 
            className="text-[#4A4A4C] text-[14px] font-medium"
          >
            <Input className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]" />
          </Form.Item> */}

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              { required: true, message: 'Please input your phone number!' },
              { pattern: /^[0-9]{10}$/, message: 'Please enter a valid 10-digit phone number!' }
            ]}
            className="text-[#4A4A4C] text-[14px] font-medium"
          >
            <Input className="h-10 text-[#4A4A4C] text-[16px] border-[#A3BBFF] placeholder-[#4A4A4C]" />
          </Form.Item>
        </div>

        <div className="mt-6 flex flex-col md:flex-row md:space-x-4">
          <Button onClick={() => navigate('/surveyors')} className="w-full md:w-1/2 h-10 border font-semibold border-[#313131] text-[#313131] rounded-lg">
            Discard
          </Button>
          <Button loading={btnLoader} type="primary" htmlType="submit" className="mt-4 md:mt-0 w-full md:w-1/2 h-10 text-white rounded-lg bg-[#1955EB]">
            {page === 'create' ? 'Create' : 'Update'}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ProfileForm;