import axios from './Axios'
import { axiosPrivate } from "./AxiosPrivate";



export const getAllSurveyor = (paramsData) => axios.get('/surveyor/getAllSurveyor',{params:paramsData})
export const updateSurveyorStatus = (id, status) => axiosPrivate.put(`/surveyor/updateSurveyorStatus/${id}`, {status})

export const getSingleSurveyor = (bidID) => axiosPrivate.get(`/surveyor/getSingleSurveyor/${bidID}`)

export const createSurveyor = (formData) => axiosPrivate.post(`/surveyor/createSurveyor`, formData)
export const updateSurveyor = (id, formData) => axiosPrivate.put(`/surveyor/updateSurveyor/${id}`, formData)

 export const getSurveyorNames = () => axiosPrivate.get(`/surveyor/getSurveyorNames`)



