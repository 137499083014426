import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../store/authStore";

const LoginProtect = () => {
  const { adminRefToken, isAuthenticated } = useAuthStore();

  if (adminRefToken && isAuthenticated) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};

export default LoginProtect;
