import { useState } from "react";
import { Button } from "antd";
import Loginlogo from "../../../assets/images/loginlogo.png";
import Recoverpageimg from "../../../assets/images/recoverpageimg.webp";
import EnterPhoneEmail from "./EnterPhoneEmail";
import EnterOTP from "./EnterOTP";
import CreateNewPassword from "./CreateNewPassword";
import PropTypes from "prop-types";

function ForgotPass({ setLoginView }) {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const SuccessfulPassword = () => (
    <>
      <h1 className="lg:text-[40px] md:text-[40px] text-[30px] lg:text-start md:text-start text-center font-bold mb-3">
        Recover<span className="font-normal"> your account</span>
      </h1>
      <p className="text-[#01A208] lg:text-start md:text-start text-center mb-5 font-medium text-[16px]">
        Password changed Successfully
      </p>
      <Button
        onClick={() => {
          setLoginView(true);
        }}
        type="primary"
        className="w-full lg:mt-[50px] md:mt-[50px] mt-[20px] pb-3  h-12 bg-[#1955EB] text-white text-base font-semibold hover:bg-[#1955EB]"
      >
        Back to Log in
      </Button>
    </>
  );

  return (
    <>
      <div className="lg:hidden md:hidden p-3">
        <img src={Loginlogo} alt="Architectural background" />
      </div>
      <div className="lg:flex md:flex lg:pb-10 lg:pt-5 lg:px-8 justify-between gap-[50px] lg:h-[90vh]">
        <div className="hidden relative lg:flex lg:w-1/2 items-center justify-center">
          <img
            src={Recoverpageimg}
            alt="loginimg"
            className="w-full h-auto lg:max-h-[90vh] object-cover rounded-[17px]"
          />
          <div className="absolute right-0 top-0 p-2">
            <img
              src={Loginlogo}
              alt="Architectural background"
              className="max-w-xs"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex items-center justify-center">
          <div className="w-full">
            {step === 1 ? (
              <EnterPhoneEmail
                setStep={setStep}
                setEmail={setEmail}
                setLoginView={setLoginView}
              />
            ) : step === 2 ? (
              <EnterOTP setStep={setStep} emailID={email} />
            ) : step === 3 ? (
              <CreateNewPassword setStep={setStep} emailID={email} />
            ) : step === 4 ? (
              <SuccessfulPassword />
            ) : (
              <EnterPhoneEmail setStep={setStep} setEmail={setEmail} setLoginView={setLoginView}/>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

ForgotPass.propTypes = {
  setLoginView: PropTypes.func,
};
export default ForgotPass;
