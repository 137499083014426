import React from 'react'
import { FaBell, FaLongArrowAltRight } from "react-icons/fa";
function header() {
  return (
    <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-10 items-center mb-6 w-full">
    <div className="relative w-full">
      <input
        type="text"
        placeholder="Search for something"
        className="pl-10 pr-4 py-2 border border-[#9FACF0] rounded-md w-full text-sm md:text-base"
      />
      <svg
        className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </div>
    <div className="flex flex-col md:flex-row items-center lg:gap-3 space-y-2 md:space-y-0">
      <div className="w-8 h-8 bg-[#EAEFFF] border border-[#9FACF0] flex justify-center items-center rounded-full">
        <FaBell className="text-[#1955EB]" />
      </div>
      <button className="text-sm md:text-base font-bold text-[#1955EB] bg-[#EAEFFF] border border-[#9FACF0] rounded-[8px] px-2 py-1">
        ADMIN12
      </button>
    </div>
  </div>
  )
}

export default header