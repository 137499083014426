import React, { useState } from 'react';
import { Table, Checkbox, Tooltip, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { LuArrowUpDown } from 'react-icons/lu';
import { GoHome } from 'react-icons/go';
import { IoMdDownload } from 'react-icons/io';
import Userimg from "../../../img/userimg.svg";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Group from "./Group"
const BuyersIndividual = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [activeTab, setActiveTab] = useState('Tab 1'); 
  const tabs = ['Tab 1', 'Tab 2', 'Tab 3']; 
  const [showGroup, setShowGroup] = useState(false);
  const data = [
    {
      key: '1',
      memberName: 'Sethuram Iyyer',
      status: 'Active',
      companyDomainId: 'aquin@metasoffit.com',
      joinedDate: '12 Sep 2021',
      startedDate: '12 Sep 2021',
    },
    {
      key: '2',
      memberName: 'John Doe',
      status: 'Active',
      companyDomainId: 'john.doe@company.com',
      joinedDate: '15 Oct 2021',
      startedDate: '15 Oct 2021',
    },
    {
      key: '3',
      memberName: 'Jane Smith',
      status: 'Active',
      companyDomainId: 'jane.smith@company.com',
      joinedDate: '20 Nov 2021',
      startedDate: '20 Nov 2021',
    },
    {
      key: '4',
      memberName: 'Alice Johnson',
      status: 'Blocked',
      companyDomainId: 'alice.johnson@company.com',
      joinedDate: '25 Dec 2021',
      startedDate: '25 Dec 2021',
    },
    {
      key: '5',
      memberName: 'Bob Brown',
      status: 'Active',
      companyDomainId: 'bob.brown@company.com',
      joinedDate: '10 Jan 2022',
      startedDate: '10 Jan 2022',
    },
    {
      key: '6',
      memberName: 'Carol White',
      status: 'Active',
      companyDomainId: 'carol.white@company.com',
      joinedDate: '15 Feb 2022',
      startedDate: '15 Feb 2022',
    },
    {
      key: '7',
      memberName: 'David Black',
      status: 'Active',
      companyDomainId: 'david.black@company.com',
      joinedDate: '20 Mar 2022',
      startedDate: '20 Mar 2022',
    },
    {
      key: '8',
      memberName: 'Eve Adams',
      status: 'Active',
      companyDomainId: 'eve.adams@company.com',
      joinedDate: '25 Apr 2022',
      startedDate: '25 Apr 2022',
    },
  ];
  const handleOpenGroup = () => {
    setShowGroup(true);
  };
  
  if (showGroup) {
    return <Group />;
  }
  const columns = [
    {
      title: () => <Checkbox />,
      key: 'select',
      render: () => <Checkbox />,
      width: 50,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Member Name
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'memberName',
      key: 'memberName',
      render: text => (
        <div className="flex items-center">
          <img src={Userimg} alt="User avatar" className="w-8 h-8 rounded-full mr-2" />
          <span className="text-[#313131] text-[13px] font-medium">{text}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Status
          <FaFilter className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <span className={`px-2 py-1 rounded-full text-xs ${
          status === 'Active' ? 'text-green-600' : 
          status === 'Blocked' ? 'text-red-600' : 
          'text-gray-600'
        }`}>
          {status}
        </span>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Company Domain id
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'companyDomainId',
      key: 'companyDomainId',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Joined Date
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'joinedDate',
      key: 'joinedDate',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Started Date
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'startedDate',
      key: 'startedDate',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: () => (
        <div className="flex space-x-2">
          <Tooltip title="Delete">
            <Button icon={<RiDeleteBinLine />} className="bg-[#FF4545] text-white hover:border-[#FF4545]" />
          </Tooltip>
        </div>
      ),
    },
  ];

  const showModal = () => {
   
    console.log('Show modal');
  };

  return (
    <>
     <div className="lg:stick top-0 pb-3 z-10 bg-white">
     <div className="mb-4  flex items-center text-sm md:text-base flex-wrap text-sm md:text-base">
     <Link to="/">   <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
    {/* <Link to="/buyer/GroupBuyer">  */}
    <span      onClick={handleOpenGroup} className="ml-2 cursor-pointer text-[#C7C7C7] text-base md:text-lg font-medium">
        / Buyers - Groups
      </span>
      {/* </Link> */}
      <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
         / Group1234321
      </span>
      <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
      <IoMdDownload className='text-[#1955EB] mt-1 text-[20px]' />
      <p className='text-[#1955EB] underline lg:text-[20px] font-medium'>Download Data</p>
      </div>
    </div>

  <div className="flex flex-col md:flex-row justify-between items-center gap-4 mt-7 mb-4">
    <div className="flex items-center gap-4 w-full md:w-auto">
      <img src={Userimg} alt="Metasoft IT Pvt ltd" className="w-20 h-20 rounded-full" />
      <div className="text-center md:text-left">
        <h2 className="text-[20px] md:text-[25px] text-[#313131] font-semibold">Metasoft IT Pvt ltd</h2>
        <p className="text-[14px] md:text-[17px] font-normal text-[#4A4A4C]">240 Members</p>
        <div className="flex flex-col md:flex-row items-center gap-2">
          <span className="text-[14px] md:text-[17px] font-normal text-[#878787]">@metasoftit.com</span>
          <a href="#" className="text-[14px] md:text-[17px] font-medium text-[#1955EB] underline">Change</a>
        </div>
      </div>
    </div>
    <button 
      className="px-4 py-2 text-[14px] md:text-[16px] font-medium text-white bg-[#1955EB] rounded-md hover:bg-white hover:border-[#1955EB] border-[1px] hover:text-[#1955EB]">
      + Add Members
    </button>
  </div>
</div>

      <div className="pt-[0px] space-y-10">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            total: 200,
            current: current,
            pageSize: pageSize,
            showSizeChanger: false,
            itemRender: (page, type, originalElement) => {
              if (type === 'prev') {
                return <p className="custom-pagination">Prev</p>;
              }
              if (type === 'next') {
                return <p className="custom-pagination">Next</p>;
              }
              if (type === 'page') {
                return <p className="custom-pagination">{page}</p>;
              }
              return originalElement;
            },
            onChange: (page, size) => {
              setCurrent(page);
              setPageSize(size);
            },
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            simple: true,
          }}
          className="custom-table-header lg:w-full w-[250px] overflow-x-auto custom-pagination"
        />
      </div>
    </>
  );
};

export default BuyersIndividual;
