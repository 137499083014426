import axios from './Axios'

export const login = (loginData) => axios.post('/auth/login',loginData)
export const logout = (refToken) => axios.post('/auth/logout',{refToken})
export const refreshToken = (refToken) => axios.post('/auth/refresh-token',{refToken})
export const forgotPassword = (email) => axios.post('/auth/forgotPassword',email)

//Api For Create Account

export const emailVerification = (verificationData) => axios.post('/auth/emailVerification',verificationData)
export const otpVerification = (otpData) => axios.post('/auth/otpVerification',otpData)
export const resetPassword = (pass) => axios.put('/auth/resetPassword',pass)

