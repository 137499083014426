
import { axiosPrivate } from "./AxiosPrivate";

export const getAllUsers = (paramsData) => axiosPrivate.get('/user/getAllUsers',{params:paramsData})
export const updateUserStatus = (id, status) => axiosPrivate.put(`/user/updateUserStatus/${id}`, {status})
export const getVerifyRequest = (paramsData) => axiosPrivate.get('/user/getVerifyRequest',{params:paramsData})


export const verifyUser = (id) => axiosPrivate.put(`/admin/verify-user/${id}`)
export const rejectUserVerification = (id, rejectReason) => axiosPrivate.put(`/admin/reject-user-verification/${id}` , {rejectReason})
