import { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import PropTypes from "prop-types";
import { Slider } from "antd";


const categories = [
  {
    name: "Motors",
    items: ["Cars", "Bikes", "Trucks", "Jeeps", "Buses", "Van", "Boats"],
  },
  { name: "Property", items: ["Houses", "Apartments", "Land", "Commercial"] },
  { name: "Scraps", items: ["Metal", "Plastic", "Glass", "Electronics"] },
];
// const prices = ["Under $10,000", "$10,000 - $20,000", "Above $20,000"];
const types = ["All","Public", "Group"];
const images = {
  Cars: "https://via.placeholder.com/80?text=Cars",
  Bikes: "https://via.placeholder.com/80?text=Bikes",
  Trucks: "https://via.placeholder.com/80?text=Trucks",
  Jeeps: "https://via.placeholder.com/80?text=Jeeps",
  Buses: "https://via.placeholder.com/80?text=Buses",
  Van: "https://via.placeholder.com/80?text=Van",
  Boats: "https://via.placeholder.com/80?text=Boats",
  Houses: "https://via.placeholder.com/80?text=Houses",
  Apartments: "https://via.placeholder.com/80?text=Apartments",
  Land: "https://via.placeholder.com/80?text=Land",
  Commercial: "https://via.placeholder.com/80?text=Commercial",
  Metal: "https://via.placeholder.com/80?text=Metal",
  Plastic: "https://via.placeholder.com/80?text=Plastic",
  Glass: "https://via.placeholder.com/80?text=Glass",
  Electronics: "https://via.placeholder.com/80?text=Electronics",
};
const FilterModal = ({ isVisible, onClose, subCategory, setSubCategory,type,setType ,bidRange,setBidRange}) => {
  const [selectedCategory, setSelectedCategory] = useState("Motors");
  // const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedsubCategory, setSelectedSubCategory] = useState([]);
  const [value, setValue] = useState([1000, 100000]);
  const handleSubCategory = (categoryName) => {
    // Check if the button is already selected
    if (selectedsubCategory.includes(categoryName)) {
      // Button is already selected, so remove it from the selection
      setSelectedSubCategory(
        selectedsubCategory.filter((category) => category !== categoryName)
      );
    } else {
      // Button is not selected, so add it to the selection
      setSelectedSubCategory([...selectedsubCategory, categoryName]);
    }
  };


  const handleSliderChange = (newValue) => {
    setValue(newValue); // Update the Slider's value state
    // You can perform additional actions here based on the new value
  };
  const priceFormatter = (value) => `$${value}`;
  const handleFilter = () => {
    setSubCategory(selectedsubCategory)
    setType(selectedType)
    setBidRange(value)
    onClose()
  };
  const handleClearFilter = () => {
    setSubCategory([])
    setType('All')
    setBidRange([1000, 100000])
    onClose()
  };


   useEffect(()=>{
    setSelectedSubCategory(subCategory)
    setSelectedType(type)
    setValue(bidRange)
  },[subCategory,type,bidRange])
  if (!isVisible) return null;
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 ">
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg relative lg:h-[86vh] h-[90vh]">
        <div className="  h-[80vh]">
            <div className="flex">


           
          <div className="w-1/4 mt-20 ">
            <h2 className="text-[24px] text-[#000000] pl-6 font-semibold mb-4">
              Filters
            </h2>
            <ul className="">
              <li
                onClick={() => setSelectedCategory("Motors")}
                className={`flex items-center justify-between font-medium text-[16px] cursor-pointer border-[#C7C7C7] border-b border-t py-2 pl-6 pr-2 ${
                  selectedCategory === "Motors"
                    ? "text-blue-500 bg-blue-50"
                    : "text-gray-500 bg-white"
                }`}
              >
                Category
                <IoIosArrowForward
                  onClick={() => setSelectedCategory("Motors")}
                  className={`font-medium text-[20px] ${
                    selectedCategory === "Motors"
                      ? "text-blue-500"
                      : "text-gray-500"
                  }`}
                />
              </li>
              <li
                onClick={() => setSelectedCategory("Price")}
                className={`flex items-center justify-between font-medium text-[16px] cursor-pointer border-[#C7C7C7] border-b py-2 pl-6 pr-2  ${
                  selectedCategory === "Price"
                    ? "text-blue-500 bg-blue-50"
                    : "text-gray-500 bg-white"
                }`}
              >
                Price
                <IoIosArrowForward
                  onClick={() => setSelectedCategory("Price")}
                  className={`font-medium text-[20px] ${
                    selectedCategory === "Price"
                      ? "text-blue-500"
                      : "text-gray-500"
                  }`}
                />
              </li>
              <li
                onClick={() => setSelectedCategory("Type")}
                className={`flex items-center justify-between font-medium text-[16px] cursor-pointer border-[#C7C7C7] border-b py-2 pl-6 pr-2  ${
                  selectedCategory === "Type"
                    ? "text-blue-500 bg-blue-50"
                    : "text-gray-500 bg-white"
                }`}
              >
                Individual / Group
                <IoIosArrowForward
                  onClick={() => setSelectedCategory("Type")}
                  className={`font-medium text-[20px] ${
                    selectedCategory === "Type"
                      ? "text-blue-500"
                      : "text-gray-500"
                  }`}
                />
              </li>
            </ul>
          </div>
          <div className="w-3/4 lg:mr-10 md:mr-10 mr-3 mt-10  font-semibold bg-white border-[1px] p-10 h-[70vh] border-[#D2DEFF] rounded-[16px] overflow-y-auto">
            <div>
              {selectedCategory === "Motors" && (
                <>
                  <div className="flex">
                    {categories.map((category) => (
                      <button
                        key={category.name}
                        onClick={() => setSelectedCategory(category.name)}
                        className={`px-5 py-2 text-sm font-medium ${
                          selectedCategory === category.name
                            ? "text-blue-600  border-b-2 font-semibold border-blue-600"
                            : "text-gray-500 hover:text-gray-700"
                        }`}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                  <div className="lg:grid grid-cols-4 gap-4 mt-5">
                    {categories
                      .find((category) => category.name === selectedCategory)
                      ?.items.map((item) => (
                        <div
                          key={item}
                          className="text-center "
                          onClick={() => handleSubCategory(item)}
                        >
                          <div
                            className={`w-full h-24 bg-gray-700 rounded-lg mb-2 flex items-center justify-center  cursor-pointer ${
                              selectedsubCategory.includes(item)
                                ? "border border-blue-800"
                                : ""
                            }`}
                          >
                            <img
                              src={images[item]}
                              alt={item}
                              className="w-full h-full object-cover rounded-lg"
                            />
                          </div>
                          <span>{item}</span>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {selectedCategory === "Property" && (
                <>
                  <div className="flex">
                    {categories.map((category) => (
                      <button
                        key={category.name}
                        onClick={() => setSelectedCategory(category.name)}
                        className={`px-4 py-2 text-sm font-medium ${
                          selectedCategory === category.name
                            ? "text-blue-600 border-b-2 border-blue-600"
                            : "text-gray-500 hover:text-gray-700"
                        }`}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                  <div className="lg:grid grid-cols-4 mt-5 gap-4">
                    {categories
                      .find((category) => category.name === selectedCategory)
                      ?.items.map((item) => (
                        <div
                          key={item}
                          className="text-center "
                          onClick={() => handleSubCategory(item)}
                        >
                          <div
                            className={`w-full h-24 bg-gray-700 rounded-lg mb-2 flex items-center justify-center  cursor-pointer ${
                                selectedsubCategory.includes(item)
                                ? "border border-blue-800"
                                : ""
                            }`}
                          >
                            <img
                              src={images[item]}
                              alt={item}
                              className="w-full h-full object-cover rounded-lg"
                            />
                          </div>
                          <span>{item}</span>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {selectedCategory === "Scraps" && (
                <>
                  <div className="flex">
                    {categories.map((category) => (
                      <button
                        key={category.name}
                        onClick={() => setSelectedCategory(category.name)}
                        className={`px-4 py-2 text-sm font-medium ${
                          selectedCategory === category.name
                            ? "text-blue-600 border-b-2 border-blue-600"
                            : "text-gray-500 hover:text-gray-700"
                        }`}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                  <div className="lg:grid md:grid grid-cols-4 mt-5 gap-4">
                    {categories
                      .find((category) => category.name === selectedCategory)
                      ?.items.map((item) => (
                        <div
                          key={item}
                          className="text-center "
                          onClick={() => handleSubCategory(item)}
                        >
                          <div
                            className={`w-full h-24 bg-gray-700 rounded-lg mb-2 flex items-center justify-center  cursor-pointer ${
                                selectedsubCategory.includes(item)
                                ? "border border-blue-800"
                                : ""
                            }`}
                          >
                            <img
                              src={images[item]}
                              alt={item}
                              className="w-full h-full object-cover rounded-lg"
                            />
                          </div>
                          <span>{item}</span>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
            {selectedCategory === "Price" && (
                <div>


              {/* <div className="grid grid-cols-4 gap-4">
                {prices.map((price) => (
                    <div
                    key={price}
                    onClick={() => setSelectedPrice(price)}
                    className={`cursor-pointer p-4 rounded-lg ${
                        selectedPrice === price
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                    >
                    {price}
                  </div>
                ))}
      </div> */}
      <div className="mt-6">


      <h1>Price Range</h1>
                <Slider
      range={{ draggableTrack: true }}
      value={value}
      onChange={handleSliderChange}
      tipFormatter={priceFormatter} // Use the priceFormatter function for tooltip
      min={1000} // Set min value for slider
      max={100000}
      step={1000}
      />
      <div className="flex justify-between">


      <h1>{value[0]}</h1>
      <h1>{value[1]}</h1>
      </div>
              </div>
      </div>
            )}
            {selectedCategory === "Type" && (
              <div className="grid grid-cols-4 gap-4">
                {types.map((type) => (
                  <div
                    key={type}
                    onClick={() => setSelectedType(type)}
                    className={`cursor-pointer p-4 rounded-lg ${
                      selectedType === type
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {type}
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            onClick={onClose}
            className="absolute top-6 border-[#A3BBFF] border-[1px] rounded-full bg-[#F4F7FF] p-2 lg:right-7 md:right-7 right-1 text-[#1955EB] hover:text-black"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          </div>
          <div className="flex justify-end mx-10 lg:my-4 md:my-4 my-6">


          <button className="bg-white mr-4 border hover:bg-[#1955EB] hover:text-white   border-[#1955EB]  text-[#1955EB] font-medium px-4 py-2 rounded-md" onClick={()=>{handleClearFilter()}}>
            Clear Filter
        </button>
          <button className="bg-[#1955EB] hover:bg-white hover:border hover:border-[#1955EB] hover:text-[#1955EB]  font-medium text-white px-10 py-2 rounded-md" onClick={()=>{handleFilter()}}>
            Apply
        </button>
          </div>
        </div>
      </div>
    </div>
  );
};


FilterModal.propTypes = {
  onClose: PropTypes.object,
  isVisible: PropTypes.object,
  subCategory: PropTypes.object,
  setSubCategory: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.object,
  bidRange: PropTypes.object,
  setBidRange: PropTypes.object,
};
export default FilterModal;