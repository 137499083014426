import React, { useState } from 'react';
import { Input, Form, Select, Button, Upload ,Modal , Radio, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { GoHome } from "react-icons/go";
import { MdOutlineDone } from "react-icons/md";
import { Link } from 'react-router-dom';
import SurveyRequests from './SurveyRequets';
import Bids from '../../../../pages/adminpages/Bids';


const { Option } = Select;
const YourComponent = () => {
  const [images, setImages] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [activeTab, setActiveTab] = useState("Basic Info");
  const [description, setDescription] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [showSurveyRequest, setShowSurveyRequest] = useState(false);
  const [showBids, setShowBids] = useState(false);
  const handleUpload = ({ fileList }) => {
    setImages(fileList);
    if (fileList.length && !thumbnail) {
      setThumbnail(fileList[0]);
    }
  };

  const handleThumbnailChange = (file) => {
    setThumbnail(file);
  };

  const tabItems = [
    { key: "Basic Info", label: "Basic Info" },
    { key: "Detailed Info", label: "Detailed Info" },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bidType, setBidType] = useState('group');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Handle submit action here
    setIsModalVisible(false);
    setIsSecondModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsSecondModalOpen(false);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleOpenBids = () => {
    setShowBids(true);
  };
  
  if (showBids) {
    return <Bids />;
  }
  const handleOpenSurveyRequest = () => {
    setShowSurveyRequest(true);
  };
  
  if (showSurveyRequest) {
    return <SurveyRequests />;
  }
  return (
    <>
    <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
     
      <div className="mb-4 flex items-center text-xs sm:text-sm md:text-base">
      <Link to="/">     <GoHome className="text-[#C7C7C7] text-[20px] sm:text-[22px] md:text-[24px]" /></Link>
        <span onClick={handleOpenBids} className="ml-2 text-[#C7C7C7] cursor-pointer text-xs sm:text-sm md:text-base font-medium">
          / Bids
        </span>
    {/* <Link to="/surveyrequest"  >  */}
     <span onClick={handleOpenSurveyRequest} className="ml-2 text-[#C7C7C7] cursor-pointer text-xs sm:text-sm md:text-base font-medium">
          / Survey Request
        </span>
        {/* </Link> */}
        <span className="ml-2 text-[#313131] text-xs sm:text-sm cursor-pointer md:text-base font-medium">
          / Survey 019231
        </span>
      </div>

      <div className="flex w-fit flex-wrap gap-2 mb-4 border border-[#9FACF0] p-2 rounded-[12px]">
        {tabItems.map((tab) => (
          <button
            key={tab.key}
            className={`px-4 py-2 rounded-md text-xs sm:text-sm md:text-base ${
              activeTab === tab.key
                ? "bg-[#1955EB] text-white font-medium"
                : "bg-[#F6F8FF] text-black font-medium"
            }`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>
</div>
<div className="pt-[0px] space-y-10">
     
      {activeTab === "Basic Info" && (
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
            <div>
              <label className="block mb-2 text-[14px] text-start font-medium text-[#4A4A4C]">
                Product Name
              </label>
              <Input
                placeholder="Enter product name"
                className="border-[#A3BBFF] border text-[16px] h-10  text-[#4A4A4C] placeholder:text-[#4A4A4C]"
              />
            </div>
            <div>
              <label className="block mb-2 text-[14px] text-start font-medium text-[#4A4A4C]">
                Category
              </label>
              <Select
  placeholder="Select category"
  className="w-full h-10 text-xs sm:text-sm md:text-base flex justify-start text-start text-[#4A4A4C] placeholder:text-[#4A4A4C] rounded-md border border-[#A3BBFF]"
  dropdownClassName="custom-dropdown"
>
  <Option value="category1">Category 1</Option>
  <Option value="category2">Category 2</Option>
  <Option value="category3">Category 3</Option>
</Select>

            </div>
            <div>
              <label className="block mb-2 text-[14px] text-start font-medium text-[#4A4A4C]">
                Location
              </label>
              <Input
                placeholder="Enter location"
                className="border-[#A3BBFF] border text-[16px] h-10  text-[#4A4A4C] placeholder:text-[#4A4A4C]"
              />
            </div>
            <div>
              <label className="block mb-2 text-[14px] text-start font-medium text-[#4A4A4C]">
                Expected Price
              </label>
              <Input
                placeholder="Enter expected price"
                className="border-[#A3BBFF] border text-[16px] h-10  text-[#4A4A4C] placeholder:text-[#4A4A4C]"
              />
            </div>
          </div>

          <div className="mb-6">
            <div className="flex items-center justify-start gap-5 gap-4 mb-4">
              <span className="text-[20px] text-[#313131]">Photos</span>
              <span className="text-[#1955EB] bg-[#EAEFFF] rounded-full text-xs sm:text-sm md:text-base font-medium w-8 h-8 sm:w-10 sm:h-10 text-center flex items-center justify-center">
                {images.length}
              </span>
            </div>
            
      <Upload
        listType="picture-card"
        fileList={images}
        onChange={handleUpload}
        beforeUpload={() => false} 
        className="w-full lg:flex justify-center"
      >
        <div className="upload-button ">
          <UploadOutlined className="text-lg sm:text-xl md:text-2xl" />
          <div className="mt-1 sm:mt-0 sm:ml-2">Upload</div>
        </div>
      </Upload>
      
      {thumbnail && (
        // <div className="mt-4 flex flex-col sm:flex-row items-center">
        //   <span className="text-xs sm:text-sm md:text-base text-gray-600 mb-2 sm:mb-0 sm:mr-4">
        //     Chosen as default Thumbnail
        //   </span>
        //   <Select
        //     value={thumbnail.uid}
        //     onChange={(value) =>
        //       handleThumbnailChange(images.find((file) => file.uid === value))
        //     }
        //     className="w-full sm:w-1/2 md:w-1/3"
        //   >
        //     {images.map((file) => (
        //       <Select.Option key={file.uid} value={file.uid}>
        //         {file.name}
        //       </Select.Option>
        //     ))}
        //   </Select>
        // </div>
        <div className="mt-4 flex flex-col sm:flex-row items-center">
        <span className="text-[14px] text-[#313131] mb-2 font-normal sm:mb-0 sm:mr-4">
          IMG {thumbnail.name} Chosen as default Thumbnail
        </span>
        <button className="text-[#1955EB] text-[16px] bg-[#EAEFFF] hover:bg-blue-100 px-3 py-1 rounded">
          Choose Thumbnail
        </button>
      </div>
      )}
    </div>

          <div className="flex flex-col sm:flex-row justify-between items-center mt-8 gap-4">
            <Button className="text-[#313131] w-full sm:w-1/2 border-[#313131] rounded-[8px] h-10 text-xs sm:text-sm md:text-base font-semibold hover:border-gray-400">
              Discard
            </Button>
            <Button
              type="primary"
              className="bg-[#1955EB] w-full sm:w-1/2 hover:border-[#1955EB] text-xs sm:text-sm md:text-base font-semibold hover:bg-white h-10 text-white"
            >
              Next
            </Button>
          </div>
        </div>
      )}
    

      {activeTab === "Detailed Info" && (
        <Form className="" layout="vertical">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-0 m-0">

          <Form.Item
            label="Registration Year"
            name="registrationYear"
            className="text-sm font-medium text-[#4A4A4C] p-0 m-0"
          >
            <Input type='date' className="border-[#A3BBFF] border h-10 text-[#4A4A4C] text-base font-normal placeholder:text-[#4A4A4C]" />
          </Form.Item>
          <Form.Item
            label="No. of Owners"
            name="noOfOwners"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Select className="border-[#A3BBFF] border text-[#4A4A4C] text-base font-normal h-10 rounded-md">
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Model"
            name="model"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Input className="border-[#A3BBFF] border text-[#4A4A4C] h-10 text-base font-normal placeholder:text-[#4A4A4C]" />
          </Form.Item>
          <Form.Item
            label="Transmission"
            name="transmission"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Select className="border-[#A3BBFF] border text-[#4A4A4C] h-10 text-base font-normal h-10 rounded-md">
              <Option value="automatic">Automatic</Option>
              <Option value="manual">Manual</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Trim"
            name="trim"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Input className="border-[#A3BBFF] h-10 border text-[#4A4A4C] text-base font-normal placeholder:text-[#4A4A4C]" />
          </Form.Item>
          <Form.Item
            label="Fuel Type"
            name="fuelType"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Select className="border-[#A3BBFF] border text-[#4A4A4C] text-base font-normal h-10 rounded-md">
              <Option value="petrol">Petrol</Option>
              <Option value="diesel">Diesel</Option>
              <Option value="electric">Electric</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Kms Driven"
            name="Kms Driven"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Input className="border-[#A3BBFF] h-10 border text-[#4A4A4C] text-base font-normal placeholder:text-[#4A4A4C]" />
          </Form.Item>
          <Form.Item
            label="Body Type"
            name="bodyType"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Select className="border-[#A3BBFF] border text-[#4A4A4C] text-base font-normal h-10 rounded-md">
              <Option value="sedan">Sedan</Option>
              <Option value="suv">SUV</Option>
              <Option value="hatchback">Hatchback</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Input className="border-[#A3BBFF] h-10 border text-[#4A4A4C] text-base font-normal placeholder:text-[#4A4A4C]" />
          </Form.Item>
          <Form.Item
            label="Mileage"
            name="mileage"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Input className="border-[#A3BBFF] border h-10 text-[#4A4A4C] text-base font-normal placeholder:text-[#4A4A4C]" />
          </Form.Item>
          <Form.Item
            label="VIN Number"
            name="vinNumber"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Input className="border-[#A3BBFF] h-10 border text-[#4A4A4C] text-base font-normal placeholder:text-[#4A4A4C]" />
          </Form.Item>
          <Form.Item
            label="No. of Seats"
            name="noOfSeats"
            className="text-sm font-medium text-[#4A4A4C]"
          >
            <Select className="border-[#A3BBFF] h-10 border text-[#4A4A4C] text-base font-normal h-10 rounded-md">
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="7">7</Option>
            </Select>
          </Form.Item>
    
        </div>
  
        <div className="mb-6">
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full h-24 border-[#A3BBFF] border rounded-md p-3 text-[#4A4A4C] text-base font-normal placeholder:text-[#4A4A4C]"
            placeholder="Description"
          ></textarea>
        </div>
  
        <div className="mb-6">
          <textarea
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            className="w-full h-24 border-[#A3BBFF] border rounded-md p-3 text-[#4A4A4C] text-base font-normal placeholder:text-[#4A4A4C]"
            placeholder="Additional Information"
          ></textarea>
        </div>
  
        <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
          <Button
            type="primary"
            className="bg-[#1955EB] h-10 w-full sm:w-1/2 hover:border-[#1955EB] hover:bg-transparent text-white text-base font-semibold rounded-md px-4 py-2"
          >
            Approve
          </Button>
          <Button    onClick={showModal}  className="border-[#FF4545] border h-10 w-full sm:w-1/2 hover:text-black text-base font-semibold text-[#FF4545] rounded-md px-4 py-2">
            Reject
          </Button>
        </div>
      </Form>
      )}

<Modal 
        // title="Bid Approval" 
        open={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={
          <div className="flex w-full space-x-4">
            <Button 
              key="cancel" 
              onClick={handleCancel} 
              className="bg-white border-[#313131] border-[1px] h-10 text-[16px] font-semibold text-[#313131] w-full"
            >
              Discard
            </Button>
            <Button 
              key="submit" 
              type="primary" 
              onClick={handleOk} 
              className="text-white bg-[#1955EB] text-[16px] font-semibold w-full h-10"
            >
              List into Bid
            </Button>
          </div>
        }
        width={600}
        className="text-center"
      >
        <div className="px-4 py-2 text-left">
          <div className="mb-4">
            <p className='text-[18px] text-[#4A4A4C] font-semibold'>Bid Approval</p>
            <p className="text-[13px] text-[#4A4A4C] font-medium">Bidding ID: BD23462434552</p>
          </div>
          
          <div className="flex justify-between gap-10 mb-5">
            <div>
              <p className="text-[#4A4A4C] text-[14px] font-medium">Bid Starting</p>
              <DatePicker 
                value={startDate} 
                onChange={handleStartDateChange} 
                className="w-full mt-2"
              />
              <div className="flex mt-2 space-x-2">
                <Input className="w-1/3" defaultValue="00" />
                <Input className="w-1/3" defaultValue="00" />
                <Select defaultValue="AM" className="w-1/3">
                  <Option value="AM">AM</Option>
                  <Option value="PM">PM</Option>
                </Select>
              </div>
            </div>

            <div>
              <p className="text-[#4A4A4C] text-[14px] font-medium">Bid Closing</p>
              <DatePicker 
                value={endDate} 
                onChange={handleEndDateChange} 
                className="w-full mt-2 "
              />
              <div className="flex mt-2 space-x-2">
                <Input className="w-1/3" defaultValue="00" />
                <Input className="w-1/3" defaultValue="00" />
                <Select defaultValue="AM" className="w-1/3">
                  <Option value="AM">AM</Option>
                  <Option value="PM">PM</Option>
                </Select>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-between gap-4 mb-4">
            <div className='w-full'>
            <div className='flex gap-3'>
              <p className="font-medium text-[#4A4A4C] text-[13px] font-medium">Surveyor: </p>
              <p className='text-[#4A4A4C] text-[13px] font-light'>Surveyor Name</p>
            </div>
            <div className='flex gap-3 mt-2'>
              <p className="font-medium text-[#4A4A4C] text-[13px] font-medium">Client: </p>
              <p className='text-[#4A4A4C] text-[13px] font-light'>Client Name</p>
            </div>
            <div className="mb-4 mt-2 flex">
            <p className="font-medium text-[#4A4A4C] text-[13px] font-medium">Expected:</p>
            <p className='text-[#4A4A4C] text-[13px] font-light'> 12000000</p>
            {/* <Input 
              defaultValue="12000000" 
              className="w-full mt-1"
            /> */}
          </div>
        
</div>
         
<div className='w-full'>
          
<Radio.Group 
  onChange={(e) => setBidType(e.target.value)} 
  value={bidType} 
  className="flex flex-col space-y-2"
>
  <Radio value="group" className='text-[#4A4A4C] text-[13px] font-medium'>
    Group Bid
  </Radio>
  <Radio value="individual" className='mt-3 text-[#4A4A4C] text-[13px] font-medium'>
    Individual Bid
  </Radio>
</Radio.Group>

          

          {bidType === 'group' && (
         
              <Select 
                placeholder="Select Group" 
                className="w-full mt-3 text-[#4A4A4C] text-[13px] font-medium"
              >
                <Option value="group1">Group 1</Option>
                <Option value="group2">Group 2</Option>
                <Option value="group3">Group 3</Option>
              </Select>
            
          )}
        </div>
        </div></div>
      </Modal>
      <Modal
        open={isSecondModalOpen}
        onCancel={handleCancel}
        footer={null}
        className=""
        // closeIcon={<AiOutlineCheckCircle className="text-green-600" />} // Close icon
      >
        <div className="flex flex-col items-center py-3">
          <div className="flex items-center mb-4">
            <MdOutlineDone className="text-white p-1 rounded-full bg-[#3AB927] text-4xl" />
          </div>
          <div className="text-center text-[16px] font-medium">
            <p className="text-[#C7C7C7] text-[16px] font-medium">Bidding Id #23456789</p>
            <p className="text-[#313131] text-[24px] font-medium ">Bid Listed Successfully</p>
          </div>
          <div className="flex justify-center mt-3">
            <Button onClick={handleCancel} className="bg-[#313131] h-10 text-white text-[18px] font-medium">
              Dismiss
            </Button>
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default YourComponent;
