import { Button, Form, Input, Upload, message } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import Logo from "../../../img/profilepage-logo.svg";
import { GoHome } from "react-icons/go";
import { MdOutlineDone } from "react-icons/md";
import {
  getCompanyProfile,
  addCompanyProfile,
} from "../../../api/AdminRequest";
import CropperUploder from "../../imageUploader/CropperUploder";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [logo, setLogo] = useState(Logo);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getCompanyProfile();

        if (response.data.success) {
          const profile = response.data.companyProfile;
          form.setFieldsValue({
            contactNumber: profile.contactNumber,
            additionalNumber: profile.additionalNumber,
            email: profile.email,
            addressLine1: profile.addressLane1,
            addressLine2: profile.addressLane2,
            addressLine3: profile.addressLane3,
          });
          setLogo(profile.logo);
        }
      } catch (error) {
        console.error("Failed to fetch company profile:", error);
      }
    };

    fetchProfile();
  }, [form]);

  const handleCancel = () => {};

  const handleUpdateChanges = async () => {
    try {
      const values = await form.validateFields();
      const updatedProfile = {
        ...values,
        logo,
      };
      const response = await addCompanyProfile(updatedProfile);
      if (response.data.success) {
        message.success("Company profile updated successfully");
      }
    } catch (error) {
      console.error("Failed to update company profile:", error);
    }
  };

  const handleLogoChange = ({ file }) => {
    if (file.status === "done") {
      setLogo(file.response.url);
    }
  };

  return (
    <div className="">
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
          <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" />
          <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
            / Profile
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1  md:grid-cols-2 gap-5">
        <div className="h-full flex flex-col">
          <h2 className="text-[20px] text-[#313131] text-start font-semibold mb-4">
            Logo
          </h2>
          <div className="h-full border-dashed border border-[#9FACF0] rounded-lg flex justify-center items-center">
            <img
              src={logo}
              alt="Logo"
              className="w-full h-full object-contain max-w-xs lg:p-0 p-6"
            />
          </div>
          <Upload
            name="logo"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture"
            onChange={handleLogoChange}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>

        <div className="flex flex-col">
          <h2 className="text-lg md:text-xl text-start text-[#313131] font-semibold mb-4">
            Contact Details
          </h2>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            className="flex-grow"
          >
            <Form.Item
              label={
                <span className="block text-sm md:text-base font-medium text-[#4A4A4C]">
                  Contact Number
                </span>
              }
              name="contactNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your contact number!",
                },
              ]}
            >
              <Input className="h-10 border-[#A3BBFF] rounded-[8px]" />
            </Form.Item>
            <Form.Item
              label={
                <span className="block text-sm md:text-base font-medium text-[#4A4A4C]">
                  Additional Number
                </span>
              }
              name="additionalNumber"
            >
              <Input className="h-10 border-[#A3BBFF] rounded-[8px]" />
            </Form.Item>
            <Form.Item
              label={
                <span className="block text-sm md:text-base font-medium text-[#4A4A4C]">
                  Email Id
                </span>
              }
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                type="email"
                className="h-10 border-[#A3BBFF] rounded-[8px]"
              />
            </Form.Item>
          </Form>
        </div>
      </div>

      <div className="mt-8 lg:w-1/2">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg md:text-xl text-[#313131] font-semibold">
            Address
          </h2>
          <button className="text-[#1955EB] text-sm md:text-base font-semibold">
            + Add Branch
          </button>
        </div>
        <Form form={form} layout="vertical" requiredMark={false}>
          <Form.Item
            label={
              <span className="block text-sm md:text-base font-medium text-[#4A4A4C]">
                Address Line 1
              </span>
            }
            name="addressLine1"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Input className="h-10 border-[#A3BBFF] rounded-[8px]" />
          </Form.Item>
          <Form.Item
            label={
              <span className="block text-sm md:text-base font-medium text-[#4A4A4C]">
                Address Line 2
              </span>
            }
            name="addressLine2"
          >
            <Input className="h-10 border-[#A3BBFF] rounded-[8px]" />
          </Form.Item>
          <Form.Item
            label={
              <span className="block text-sm md:text-base font-medium text-[#4A4A4C]">
                Address Line 3
              </span>
            }
            name="addressLine3"
          >
            <Input className="h-10 border-[#A3BBFF] rounded-[8px]" />
          </Form.Item>
        </Form>
      </div>

      <div className="mt-6 flex flex-col md:flex-row md:space-x-4">
        <Button
          onClick={() => navigate("/")}
          className="w-full md:w-1/2 h-10 border font-semibold border-[#313131] text-[#313131] rounded-lg text-sm md:text-base"
        >
          Revert Changes
        </Button>
        <Button
          onClick={handleUpdateChanges}
          type="primary"
          className="mt-4 md:mt-0 w-full md:w-1/2 h-10 text-white text-sm md:text-base font-semibold rounded-lg"
        >
          Update Changes
        </Button>
      </div>
    </div>
  );
};

export default ProfilePage;
