import React, { useState } from 'react'
import { Modal, Form, Input, message } from 'antd';
import { adminChangePassword } from '../../../api/SettingRequestApi';

export default function ChangePasswordModal({ visible, onCancel, onSuccess}) {
    const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const onFinish = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error('New passwords do not match');
      return;
    }

    setIsLoading(true);
    try {     
      const response = await adminChangePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      });
      if (response.data.success) {
        message.success('Password changed successfully');
        form.resetFields();
        onSuccess();
      } else {
        throw new Error(response.data.message || 'Failed to change password');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      message.error(error.message || 'An error occurred while changing the password');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      title="Change Password"
      visible={visible}
      onCancel={handleCancel}
      okText="Change Password"
      okButtonProps={{ loading: isLoading }}
      onOk={() => form.submit()}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="oldPassword"
          label="Current Password"
          rules={[{ required: true, message: 'Please input your current password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: 'Please input your new password!' },
            { min: 6, message: 'Password must be at least 6 characters long' }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm New Password"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}
