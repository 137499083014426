import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { GoHome } from "react-icons/go";
import { FaLongArrowAltRight } from "react-icons/fa";
import { RiArrowUpDownLine } from "react-icons/ri";
import { CiClock2 } from "react-icons/ci";
import { Link } from "react-router-dom";
import { getAllCategory } from "../../../api/AdminRequest";
import { productCategoryPerformance, topBids, userEngagement } from "../../../api/DashboardRequestApi";
import { Spin } from "antd";

const Dashboard = () => {
  const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState(null);
  const [bids, setBids] = useState([]);
  const [categoryPerformance, setCategoryPerformance] = useState([]);
  const [userEngagementData, setUserEngagementData] = useState([]);
  const [timeRange, setTimeRange] = useState("year");
  const [loader, setLoader] = useState(false)
  const [engagementUserLoader, setengagementUserLoader] = useState(false)




  // // ------ TO GET THE CATEGORIES FROM API ------
  useEffect(() => {
    const getCategories = async () => {
      setLoader(true)
      try {
        const { data } = await getAllCategory()

        const formattedCategories = [
          { id: '', name: 'All Category' },
          ...data.categories.map(category => ({
            id: category._id,
            name: category.name
          }))
        ];
        setCategories(formattedCategories);
        // Set 'All Category' as active by default
        setActiveCategory(formattedCategories[0]);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoader(false)
      }
    }
    getCategories()
  }, [])

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };



  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoader(true);
      try {
        const [bidsResponse, performanceResponse] = await Promise.all([

          topBids({ categoryId: activeCategory.id }), // Fetch initial bids for 'All Category'
          productCategoryPerformance({ categoryId: activeCategory.id }) // Fetch initial performance for 'All Category'
        ]);


        setBids(bidsResponse.data.topBids);
        setCategoryPerformance(performanceResponse.data?.topCategories || []);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoader(false)
      }
    };
    if(activeCategory) {

      fetchInitialData();
    }
  }, [activeCategory]);

  const COLORS = ["#00C49F", "#9013FE", "#0088FE"];
  useEffect(() => {
    const fetchUserEngagement = async () => {
      setengagementUserLoader(true)
      // if (activeCategory) {
      try {
        const { data } = await userEngagement({
          categoryId: activeCategory?.id,
          timeRange: timeRange
        });
        const chartData = Object.entries(data.data).map(([key, value]) => ({
          // name: key,
          name: key.slice(0, 3),
          value: value
        }));
        setUserEngagementData(chartData);
      } catch (error) {
        console.error("Error fetching user engagement:", error);
      } finally {
        setengagementUserLoader(false)
      }
      // }
    };
    fetchUserEngagement();
  }, [activeCategory, timeRange]);

  return (

    <div className="">
      <Spin spinning={loader} >
        <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white  ">
          <div className="mb-4 flex items-center text-sm md:text-base">
            <Link to="./"> <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
            <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
              / Dashboard
            </span>
          </div>
          <div className="flex flex-wrap border border-[#9FACF0] p-2 md:p-3 w-fit rounded-[8px] justify-between mb-6">
            <div className="flex flex-wrap space-x-2">
              {categories.map((category) => (
                <button
                  key={category.id}
                  className={`px-4 py-2 rounded-md text-sm md:text-base ${activeCategory?.id === category.id
                    ? "bg-[#1955EB] text-white font-medium"
                    : "bg-[#F6F8FF] text-black font-medium"
                    }`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-6">
          {/* {activeTab === "All Category" ? ( */}
          <>
            <div className="bg-white p-4 md:p-6 border border-[#DBDBDB] rounded-lg flex-1">
              <p className="text-sm md:text-base text-start font-medium text-[#313131]">
                Product Category Performance
              </p>
              <div className="flex flex-wrap justify-start mt-4 space-x-4">
                {categoryPerformance.map((category, index) => (
                  <div key={index} className="flex items-center">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: COLORS[index] }}
                    ></div>
                    <span className="ml-2 text-xs md:text-sm">{category.productName}</span>
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={categoryPerformance}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#8884d8"
                      // dataKey="totalBidCount"
                      dataKey="bidCount"
                      label={false}
                      labelLine={false}
                    >
                      {categoryPerformance.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value, name, props) => [`${value} bids`, props.payload.categoryName]} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="bg-white p-4 md:p-6 border border-[#DBDBDB] rounded-lg shadow-sm flex-1">
              <Spin spinning={engagementUserLoader}>
                <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                  <h2 className="text-sm md:text-base text-[#313131] font-medium">
                    User Engagement
                  </h2>
                  <div className="flex space-x-2 gap-3">
                    <div className="relative inline-block">
                      <RiArrowUpDownLine className="absolute top-1/2 transform -translate-y-1/2 pointer-events-none" />
                      <select className="bg-[#F6F8FF] rounded-md py-1 pl-5 px-2 text-xs md:text-sm focus:outline-none">
                        <option>High to Low</option>
                        <option>Low to High</option>
                      </select>
                    </div>
                    <div className="relative inline-block flex">
                      <CiClock2 className="absolute top-1/2 font-bold text-black transform -translate-y-1/2 pointer-events-none" />
                      <select
                        className="bg-[#F6F8FF] rounded-md px-2 py-1 pl-4 md:text-sm focus:outline-none"
                        value={timeRange}
                        onChange={(e) => setTimeRange(e.target.value)}
                      >
                        <option value="month">In a Month</option>
                        <option value="year">In a Year</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-4">
                  <div className="w-3 h-3 bg-blue-500 rounded-full mr-2"></div>
                  <span className="text-xs md:text-sm">Properties</span>
                </div>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={userEngagementData}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="name" axisLine={false} tickLine={false} />
                      <YAxis axisLine={false} tickLine={false} />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#3b82f6"
                        strokeWidth={2}
                        dot={{ r: 5, fill: "#3b82f6", strokeWidth: 0 }}
                        activeDot={{ r: 8, fill: "#3b82f6" }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Spin>
            </div>
          </>

        </div>
        <div className="bg-[#003C98] text-white p-4 rounded-lg mt-6 w-full">
          <div className="lg:flex justify-start w-full">
            <div className="lg:w-[20%] flex flex-col justify-between">
              <p className="text-[20px] font-semibold lg:text-start text-center">
                Top Bids
              </p>
              <div className="flex items-center lg:mt-0 mt-3">
                <button className="text-[16px] w-full px-2 gap-3 text-[#1955EB] bg-white flex justify-center items-center py-2 font-medium rounded">
                  View all Bids <FaLongArrowAltRight />
                </button>
              </div>
            </div>
            {/* <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 w-full text-[16px] lg:mt-0 mt-4">
              {bids.map((bid, index) => (
                <div key={index}>{bid}</div>
              ))}
            </div> */}
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 w-full text-[16px] lg:mt-0 mt-4">
              {bids.slice(0, 15).map((bid, index) => (
                <div key={bid._id}>{`${index + 1}. ${bid.productName}`}</div>
              ))}
            </div>
          </div>
        </div>
      </Spin>
    </div>


  );
};

export default Dashboard;
