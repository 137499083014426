import React, { useEffect, useState } from 'react';
import { Table, Button, Checkbox, message, Spin, Avatar } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { LuArrowUpDown } from 'react-icons/lu';
import "../buyer/Indivisual.css"; // Replace with your actual CSS file path
import AssignSurveyForm from './AssignSurvey';
import { GoHome } from "react-icons/go";
import { IoMdDownload } from "react-icons/io";
import { Link } from 'react-router-dom';
import ErrorLogout from '../../../helpers/ErrorLogout';
import { getAllSurvey } from '../../../api/SurveyRequest';
import Pagination from '../../common/Pagination';
import * as XLSX from 'xlsx';
import moment from 'moment';

const BuyersIndividual = () => {
  const [activeTab, setActiveTab] = useState('');
  const [tabFilter, setTabFilter] = useState(''); // Update initial state to 'all'
  const [surveyAction, setSurveyAction] = useState({ type: null, id: null });
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [limit, setLimit] = useState(10);
  const [searchName, setSearchName] = useState("")

  const [totalItems, setTotalItems] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const tabs = [
    { label: 'All', value: '' },
    { label: 'Ongoing', value: 'ongoing' },
    { label: 'Pending', value: 'pending' },
    { label: 'Completed', value: 'complete' },
    { label: 'Accepted', value: 'accepted' },
  ];
  const [user, setUser] = useState([]); // Update initial state to '

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Function to handle button clicks

  const handleLimit = (e) => {
    const selectedValue = parseInt(e.target.value); // Convert value to integer if needed
    setLimit(selectedValue); // Update state with the selected value
    setCurrentPage(1);
  };

  useEffect(() => {
    const survey = async () => {
      try {
        setLoader(true);
        const { data } = await getAllSurvey({
          q: searchName,

          page: currentPage,
          limit: limit,
          // status: tabFilter
          liveStatus: tabFilter
        });
        if (data?.success) {
          console.log(data);
          setUser(data?.surveyList);
          setTotalPages(data?.totalPages);
          setCurrentPage(data?.currentPage);
          setTotalItems(data?.totalItems);
          setLoader(false);

        }
      } catch (error) {
        setLoader(false);

        console.log(error);
        console.log(error.message);
        if (error?.response?.status === 401) {
          ErrorLogout(error);
        }
      }
    };
    survey();
  }, [limit, currentPage, tabFilter, trigger]);

  // ------------ CHECK BOX-----------
  const handleSelectUser = (user) => {
    setSelectedUsers(prevSelectedUsers => {
      const isSelected = prevSelectedUsers.some(selectedUser => selectedUser._id === user._id);
      if (isSelected) {
        return prevSelectedUsers.filter(selectedUser => selectedUser._id !== user._id);
      } else {
        return [...prevSelectedUsers, user];
      }
    });
  };
  const handleCancelSelection = () => {
    setSelectedUsers([]);
    message.info('Selection cancelled.');
  };

  const handleDownloadData = () => {
    if (selectedUsers.length === 0) {
      message.warning('Please select at least one user to download.');
      return;
    }
  
    const worksheet = XLSX.utils.json_to_sheet(selectedUsers.map(user => ({
      'Survey No': user._id,
      'Surveyor Name': user.surveyorID?.name || 'N/A',
      'Client Name': user.clientName,
      'Client Email': user.clientEmail,
      'Primary Phone': user.phonePrimary,
      'Secondary Phone': user.phoneSecondary || 'N/A',
      'Location URL': user.locationUrl,
      'Address': user.address,
      'Instructions': user.instruction,
      'Survey Live Status': user.surveyLiveStatus,
      'Status': user.status,
      'Completion Date': moment(user.completionDate).format('YYYY-MM-DD HH:mm'),
      'Created At': moment(user.createdAt).format('YYYY-MM-DD HH:mm'),
      'Updated At': moment(user.updatedAt).format('YYYY-MM-DD HH:mm')
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

    XLSX.writeFile(workbook, "selected_users.xlsx");

    message.success(`Data for ${selectedUsers.length} user(s) downloaded successfully.`);
  };


  
  const handleSurveyAction = (type, id = null) => {
    setSurveyAction({ type, id });
  };


  if (surveyAction.type) {
    return <AssignSurveyForm page={surveyAction.type} id={surveyAction.id} />;
  }

  const columns = [
    // {
    //   title: () => <Checkbox />,
    //   key: 'select',
    //   render: () => <Checkbox />,
    //   width: 50,
    // },
    {
      title: 'Select',
      dataIndex: 'select',
      width: 60,
      render: (_, record) => (
        <Checkbox
          checked={selectedUsers.some(user => user._id === record._id)}
          onChange={() => handleSelectUser(record)}
        />
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Survey Name
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'clientName',
      key: 'clientName',
      render: text => (
        <div className="text-[#313131] text-[12px] font-medium">{text}</div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Surveyor
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'surveyorID',
      key: 'surveyorID',
      render: surveyorID => (
        <div className="flex items-center">
          {surveyorID?.profileImage ? (
            <img 
              src={surveyorID.profileImage} 
              alt="User avatar" 
              className="w-8 h-8 rounded-full mr-2" 
            />
          ) : (
            <Avatar 
              icon={<UserOutlined />} 
              className="w-8 h-8 mr-2" 
            />
          )}
          <span className="text-[#313131] text-[12px] font-medium">
            {surveyorID?.name}
          </span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Client Details
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'clientDetails',
      key: 'clientDetails',
      render: (_, record) => (
        <div className="flex flex-col">
          <span className="text-[#313131] text-[12px] font-medium">{record.clientName}</span>
          <span className="text-[#313131] text-[11px]">{record.clientEmail}</span>
          <span className="text-[#313131] text-[11px]">{record.phonePrimary}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Status
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'surveyLiveStatus',
      key: 'surveyLiveStatus',
      render: status => {
        const getStatusColor = (status) => {
          switch (status) {
            case 'complete':
              return 'bg-green-100 text-green-600';
            case 'ongoing':
              return 'bg-blue-100 text-blue-600';
            case 'pending':
              return 'bg-yellow-100 text-yellow-600';
            case 'accepted':
              return 'bg-purple-100 text-purple-600';
            default:
              return 'bg-gray-100 text-gray-600';
          }
        };

        const capitalizeFirstLetter = (string) => {
          return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return (
          <div className={`text-xs font-medium py-1 px-2 rounded-full ${getStatusColor(status)}`}>
            {capitalizeFirstLetter(status)}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Completion Date
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'completionDate',
      key: 'completionDate',
      render: (text) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear().toString().slice(-2);
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');

          return `${day}/${month}/${year} ${hours}:${minutes}`;
        };

        return (
          <span className="text-[#313131] text-[12px] font-medium">
            {formatDate(text)}
          </span>
        );
      },
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Assigned Date
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear().toString().slice(-2);
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');

          return `${day}/${month}/${year} ${hours}:${minutes}`;
        };

        return (
          <span className="text-[#313131] text-[12px] font-medium">
            {formatDate(text)}
          </span>
        );
      },
    },
    {
      title: 'Contact',
      key: 'contact',
      render: (_, record) => (
        <Button
          type="primary"
          className="bg-[#1955EB] hover:border-[#1955EB] text-white text-[12px] font-semibold"

          onClick={() => handleSurveyAction('edit', record._id)}
        >
          Reassign
        </Button>
      ),
    },
  ];

  return (
    <div className="">
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">

        <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
          <Link to="/">   <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          <span className="ml-2 text-[#000000] text-base md:text-lg font-medium">
            / Surveys-Survey List
          </span>
          <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
            {selectedUsers.length > 0 && (
              <>
                <button
                  icon={<IoMdDownload className='text-[#1955EB] text-[20px]' />}
                  onClick={handleDownloadData}
                  className='text-[#1955EB] underline text-[20px] font-medium'
                >
                  Download Selected ({selectedUsers.length})
                </button>
                <Button
                  onClick={handleCancelSelection}
                  className='text-[#FF4545] underline text-[20px] font-medium'
                >
                  Cancel
                </Button>
              </>
            )}
          </div>

        </div>
        <div className="flex justify-between items-center mb-4 flex-wrap gap-2">
          <div className="flex flex-wrap gap-2 border border-[#9FACF0] p-2 w-full lg:w-fit rounded-[8px] lg:justify-between">

            {tabs.map((tab) => (
              <button
                key={tab.value}
                className={`px-4 py-2 mb-2 md:mb-0 md:mr-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
                 ${activeTab === tab.value
                    ? "bg-[#1955EB] text-white font-medium"
                    : "bg-[#F6F8FF] text-black font-medium hover:bg-[#F6F8FF]"}`}
                onClick={() => {
                  setActiveTab(tab.value)
                  setTabFilter(tab.value)
                  setCurrentPage(1);

                }}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="flex flex-wrap gap-2">
            <button

              onClick={() => handleSurveyAction('create')}
              className="px-5 py-2 text-[16px] font-medium text-white bg-[#1955EB] hover:border-[#1955EB] border-[1px] rounded-md hover:bg-white hover:text-[#1955EB]">
              + Assign Survey
            </button>
          </div>
        </div>
      </div>
      <Spin spinning={loader}>



        <div className="pt-[0px] space-y-10">

          <Table
            columns={columns}
            dataSource={user}
            pagination={false}
            className="custom-table-header lg:w-full w-[250px] overflow-auto custom-pagination"
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            totalItem={totalItems}
            onPageChange={handlePageChange}
            handleLimit={handleLimit}

          />

        </div>
      </Spin>
    </div>
  );
};

export default BuyersIndividual;
