// import React, { useState } from "react";
// import { Upload, Button, Form, Input, message, Modal, Select } from "antd";
// import {
//   UploadOutlined,
//   EyeOutlined,
//   EyeInvisibleOutlined,
// } from "@ant-design/icons";
// import { GoHome } from "react-icons/go";
// import { MdOutlineDone } from "react-icons/md";
// import { PlusOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";
// import Manager from "./Manager";
// import CropperUploder from "../../imageUploader/CropperUploder";
// const { Option } = Select;
// const CreateManger = () => {
//   const [form] = Form.useForm();
//   const [showPassword, setShowPassword] = useState(false);
//   const [imageUrl, setImageUrl] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [fileList, setFileList] = useState([]);
//   const [imgPre, setImgPre] = useState('');

//   const handlePasswordToggle = () => {
//     setShowPassword((prev) => !prev);
//   };

//   const handleSubmit = (values) => {
//     console.log("Form submitted:", values);
//     message.success("Profile updated successfully!");
//   };

//   const beforeUpload = (file) => {
//     const isImage = file.type.startsWith("image/");
//     if (!isImage) {
//       message.error("You can only upload image files!");
//     }
//     return isImage;
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const handleUpdateChanges = () => {
//     setIsModalOpen(true);
//   };
//   const handleImageUpload = (file) => {
//     const isImage = file.type.startsWith("image/");
//     if (!isImage) {
//       message.error("You can only upload image files!");
//     }
//     return isImage;
//   };

//   const handleChange = (info) => {
//     if (info.file.status === "done") {
//       setImageUrl(URL.createObjectURL(info.file.originFileObj));
//     }
//   };
//   const [showManagerList, setShowManagerList] = useState(false);
//   const handleOpenManagerList = () => {
//     setShowManagerList(true);
//   };


//   // ------------------ PROFILE IMAGE---------

//   const handleDataFromChild = (data, base64Img) => {
//     const imgSelected = [
//       {
//         uid: "-1",
//         name: "image.png",
//         status: data ? "done" : "",
//         url: base64Img,
//         thumbUrl: base64Img,
//         originFileObj: data,
//       },
//     ];
//     setFileList(imgSelected);
//     setImgPre(base64Img);
//   };

//   const onChange = ({ fileList: newFileList }) => {
//     if (newFileList[0]?.status) { newFileList[0].status = "done"; }
//     setFileList(newFileList);
//     setImgPre(newFileList[0]?.originFileObj ? URL.createObjectURL(newFileList[0]?.originFileObj) : '');
//   };

//   const onPreview = async (file) => {
//     let src = file.url;
//     if (!src) {
//       src = await new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj);
//         reader.onload = () => resolve(reader.result);
//       });
//     }
//     const image = new Image();
//     image.src = src;
//     const imgWindow = window.open(src);
//     imgWindow?.document.write(image.outerHTML);
//   };

//   const uploderButton = (
//     <div className="flex flex-col items-center justify-center w-[200px] h-[150px] border border-dashed border-[#A3BBFF] rounded-md">
//       <PlusOutlined style={{ fontSize: '32px', color: '#A3BBFF' }} />
//       <p className="mt-2 text-[18px] font-medium text-[#313131]">Upload</p>
//     </div>
//   );

//   if (showManagerList) {
//     return <Manager />;
//   }
//   return (
//     <div>
//       <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
//         <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
//           <Link to="/">
//             {" "}
//             <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" />
//           </Link>
//           <span
//             onClick={handleOpenManagerList}
//             className="ml-2 cursor-pointer text-[#C7C7C7] text-base md:text-lg font-medium"
//           >
//             / Managers
//           </span>
//           <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
//             / CreateManager
//           </span>
//         </div>
//       </div>

//       <Form
//         form={form}
//         onFinish={handleSubmit}
//         layout="vertical"
//         initialValues={{
//           addressLine1: "",
//           addressLine2: "",
//           addressLine3: "",
//           fullName: "",
//           email: "",
//           password: "",
//         }}
//       >
//         {/* <Form.Item className="flex justify-center"> */}
//           <div className="w-40 h-40 flex items-center justify-center mb-4 lg:mb-0">
//             {/* <Upload
//               showUploadList={false}
//               beforeUpload={handleImageUpload}
//               className="w-full h-full border border-dashed cursor-pointer border-[#313131] flex items-center justify-center rounded-md"
//               customRequest={({ file, onSuccess }) => {
//                 setTimeout(() => {
//                   onSuccess(null, file);
//                   message.success("Image uploaded successfully!");
//                 }, 1000);
//               }}
//               onChange={handleChange}
//             >
//               {imageUrl ? (
//                 <img
//                   src={imageUrl}
//                   alt="Uploaded"
//                   className="w-full h-full object-cover rounded-md"
//                 />
//               ) : (
//                 <div className="flex flex-col items-center justify-center w-full h-full">
//                   <PlusOutlined
//                     style={{ fontSize: "32px", color: "#A3BBFF" }}
//                   />
//                   <p className="mt-2 text-[18px] font-medium text-[#313131]">
//                     Upload
//                   </p>
//                 </div>
//               )}
//             </Upload> */}
//              {fileList?.length !== 0 ? (
//               <Upload
//                 action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
//                 listType="picture-card"
//                 fileList={fileList}
//                 onChange={onChange}
//                 onPreview={onPreview}
//               >
//                 {fileList?.length < 1 && uploderButton}
//               </Upload>
//             ) : (
//               <div className="w-full h-full">
//                 <CropperUploder
//                   onDataFromChild={handleDataFromChild}
//                   uploderButton={uploderButton}
//                   identifier={'profileImage'}
//                 />
//               </div>
//             )}
//           </div>
//         {/* </Form.Item> */}

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:w-2/3 gap-4">
//           <div className="flex flex-col">
//             <span className="text-[#4A4A4C] text-start text-sm font-medium">
//               Address Line 1
//             </span>
//             <Form.Item
//               name="addressLine1"
//               className="mt-1"
//               rules={[
//                 { required: true, message: "Please input Address Line 1!" },
//               ]}
//             >
//               <Input
//                 placeholder="Address Line 1"
//                 className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
//               />
//             </Form.Item>
//           </div>

//           <div className="flex flex-col">
//             <span className="text-[#4A4A4C] text-start text-sm font-medium">
//               Full Name
//             </span>
//             <Form.Item
//               name="fullName"
//               className="mt-1"
//               rules={[
//                 { required: true, message: "Please input your Full Name!" },
//               ]}
//             >
//               <Input
//                 placeholder="Full Name"
//                 className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
//               />
//             </Form.Item>
//           </div>

//           <div className="flex flex-col">
//             <span className="text-[#4A4A4C] text-start text-sm font-medium">
//               Address Line 2
//             </span>
//             <Form.Item name="addressLine2" className="mt-1">
//               <Input
//                 placeholder="Address Line 2"
//                 className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
//               />
//             </Form.Item>
//           </div>

//           <div className="flex flex-col">
//             <span className="text-[#4A4A4C] text-start text-sm font-medium">
//               Email ID
//             </span>
//             <Form.Item
//               name="email"
//               className="mt-1"
//               rules={[
//                 {
//                   required: true,
//                   type: "email",
//                   message: "Please input a valid Email ID!",
//                 },
//               ]}
//             >
//               <Input
//                 type="email"
//                 placeholder="Email ID"
//                 className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
//               />
//             </Form.Item>
//           </div>

//           <div className="flex flex-col">
//             <span className="text-[#4A4A4C] text-start text-sm font-medium">
//               Address Line 3
//             </span>
//             <Form.Item name="addressLine3" className="mt-1">
//               <Input
//                 placeholder="Address Line 3"
//                 className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
//               />
//             </Form.Item>
//           </div>
//           <div className="flex flex-col">
//             <span className="text-[#4A4A4C] text-start text-sm font-medium">
//               Role
//             </span>
//             <Form.Item name="role" className="mt-1">
//             <Select
//           mode="multiple"
//           className="w-40 text-[#4A4A4C] text-[14px]  rounded  placeholder-[#4A4A4C]"
//           placeholder="Select an option"
//         >
//           <Option value="Survey Requests">Survey Requests</Option>
//           <Option value="Select Roles">Select Roles</Option>
//         </Select>
//             </Form.Item>
//           </div>


//           <div className="flex flex-col">
//             <span className="text-[#4A4A4C] text-start text-sm font-medium">
//               Password
//             </span>
//             <Form.Item
//               name="password"
//               className="mt-1"
//               rules={[
//                 { required: true, message: "Please input your Password!" },
//               ]}
//             >
//               <Input.Password
//                 placeholder="Password"
//                 iconRender={(visible) =>
//                   visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
//                 }
//                 className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
//               />
//             </Form.Item>
//           </div>
//         </div>

//         <div className="mt-6 flex flex-col md:flex-row md:space-x-4">
//           <Button className="w-full md:w-1/2 h-10 border font-semibold border-[#313131] text-[#313131] rounded-lg text-sm md:text-base">
//             Discard
//           </Button>
//           <Button
//             type="primary"
//             onClick={handleUpdateChanges}
//             className="mt-4 md:mt-0 w-full md:w-1/2 h-10 text-white text-sm md:text-base font-semibold rounded-lg"
//           >
//             Save Changes
//           </Button>
//         </div>
//       </Form>

//       <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
//         <div className="flex flex-col items-center py-3">
//           <div className="flex items-center mb-4">
//             <MdOutlineDone className="text-white p-2 rounded-full bg-[#3AB927] text-4xl" />
//           </div>
//           <div className="text-center text-sm md:text-base font-medium">
//             <p className="text-[#C7C7C7]">Manager 123</p>
//             <p className="text-[#313131] text-lg md:text-xl">
//               Account Created Successfully
//             </p>
//           </div>
//           <div className="flex justify-center mt-4">
//             <Button
//               onClick={handleCancel}
//               className="bg-[#313131] h-10 text-white text-sm md:text-base font-medium"
//             >
//               Dismiss
//             </Button>
//           </div>
//         </div>
//       </Modal>
//     </div>
//   );
// };

// export default CreateManger;

import React, { useEffect, useState } from "react";
import { Upload, Button, Form, Input, message, Modal, Select, Spin } from "antd";
import {
  UploadOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { GoHome } from "react-icons/go";
import { MdOutlineDone } from "react-icons/md";
import { Link } from "react-router-dom";
import CropperUploder from "../../imageUploader/CropperUploder";
import { createManager, getSingleManager, updateManager } from "../../../api/ManagerRequestApi";
import Manager from "./Manager";

const { Option } = Select;

const CreateManager = ({ page, id }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [imgPre, setImgPre] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false)
  const [showManagerList, setShowManagerList] = useState(false);
  const handleOpenManagerList = () => {
    setShowManagerList(true);
  };

  console.log(page);
  console.log(id);



  useEffect(() => {
    const fetchManager = async () => {
      if (page === 'edit' && id) {
        setLoader(true);
        try {
          const { data } = await getSingleManager(id);
          console.log(data);

          if (data.success && data.singleManager) {
            const manager = data.singleManager;
            form.setFieldsValue({
              name: manager.name,
              email: manager.email,
              role: manager.role,
              location: manager.location,
              address: manager.address,
            });

            if (manager.profileImage) {
              setFileList([{
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: manager.profileImage,
              }]);
              setImgPre(manager.profileImage);
            }
          }
        } catch (error) {
          console.error("Error fetching manager data:", error);
          message.error("Failed to load manager data");
        } finally {
          setLoader(false);
        }
      }
    };

    fetchManager();
  }, [page, id, form]);

  const handleSubmit = async (values) => {
    try {
      setLoader(true)
      const formData = new FormData();

      Object.keys(values).forEach(key => {
        if (key === 'role' || key === 'location') {
          values[key].forEach(item => formData.append(`${key}[]`, item));
        } else {
          formData.append(key, values[key]);
        }
      });
      if (fileList[0]?.originFileObj) {
        formData.append("profileImg", fileList[0].originFileObj);
      }
    if(page === "edit" && id){
      const response = await updateManager(id, formData);
      if (response.data.success) {
        setIsModalOpen(true);
        form.resetFields();
        setFileList([]);
        setImgPre('');
      } else {
        message.error("Failed to updated manager");
      }
    }else{

      const response = await createManager(formData);
      if (response.data.success) {
        setIsModalOpen(true);
        form.resetFields();
        setFileList([]);
        setImgPre('');
      } else {
        message.error("Failed to create manager");
      }
    }

  
    } catch (error) {
      console.error("Error creating manager:", error);
      message.error("An error occurred while creating the manager");
    } finally {
      setLoader(false)

    }
  };

  // ----------- PROFILE IMAGE-------------

  const handleDataFromChild = (data, base64Img) => {
    const imgSelected = [
      {
        uid: "-1",
        name: "image.png",
        status: data ? "done" : "",
        url: base64Img,
        thumbUrl: base64Img,
        originFileObj: data,
      },
    ];
    setFileList(imgSelected);
    setImgPre(base64Img);
  };

  const onChange = ({ fileList: newFileList }) => {
    if (newFileList[0]?.status) { newFileList[0].status = "done"; }
    setFileList(newFileList);
    setImgPre(newFileList[0]?.originFileObj ? URL.createObjectURL(newFileList[0]?.originFileObj) : '');
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploderButton = (
    <div className="flex flex-col items-center justify-center w-[200px] h-[150px] border border-dashed border-[#A3BBFF] rounded-md">
      <PlusOutlined style={{ fontSize: '32px', color: '#A3BBFF' }} />
      <p className="mt-2 text-[18px] font-medium text-[#313131]">Upload</p>
    </div>
  );
  if (showManagerList) {
    return <Manager />;
  }

  return (
    <div>
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
          <Link to="/">
            <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" />
          </Link>
          <Link onClick={handleOpenManagerList} className="ml-2 text-[#C7C7C7] text-base md:text-lg font-medium">
            / Managers
          </Link>
          <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
            / CreateManager
          </span>
        </div>
      </div>

      <Spin spinning={loader}>

        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"

          initialValues={{
            name: "",
            email: "",
            password: "",
            address: "",
            role: [],
            location: [],
          }}

        >
          <div className="w-40 h-40 flex items-center justify-center mb-4 lg:mb-0">
            {fileList?.length !== 0 ? (
              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
              >
                {fileList?.length < 1 && uploderButton}
              </Upload>
            ) : (
              <div className="w-full h-full">
                <CropperUploder
                  onDataFromChild={handleDataFromChild}
                  uploderButton={uploderButton}
                  identifier={'profileImage'}
                />
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:w-2/3 gap-4">
            {/* <div className="flex flex-col">
            <span className="text-[#4A4A4C] text-start text-sm font-medium">
              Address Line 1
            </span>
            <Form.Item
              name="addressLine1"
              className="mt-1"
              rules={[
                { required: true, message: "Please input Address Line 1!" },
              ]}
            >
              <Input
                placeholder="Address Line 1"
                className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
              />
            </Form.Item>
          </div> */}
            <div className="flex flex-col">
              <span className="text-[#4A4A4C] text-start text-sm font-medium">
                Location
              </span>
              <Form.Item
                name="location"
                className="mt-1"
                rules={[
                  { required: true, message: "Please select at least one location!" },
                ]}
              >
                <Select
                  mode="multiple"
                  // className="w-full text-[#4A4A4C] text-[14px] rounded placeholder-[#4A4A4C]"
                  className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
                  placeholder="Select Location"
                >
                  <Option value="Survey Requests">India</Option>
                  <Option value="Manager">Manager</Option>
                  {/* Add more role options as needed */}
                </Select>
              </Form.Item>
            </div>

            <div className="flex flex-col">
              <span className="text-[#4A4A4C] text-start text-sm font-medium">
                Full Name
              </span>
              <Form.Item
                name="name"
                className="mt-1"
                rules={[
                  { required: true, message: "Please input your Full Name!" },
                ]}
              >
                <Input
                  placeholder="Full Name"
                  className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
                />
              </Form.Item>
            </div>
            {/* 
          <div className="flex flex-col">
            <span className="text-[#4A4A4C] text-start text-sm font-medium">
              Address Line 2
            </span>
            <Form.Item name="addressLine2" className="mt-1">
              <Input
                placeholder="Address Line 2"
                className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
              />
            </Form.Item>
          </div> */}
            <div className="flex flex-col">
              <span className="text-[#4A4A4C] text-start text-sm font-medium">
                Address
              </span>
              <Form.Item name="address" className="mt-1">
                <Input
                  placeholder="address"
                  className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
                />
              </Form.Item>
            </div>

            <div className="flex flex-col">
              <span className="text-[#4A4A4C] text-start text-sm font-medium">
                Email ID
              </span>
              <Form.Item
                name="email"
                className="mt-1"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input a valid Email ID!",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Email ID"
                  className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
                />
              </Form.Item>
            </div>

            {/* <div className="flex flex-col">
            <span className="text-[#4A4A4C] text-start text-sm font-medium">
              Address Line 3
            </span>
            <Form.Item name="addressLine3" className="mt-1">
              <Input
                placeholder="Address Line 3"
                className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
              />
            </Form.Item>
          </div> */}

            <div className="flex flex-col">
              <span className="text-[#4A4A4C] text-start text-sm font-medium">
                Role
              </span>
              <Form.Item
                name="role"
                className="mt-1"
                rules={[
                  { required: true, message: "Please select at least one role!" },
                ]}
              >
                <Select
                  mode="multiple"
                  // className="w-full text-[#4A4A4C] text-[14px] rounded placeholder-[#4A4A4C]"
                  className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
                  placeholder="Select roles"
                >
                  <Option value="Survey Requests">Survey Requests</Option>
                  <Option value="Manager">Manager</Option>
                  {/* Add more role options as needed */}
                </Select>
              </Form.Item>
            </div>
            {page === "create" &&
              <div className="flex flex-col">
                <span className="text-[#4A4A4C] text-start text-sm font-medium">
                  Password
                </span>
                <Form.Item
                  name="password"
                  className="mt-1"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                    className="border-[#A3BBFF] text-[#4A4A4C] text-sm font-medium h-10"
                  />
                </Form.Item>
              </div>
            }
          </div>

          <div className="mt-6 flex flex-col md:flex-row md:space-x-4">
            <Button
              onClick={() => form.resetFields()}
              className="w-full md:w-1/2 h-10 border font-semibold border-[#313131] text-[#313131] rounded-lg text-sm md:text-base"
            >
              Discard
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="mt-4 md:mt-0 w-full md:w-1/2 h-10 text-white text-sm md:text-base font-semibold rounded-lg"
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </Spin>

      <Modal open={isModalOpen} onCancel={() =>{ setIsModalOpen(false); setShowManagerList(true) } } footer={null}>
        <div className="flex flex-col items-center py-3">
          <div className="flex items-center mb-4">
            <MdOutlineDone className="text-white p-2 rounded-full bg-[#3AB927] text-4xl" />
          </div>
          <div className="text-center text-sm md:text-base font-medium">
            <p className="text-[#C7C7C7]">Manager</p>
            <p className="text-[#313131] text-lg md:text-xl">
              Account Created Successfully
            </p>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              onClick={() => {setIsModalOpen(false)
                setShowManagerList(true)
              } }
              className="bg-[#313131] h-10 text-white text-sm md:text-base font-medium"
            >
              Dismiss
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateManager;
