import create from "zustand";
import {
  // createUser,
  // emailVerification,
  logout,
  // otpVerification,
  refreshToken,
} from "../api/CommonRequest";
const useAuthStore = create((set) => ({
  user: null,
  adminRefToken: null,
  isAuthenticated: false,
  step: 1,
  authType: "login",
  authModal: false,
  InstitutionalEmailStatus: false,
  loading: false,
  error: null,

  loginCall: async (data) => {
    
    try {
      set({
        user: data?.admin,
        adminRefToken: data.refreshToken,
        isAuthenticated: true,
        authModal: false,
        loading: false,
        error: null,
      });
    } catch (error) {
      //  loginSocketConnection();

      console.error("Login Call error:", error);
      set({
        user: null,
        isAuthenticated: false,
        loading: false,
        error:
          error.response?.data.message || "An error occurred during login.",
      });
    }
  },
  logout: async (token) => {
    try {
      const { data } = await logout(token);
      if (data?.success) {
        set({
          user: null,
          adminRefToken: null,
          isAuthenticated: false,
          loading: false,
          error: null,
        });
        localStorage.clear();
      } else {
        set({
          user: null,
          adminRefToken: null,
          isAuthenticated: false,
          loading: false,
          error: null,
        });
        localStorage.clear();
      }
    } catch (error) {
      console.error("Logout error:", error);
      set({
        user: null,
        isAuthenticated: false,
        adminRefToken: null,
        loading: false,
        error: null,
      });
    }
  },
  refreshToken: async (token) => {
    try {
      const { data } = await refreshToken(token);
      if (data?.success) {
        if (data?.user) {
          set({
            adminRefToken: data.refreshToken,
          });
        }
      } else {
        set({
          user: null,
          isAuthenticated: false,
          adminRefToken: null,
          branchRefToken: null,
        });
      }
    } catch (error) {
      console.error("Refresh Token error:", error);
      localStorage.clear();

      // navigate('/')
    }
  },
//   stepOne: async (personalInfo) => {
//     const verificationData = {
//       name: personalInfo.name,
//       email: personalInfo.email,
//     };
//     try {
//       set({
//         loading: true,
//         user: personalInfo,
//       });
//       const { data } = await emailVerification(verificationData);
//       console.log(data, "authstore");
//       if (data?.success) {
//         set({
//           step: 2,
//           loading: false,
//           error: null,
//         });
//       } else {
//         set({ error: data.error, loading: false });
//       }
//     } catch (error) {
//       console.error("Verification error:", error);
//       set({
//         user: null,
//         isAuthenticated: false,
//         loading: false,
//         error:
//           error.response?.data.message ||
//           "An error occurred during  verification.",
//       });
//     }
//   },
//   stepTwo: async (otp, personalInfo) => {
//     console.log(otp);
//     console.log(personalInfo);
//     const verificationData = {
//       otp: otp,
//       email: personalInfo.email,
//     };
//     try {
//       set({
//         loading: true,
//       });
//       const { data } = await otpVerification(verificationData);
//       console.log(data, "authstore");
//       if (data?.success) {
//         const verificationData = {
//           name: personalInfo.name,
//           email: personalInfo.email,
//         };

//         const { data } = await emailVerification(verificationData);
//         if (data?.success) {
//           set({
//             step: 3,
//             loading: false,
//             error: null,
//           });
//         }
//       } else {
//         set({ error: data.error, loading: false });
//       }
//     } catch (error) {
//       console.error("OTP Verification error:", error);
//       set({
//         isAuthenticated: false,
//         loading: false,
//         error:
//           error.response?.data.message ||
//           "An error occurred during OTP Verification.",
//       });
//     }
//   },
//   stepThree: async (otp, email, type,user,InstitutionalEmailStatus) => {
//     const verificationData = {
//       otp: otp,
//       email: email,
//       emailType: type,
//     };
//     try {
//       set({
//         loading: true,
//       });
//       const { data } = await otpVerification(verificationData);
//       console.log(data, "authstore");
//       if (data?.success) {
//         console.log(data?.emailType, "data?.type");
//         if (data?.emailType === "personalEmail"){
// if(!InstitutionalEmailStatus){
//   const { data } = await createUser(user);

//   if (data?.success) {
//     set({
//       step: 7,
//       loading: false,
//       error: null,
//     });
//   }
// }
// else{

//   set({
//     step: 4,
//     loading: false,
//     error: null,
//   });
// }
//         }
//         if (data?.emailType === "groupEmail") {
//           //   set((state) => ({
//           //     user: {
//           //       ...state.user, // spread the current state of user
//           //       companyEmail: data.email, // add or update groupEmail with data.email
//           //     },
//           //     step: 6,
//           //     loading: false,
//           //     error: null,
//           //   })
//           // );
//           const { data } = await createUser(user);

//           if (data?.success) {
//             set({
//               step: 7,
//               loading: false,
//               error: null,
//             });
//           }
//         }
//       } else {
//         set({ error: data.error, loading: false });
//       }
//     } catch (error) {
//       console.error("OTP Verification error:", error);
//       set({
//         isAuthenticated: false,
//         loading: false,
//         error:
//           error.response?.data.message ||
//           "An error occurred during OTP Verification.",
//       });
//     }
//   },
//   stepFour: async (name, groupEmail) => {
//     const verificationData = {
//       name: name,
//       email: groupEmail,
//     };
//     try {
//       set({
//         loading: true,
//       });
//       const { data } = await emailVerification(verificationData);
//       console.log(data, "authstore");
//       if (data?.success) {
//         set((state) => ({
//           user: {
//             ...state.user, // spread the current state of user
//             companyEmail: data.email, // add or update groupEmail with data.email
//           },
//           step: 5,
//           loading: false,
//           error: null,
//         }));
//       } else {
//         set({ error: data.error, loading: false });
//       }
//     } catch (error) {
//       console.error("Create User error:", error),
//       set({  error: error.response?.data.message ||"An error occurred in  Group Email.",loading:false});

//     }
//   },
//   stepSkip: async () => {
//     try {
//       set({
//         step: 6,
//       });
//     } catch (error) {
//       console.error("Create User error:", error);
//     }
//   },
//   stepFive: async (personalInfo) => {
//     console.log(personalInfo, personalInfo);
//     try {
//       set({
//         loading: true,
//       });
//       const { data } = await createUser(personalInfo);
//       console.log(data, "authstore");
//       if (data?.success) {
//         set({
//           step: 7,
//           loading: false,
//           error: null,
//           user: null,
//         });
//         return data;
//       } else {
//         set({ error: data.error, loading: false });
//       }
//     } catch (error) {
//       console.error("Create User error:", error);
//       set({
//         user: null,
//         isAuthenticated: false,
//         loading: false,
//         error:
//           error.response?.data.message ||
//           "An error occurred during Create User.",
//       });
//     }
//   },
//   backToLogin: async () => {
//     try {
//       set({
//         user: null,
//         userRefToken: null,
//         isAuthenticated: false,
//         step: 1,
//         authType: "login",
//         authModal: false,
//         InstitutionalEmailStatus: false,
//         loading: false,
//         error: null,

//       });
//     } catch (error) {
//       console.error("Logout error:", error);
//       set({
//         user: null,
//         userRefToken: null,
//         isAuthenticated: false,
//         step: 1,
//         authType: "login",
//         authModal: false,
//         InstitutionalEmailStatus: false,
//         loading: false,
//         error: null,
//       });
//     }
//   },
//   resendOTP: async (name, email) => {
//     const verificationData = {
//       name,
//       email,
//     };
//     console.log(name, email, "verificationData");
//     try {
//       set({
//         loading: true,
//       });
//       const { data } = await emailVerification(verificationData);
//       console.log(data, "authstore");
//       if (data?.success) {
//         set({
//           loading: false,
//           error: null,
//         });
//         console.log(data);
//         return data;
//       } else {
//         set({ error: data.error, loading: false });
//       }
//     } catch (error) {
//       console.error("Resend OTP error:", error);
//       set({
//         isAuthenticated: false,
//         loading: false,
//         error:
//           error.response?.data.message ||
//           "An error occurred during  verification.",
//       });
//     }
//   },
//   updateUser: async (updateduser) => {
//     set({
//       user: updateduser,
//     });
//   },
//   viewAuthModalOpen: async () => {
//     set({
//       authModal: true,
//     });
//   },
//   viewAuthModalClose: async () => {
//     set({
//       user: null,
//       userRefToken: null,
//       isAuthenticated: false,
//       step: 1,
//       authType: "login",
//       authModal: false,
//       InstitutionalEmailStatus: false,
//       loading: false,
//       error: null,
//     });
//   },
//   changeAuthType: async (type) => {
//     set({
//       authType: type,
//     });
//   },
//   InstitutionalEmailStatusChange: async (status) => {
//     set({
//       InstitutionalEmailStatus: status,
//     });
//   },
}));

// Hydrate the store with data from localStorage on creation
const storedAuthState = JSON.parse(localStorage.getItem("authState")) || {};
useAuthStore.setState(storedAuthState);

// Subscribe to state changes and persist the state to localStorage
useAuthStore.subscribe((state) => {
  localStorage.setItem("authState", JSON.stringify(state));
});

export default useAuthStore;
