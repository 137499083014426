import { axiosPrivate } from "./AxiosPrivate";



export const getAllSurvey = (paramsData) => axiosPrivate.get('/survey/getAllSurvey',{params:paramsData})
export const createSurvey = (data) => axiosPrivate.post(`/survey/createSurvey`, data)
export const getSingleSurvey = (id) => axiosPrivate.get(`/survey/getSingleSurvey/${id}`)
export const updateSurvey = (id, data) => axiosPrivate.put(`/survey/updateSurvey/${id}`, data)




