import React, { useEffect, useState } from 'react';
import { Table, Button, Checkbox, Tooltip, Modal, Spin, Radio, message } from 'antd';
import { InfoCircleOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { LuArrowUpDown } from 'react-icons/lu';
import { GoHome } from "react-icons/go";
import VarifyRequest from './VarifyRequest';
import { GiHazardSign } from "react-icons/gi";
import * as XLSX from 'xlsx';
import "./Indivisual.css";
import { IoMdDownload } from "react-icons/io";
import { Link } from 'react-router-dom';
import { getAllUsers, updateUserStatus } from '../../../api/UserRequest';
import { MdCircle, MdWarning } from 'react-icons/md';
import ErrorLogout from '../../../helpers/ErrorLogout';
import Pagination from '../../common/Pagination';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const BuyersIndividual = () => {

 
  const [showVerifyRequest, setShowVerifyRequest] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const showModal = (buyer) => {
    setSelectedContact(buyer);
    setIsModalVisible(true);
  };
  const [searchValue, setSearchValue] = useState("")
  const [subCategory, setSubCategory] = useState([]);
  const [type, setType] = useState(null);
  const [bidRange, setBidRange] = useState([1000, 10000000]);
  const [searchTerm, setSearchTerm] = useState(searchValue);
  const [loader, setLoader] = useState(false)

  const [totalPages, setTotalPages] = useState(5);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [trigger, setTrigger] = useState(false);

  const [activeTab, setActiveTab] = useState( '' ); // Update initial state to 'all'
  const [tabFilter, setTabFilter] = useState(''); // Update initial state to 'all'
  const [user, setUser] = useState([]); // Update initial state to '
  

  const [selectedUsers, setSelectedUsers] = useState([]);
const tabs = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Blacklisted', value: 'deactive' },
];

const handleSelectUser = (user) => {
  setSelectedUsers(prevSelectedUsers => {
    const isSelected = prevSelectedUsers.some(selectedUser => selectedUser._id === user._id);
    if (isSelected) {
      return prevSelectedUsers.filter(selectedUser => selectedUser._id !== user._id);
    } else {
      return [...prevSelectedUsers, user];
    }
  });
};
const handlePageChange = (page) => {
  if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
  }
};
const handleLimit = (e) => {
  const selectedValue = parseInt(e.target.value); // Convert value to integer if needed
  setLimit(selectedValue); // Update state with the selected value
  setCurrentPage(1);
}

const [sortBy, setSortBy] = useState({
  key: "1",
  label: "Recently added",
  value: "createdDate:desc",

  icon: <MdCircle size={20} />,
});


  useEffect(() => {
    const bidsCall = async () => {
      try {
        setLoader(true);
        const { data } = await getAllUsers({
          q: searchTerm,
          subCategory: subCategory,
          bidRange: bidRange,
          type: type === "Public" ? true : type === "Group" ? false : null,
          sort: sortBy.value,
          page: currentPage,
          limit: limit,
          status: tabFilter
        });
        
        if (data?.success) {
          
          setUser(data?.userList);
          setTotalPages(data?.totalPages);
          setCurrentPage(data?.currentPage);
          setTotalItems(data?.totalItems);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        console.log(error);
        console.log(error.message);
        if (error?.response?.status === 401) {
          ErrorLogout(error);
        }
      }
    };
    bidsCall();
  }, [searchTerm, sortBy, limit, currentPage, subCategory, type, bidRange, tabFilter, trigger]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOpenVerifyRequest = () => {
    setShowVerifyRequest(true);
  };

  if (showVerifyRequest) {
    return <VarifyRequest />;
  }
  const handleDownloadData = () => {
    if (selectedUsers.length === 0) {
      message.warning('Please select at least one user to download.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(selectedUsers.map(user => ({
      Name: user.name,
      Email: user.email,
      'Company Email': user.companyEmail,
      'Created Date': new Date(user.createdDate).toLocaleDateString(),
      'Wallet Balance': user.walletBalance,
      Status: user.status
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

    XLSX.writeFile(workbook, "selected_users.xlsx");

    message.success(`Data for ${selectedUsers.length} user(s) downloaded successfully.`);
  };

  const handleCancelSelection = () => {
    setSelectedUsers([]);
    message.info('Selection cancelled.');
  };


  // ----------------------- DELETE Conform ----------------
  const showUpdateConfirm = (id, action) => {
    Modal.confirm({
      title: 'Confirm Status Update',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to ${action} this user?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleUpdateUserStatus(id, action);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // ------------- BLOCK WARNING ----------------
  const showBlockConfirm = (id, currentStatus) => {
    let nextAction;
    let actionText;

    switch (currentStatus) {
      case 'active':
        nextAction = 'warn1';
        actionText = 'give the first warning to';
        break;
      case 'warn1':
        nextAction = 'warn2';
        actionText = 'give the second warning to';
        break;
      case 'warn2':
        nextAction = 'deactive';
        actionText = 'deactive';
        break;
      case 'deactive':
        nextAction = 'active';
        actionText = 'active';
        break;
      default:
        nextAction = 'warn1';
        actionText = 'give the first warning to';
    }

    Modal.confirm({
      title: 'Confirm Action',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to ${actionText} this user?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleUpdateUserStatus(id, nextAction);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleUpdateUserStatus = async (id, action) => { 
    try {
      const {data} = await updateUserStatus(id, action);
      
      if (data.success) {

        message.success('User status updated successfully');
        // Refresh the user list or update the local state
      } else {
        message.error('Failed to update user status');
      }
      setTrigger(!trigger)
    } catch (error) {
      console.log('Error updating user status:', error);
      message.error('An error occurred while updating user status');
    }
  };

  const columns = [
    {
      title: 'Select',
      dataIndex: 'select',
      width: 60,
      render: (_, record) => (
        <Checkbox
          checked={selectedUsers.some(user => user._id === record._id)}
          onChange={() => handleSelectUser(record)}
        />
      ),
    },

    {
      title: (
        <div className="flex items-center gap-2">
          Buyer Name
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="flex items-center">
          <img src={record.idImage1} alt="User avatar" className="w-8 h-8 rounded-full mr-2" />
          <span className="text-[#313131] text-[12px] font-medium">{text}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
         Email
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'email',
      key: 'email',
      render: text => <span className="text-[#313131] text-[12px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Company Email id
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'companyEmail',
      key: 'companyEmail',
      render: text => <span className="text-[#313131] text-[12px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Created Date
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text) => (
        <span className="text-[#313131] text-[12px] font-medium">
          {new Date(text).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}
        </span>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Wallet Balance
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'walletBalance',
      key: 'walletBalance',
      render: text => <span className="text-[#313131] text-[12px] font-medium">{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
  
        <div className="flex items-center gap-2">
        <span className={`py-1 rounded-full text-xs ${
          status === 'deactive' ? 'text-red-600' :
          'text-green-600'
        }`}>
          {status === 'deactive' ? 'Deactivate' : 'Active'}
        </span>
        {status === 'warn1' && (
          <Tooltip title="First Warning">
            <MdWarning className="text-yellow-600 ml-2" />
          </Tooltip>
        )}
        {status === 'warn2' && (
          <>
            <Tooltip title="First Warning">
              <MdWarning className="text-yellow-600 ml-2" />
            </Tooltip>
            <Tooltip title="Second Warning">
              <MdWarning className="text-orange-600 ml-2" />
            </Tooltip>
          </>
        )}
      </div>
      ),
    },
    {
      title: 'Contact',
      key: 'contact',
      render: (_, record) => (
        <Button
          type="primary"
          className="bg-[#1955EB] hover:border-[#1955EB] text-white text-[12px] font-semibold"
          onClick={() => showModal(record)}
        >
          Contact Info
        </Button>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="flex space-x-2">
         <Tooltip title={record.status === 'deactive' ? 'active' : 'Block/Warn'}>
            <Button 
              icon={<GiHazardSign />} 
              className={`text-white ${
                record.status === 'deactive' ? 'bg-green-500 hover:border-green-700' : 'bg-[#4A4A4C] hover:border-[#0445C3]'
              }`}
              onClick={() => showBlockConfirm(record._id, record.status)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button 
              icon={<DeleteOutlined />} 
              className="bg-[#FF4545] text-white hover:border-[#FF4545]" 
              onClick={() => showUpdateConfirm(record._id, 'delete')}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base">
          <Link to="/">    <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
            / Buyers - Individual
          </span>
          {/* <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
            <IoMdDownload className='text-[#1955EB] mt-1 text-[20px]' />
            <p className='text-[#1955EB] underline text-[20px] font-medium'>Download Data</p>
          </div> */}
           <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
          {selectedUsers.length > 0 && (
            <>
              <button
                icon={<IoMdDownload className='text-[#1955EB] text-[20px]' />}
                onClick={handleDownloadData}
                className='text-[#1955EB] underline text-[20px] font-medium'
              >
                Download Selected ({selectedUsers.length})
              </button>
              <Button
                onClick={handleCancelSelection}
                className='text-[#FF4545] underline text-[20px] font-medium'
              >
                Cancel
              </Button>
            </>
          )}
        </div>
        </div>

        <div className="flex justify-between items-center mb-4 flex-wrap gap-2">
          <div className="flex flex-wrap gap-2 border border-[#9FACF0] p-2 w-full lg:w-fit rounded-[8px] lg:justify-between">
            {tabs.map((tab) => (
                 <button
                 key={tab.value}
                 className={`px-4 py-2 mb-2 md:mb-0 md:mr-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
                 ${activeTab === tab.value
                     ? "bg-[#1955EB] text-white font-medium"
                     : "bg-[#F6F8FF] text-black font-medium hover:bg-[#F6F8FF]"}`}
                 onClick={() => {
                   setActiveTab(tab.value)
                   setTabFilter(tab.value)
                   setCurrentPage(1);

                 }}
               >
                 {tab.label}
               </button>
            ))}
          </div>
          {/* <Link to="/varifyRequest"> */}
          <div className="flex items-center space-x-2 border border-[#9FACF0] py-1 px-2 w-full lg:w-fit rounded-[8px]">
            <button className="px-4 py-2 text-black text-[16px] font-semibold">
              Verify Requests <span className='text-[#1955EB]'>21</span>
            </button>
            <button onClick={handleOpenVerifyRequest} className="px-5 py-2 text-[16px] font-medium text-white bg-[#1955EB] rounded-md hover:bg-white hover:text-[#1955EB]" >
              Open
            </button>
          </div>
          {/* </Link> */}
        </div>
      </div>
      <Spin spinning={loader}>
        
      <div className="pt-[0px] space-y-10 ">
        <Table
          columns={columns}
          dataSource={user}
          pagination={false}
       
          className="custom-table-header lg:w-full w-[250px] overflow-auto custom-pagination"
        />
            <div className="">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              limit={limit}
              totalItem={totalItems}
              onPageChange={handlePageChange}
              handleLimit={handleLimit}

            />
          </div>
      </div>
      </Spin>
      <Modal
        title={<h2 className="text-[24px] text-[#000000] font-medium border-b-2 pb-2">Contact Info</h2>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className='flex justify-center'>
            <Button className='flex justify-center bg-[#313131] h-10 w-fit text-[16px] text-[#FFFFFF]' key="dismiss" onClick={handleCancel}>
              Dismiss
            </Button>
          </div>,
        ]}
      >
        {selectedContact && (
          <div className="space-y-3">
            <div className="flex justify-between">
              <p className='text-[16px] text-[#313131] font-semibold'>Name:</p>
              <p className='text-[#4A4A4C] text-[16px] font-normal'>{selectedContact.name}</p>
            </div>
            <div className="flex justify-between">
              <p className='text-[16px] text-[#313131] font-semibold'>Email:</p>
              <p className='text-[#4A4A4C] text-[16px] font-normal'>{selectedContact.email}</p>
            </div>
            <div className="flex justify-between">
              <p className='text-[16px] text-[#313131] font-semibold'>Company Email:</p>
              <p className='text-[#4A4A4C] text-[16px] font-normal'>{selectedContact.companyEmail}</p>
            </div>
            <div className="flex justify-between">
              <p className='text-[16px] text-[#313131] font-semibold'>Phone:</p>
              <p className='text-[#4A4A4C] text-[16px] font-normal'>{selectedContact.phone || 'N/A'}</p>
            </div>
            <div className="flex justify-between">
              <p className='text-[16px] text-[#313131] font-semibold'>Address:</p>
              <div className='text-[#4A4A4C] text-[16px] font-normal'>
                <p>{selectedContact.address?.line1 || 'N/A'}</p>
                <p>{selectedContact.address?.line2 || ''}</p>
                <p>{selectedContact.address?.line3 || ''}</p>
              </div>
            </div>
            <div className="flex justify-between">
              <p className='text-[16px] text-[#313131] font-semibold'>Status:</p>
              <p className='text-[#4A4A4C] text-[16px] font-normal'>{selectedContact.status}</p>
            </div>
            <div className="flex justify-between">
              <p className='text-[16px] text-[#313131] font-semibold'>Created At:</p>
              <p className='text-[#4A4A4C] text-[16px] font-normal'>
                {new Date(selectedContact.createdDate).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                })}
              </p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BuyersIndividual;
