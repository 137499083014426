import React, { useEffect, useState } from 'react';
import { Table, Button, Checkbox, Tooltip, Modal, Image, Spin, message, Form, Input } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { FaFilter } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { LuArrowUpDown } from 'react-icons/lu';
import { GoHome } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import Userimg from "../../../img/userimg.svg"
import { MdOutlineDone } from "react-icons/md";
import { Link } from 'react-router-dom';
import IndivisualPage from "./Indivisual"
import "./Indivisual.css";
import { getVerifyRequest, rejectUserVerification, updateUserStatus, verifyUser } from '../../../api/UserRequest';
import ErrorLogout from '../../../helpers/ErrorLogout';
import Pagination from '../../common/Pagination';
const { TextArea } = Input;
const VarifyRequest = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showIndivisualPage, setShowIndivisualPage] = useState(false);

  const [loader, setLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(5);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [trigger, setTrigger] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [user, setUser] = useState([])
  const [currentUser, setCurrentUser] = useState("");
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectingUser, setRejectingUser] = useState(null);
  const [form] = Form.useForm();


  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOpenIndivisualPage = () => {
    setShowIndivisualPage(true);
  };
  const handleLimit = (e) => {
    const searchName = parseInt(e.target.value); // Convert value to integer if needed
    setLimit(searchName); // Update state with the selected value
    setCurrentPage(1);
  }





  useEffect(() => {
    const bidsCall = async () => {
      try {
        setLoader(true);
        const { data } = await getVerifyRequest({
          q: searchName,
          page: currentPage,
          limit: limit,

        });

        if (data?.success) {
          console.log(data);

          setUser(data?.users);
          setTotalPages(data?.totalPages);
          setCurrentPage(data?.currentPage);
          setTotalItems(data?.totalItems);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        console.log(error);
        console.log(error.message);
        if (error?.response?.status === 401) {
          ErrorLogout(error);
        }
      }
    };
    bidsCall();
  }, [limit, currentPage, trigger]);

// ------- VERFY USER---------------
const showConfirm = (user) => {
  Modal.confirm({
    title: 'Are you sure you want to verify this user?',
  
    onOk() {
      VerifyUser(user._id, 'verified', user.name);
    },
    onCancel() {
      console.log('Verification cancelled');
    },
  });
};

const VerifyUser = async (id, status, name) => {
  try {
    const { data } = await verifyUser(id);
    console.log(data);
    
    if (data.success) {
      setCurrentUser({ name });
      setIsModalOpen(true);
      setTrigger(!trigger); // Trigger a re-fetch of the user list
    } else {
      message.error('Verification failed. Please try again.');
    }
  } catch (error) {
    console.error('Error verifying user:', error);
    message.error('An error occurred while verifying the user.');
  }
};

// ------------------ REJECT USER----------
const showRejectConfirm = (user) => {
  setRejectingUser(user);
  setIsRejectModalOpen(true);
  form.resetFields();
};

const handleRejectCancel = () => {
  setIsRejectModalOpen(false);
  setRejectingUser(null);
  form.resetFields();
};

const handleReject = async () => {
  try {
    const values = await form.validateFields();
    const { data } = await rejectUserVerification(rejectingUser._id, values.rejectReason);
    if (data.success) {
      message.success(`${rejectingUser.name}'s verification request has been rejected.`);
      setTrigger(!trigger); // Trigger a re-fetch of the user list
      handleRejectCancel();
    } else {
      message.error('Rejection failed. Please try again.');
    }
  } catch (error) {
    if (error.errorFields) {
      return; // Form validation error, don't proceed
    }
    console.error('Error rejecting user:', error);
    message.error('An error occurred while rejecting the user.');
  }
};

 // ----------------------- DELETE Conform ----------------
 const showUpdateConfirm = (id, action) => {
  Modal.confirm({
    title: 'Confirm Delete',
    icon: <ExclamationCircleOutlined />,
    content: `Are you sure you want to ${action} this user?`,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      return handleUpdateUserStatus(id, action);
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};
const handleUpdateUserStatus = async (id, action) => { 
  try {
    const {data} = await updateUserStatus(id, action);
    
    if (data.success) {

      message.success('User status updated successfully');
      // Refresh the user list or update the local state
    } else {
      message.error('Failed to update user status');
    }
    setTrigger(!trigger)
  } catch (error) {
    console.log('Error updating user status:', error);
    message.error('An error occurred while updating user status');
  }
};

  if (showIndivisualPage) {
    return <IndivisualPage />;
  }

  const columns = [
    {
      title: () => <Checkbox />,
      key: 'select',
      render: () => <Checkbox />,
      width: 50,
    },
    {
      title: (
        <div className="flex text-[13px] items-center gap-2">
          Buyer Name
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: text => (
        <div className="flex items-center">
          {/* <img src={Userimg} alt="User avatar" className="w-8 h-8 rounded-full mr-2" /> */}
          <span className="text-[#313131] text-[12px] font-medium">{text}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex text-[13px] items-center gap-2">
          Nationality
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'nationality',
      key: 'nationality',
      render: text => <span className="text-[#313131] text-[12px] font-medium whitespace-pre-line">{text}</span>,
    },
    {
      title: (
        <div className="flex text-[13px] items-center gap-2">
          ID Type
          <FaFilter className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'idType',
      key: 'idType',
      render: text => <span className="text-[#313131] text-[12px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex text-[13px] items-center gap-2">
          ID Number
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'idNumber',
      key: 'idNumber',
      render: text => <span className="text-[#313131] text-[12px] font-medium">{text}</span>,
    },
    {
      title: <span className="text-[13px]">Photo-Front</span>,
      dataIndex: 'idImage1',
      key: 'idImage1',
      render: (text) => (
        <Image
          src={text}
          alt="ID Front"
          className="object-cover"
          width={64}
          height={40}
          preview={{
            maskClassName: 'backdrop-filter backdrop-blur-sm',
            mask: <div className="text-white">Preview</div>,
          }}
        />
      ),
    },
    {
      title: <span className="text-[13px]">Photo-Back</span>,
      key: 'idImage2',
      dataIndex: 'idImage2',
      render: (text) => (
        <Image
          src={text}
          alt="ID Back"
          className="object-cover"
          width={64}
          height={40}
          preview={{
            maskClassName: 'backdrop-filter backdrop-blur-sm',
            mask: <div className="text-white">Preview</div>,
          }}
        />
      ),
    },
    {
      title: <span className="text-[13px]">Contact</span>,
      key: 'contact',
      render: (_, record) => (
        <Button 
          type="primary" 
          onClick={() => showConfirm(record)} 
          className="bg-[#1955EB] hover:border-[#1955EB] text-white text-[12px] font-semibold"
        >
          Verify ID
        </Button>
      ),
    },
    {
      title: <span className="text-[13px]">Actions</span>,
      key: 'actions',
      render: (_, record) => (
        <div className="flex space-x-2">
          <Tooltip title="Reject">
            <Button
              icon={<IoMdClose />}
              onClick={() => showRejectConfirm(record)}
              className="bg-[#4A4A4C] text-[20px] text-white hover:border-[#0445C3] hover:bg-[#363638]"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button 
            onClick={()=> showUpdateConfirm(record._id, "delete")}
            icon={<DeleteOutlined />} className="bg-[#FF4545] text-white hover:border-[#FF4545] hover:bg-[#E63E3E]" />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
          <Link to="/">  <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          {/* <Link to="/buyer/Indivisual"> */}
          <span onClick={handleOpenIndivisualPage} className="ml-2 cursor-pointer text-[#C7C7C7] text-base md:text-lg font-medium">
            / Buyers-individuals
          </span>
          {/* </Link> */}
          <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
            / Verify Request
          </span>
        </div>
      </div>
      <div className="pt-[0px] space-y-10">
      <Spin spinning={loader}>

        <Table
          columns={columns}
          dataSource={user}
         pagination={false}
        />
           <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              limit={limit}
              totalItem={totalItems}
              onPageChange={handlePageChange}
              handleLimit={handleLimit}

            />
        </Spin>
        </div>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className=""
      >
        <div className="flex flex-col items-center py-3">
          <div className="flex items-center mb-4">
            <MdOutlineDone className="text-white p-1 rounded-full bg-[#3AB927] text-4xl" />
          </div>
          <div className="text-center text-[16px] font-medium">
            <p className="text-[#C7C7C7] text-[16px] font-medium">{currentUser?.name}</p>
            <p className="text-[#313131] text-[24px] font-medium ">Account Varified Successfully</p>
          </div>
          <div className="flex justify-center mt-3">
            <Button onClick={handleCancel} className="bg-[#313131] h-10 text-white text-[18px] font-medium">
              Dismiss
            </Button>
          </div>
        </div>
      </Modal>

        {/* Reject Confirmation Modal */}
        <Modal
        title={`Reject ${rejectingUser?.name}'s Verification Request`}
        open={isRejectModalOpen}
        onCancel={handleRejectCancel}
        footer={[
          <Button key="cancel" onClick={handleRejectCancel}>
            Cancel
          </Button>,
          <Button 
            key="reject" 
            type="primary" 
            danger 
            onClick={handleReject}
          >
            Reject
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="rejectReason"
            label="Reason for rejection"
            rules={[
              { required: true, message: 'Please provide a reason for rejection' },
              { min: 10, message: 'Reason must be at least 10 characters long' }
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Enter rejection reason..."
              className="mt-2"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VarifyRequest;
