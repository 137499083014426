import React, { useEffect, useState } from 'react';
import { Table, Button, Checkbox, Tooltip, Spin, message, Modal, Avatar } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { DeleteOutlined, EditOutlined, CheckCircleOutlined, UserOutlined} from '@ant-design/icons';
import { MdBlockFlipped } from "react-icons/md";
import { LuArrowUpDown } from 'react-icons/lu';
import Userimg from "../../../img/userimg.svg";

import "../buyer/Indivisual.css";
import CreateSurveyer from './CreateSurvey';
import { GoHome } from "react-icons/go";
import { IoMdDownload } from "react-icons/io";
import { Link } from 'react-router-dom';

import ErrorLogout from '../../../helpers/ErrorLogout';
import { getAllSurveyor, updateSurveyorStatus } from '../../../api/SurveyorApiRequest';
import Pagination from '../../common/Pagination';
import * as XLSX from 'xlsx';

const { confirm } = Modal;
const BuyersIndividual = () => {
  const [activeTab, setActiveTab] = useState('');
  const [surveyorAction, setSurveyorAction] = useState({ type: null, id: null });
 

  const [loader, setLoader] = useState(false)

  const [totalPages, setTotalPages] = useState(5);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [trigger, setTrigger] = useState(false);
  const [searchName, setSearchName] = useState("")
  const [selectedUsers, setSelectedUsers] = useState([]);


  const [tabFilter, setTabFilter] = useState(''); // Update initial state to 'all'
  const [user, setUser] = useState([]); // Update initial state to '
  const tabs = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Blacklisted', value: 'deactive' },
    { label: 'Deleted', value: 'delete' },
  ];


  useEffect(() => {
    const bidsCall = async () => {
      try {
        setLoader(true);
        const { data } = await getAllSurveyor({
          q: searchName,

          page: currentPage,
          limit: limit,
          status: tabFilter
        });
        if (data?.success) {

          setUser(data?.surveyorList);
          setTotalPages(data?.totalPages);
          setCurrentPage(data?.currentPage);
          setTotalItems(data?.totalItems);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        console.log(error);
        console.log(error.message);
        if (error?.response?.status === 401) {
          ErrorLogout(error);
        }
      }
    };
    bidsCall();
  }, [limit, currentPage, tabFilter, trigger]);


  // ------------ CHECK BOX-----------
  const handleSelectUser = (user) => {
    setSelectedUsers(prevSelectedUsers => {
      const isSelected = prevSelectedUsers.some(selectedUser => selectedUser._id === user._id);
      if (isSelected) {
        return prevSelectedUsers.filter(selectedUser => selectedUser._id !== user._id);
      } else {
        return [...prevSelectedUsers, user];
      }
    });
  };
  const handleCancelSelection = () => {
    setSelectedUsers([]);
    message.info('Selection cancelled.');
  };

  const handleDownloadData = () => {
    if (selectedUsers.length === 0) {
      message.warning('Please select at least one user to download.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(selectedUsers.map(user => ({
      Name: user?.name,
      Email: user?.email,
      Phone: user?.phone,
      category: user?.category,
      location: user?.location,
      address: user?.address,
      'Created Date': new Date(user.createdDate).toLocaleDateString(),
      Status: user.status
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

    XLSX.writeFile(workbook, "selected_users.xlsx");

    message.success(`Data for ${selectedUsers.length} user(s) downloaded successfully.`);
  };


  // -------------------- CREATE AND EDIT PAGE------------------

  const handleSurveyorAction = (type, id = null) => {
    setSurveyorAction({ type, id });
  };
  

  if (surveyorAction.type) {
    return <CreateSurveyer page={surveyorAction.type} id={surveyorAction.id} />;
  }



  // -------------- UPDATE STATUS--------------
  const handleUpdateStatus = async (record, status) => {


    confirm({
      title: `Do you want to ${status} ${record.name}?`,
      icon: <ExclamationCircleOutlined />,
      content: `This action will ${status} the surveyor. Are you sure you want to proceed?`,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk: async () => {
        try {
          setLoader(true);
          const { data } = await updateSurveyorStatus(record._id, status);
          if (data?.success) {
            message.success(`Surveyor ${status}d successfully.`);
            setTrigger(!trigger);
          } else {
            message.error(`Failed to ${status} surveyor.`);
          }
        } catch (error) {
          console.log(error);
          console.log(error.message);
          if (error?.response?.status === 401) {
            ErrorLogout(error);
          }
          message.error(`An error occurred while trying to ${status} the surveyor.`);
        } finally {
          setLoader(false);
        }
      },
      onCancel() {
        console.log('Action canceled');
      },
    });
  };

  // --------- PAGINATION HANDLERS-------
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleLimit = (e) => {
    const selectedValue = parseInt(e.target.value); // Convert value to integer if needed
    setLimit(selectedValue); // Update state with the selected value
    setCurrentPage(1);
  }


  const columns = [
    // {
    //   title: () => <Checkbox />,
    //   key: 'select',
    //   render: () => <Checkbox />,
    //   width: 50,
    // },
    {
      title: 'Select',
      dataIndex: 'select',
      width: 60,
      render: (_, record) => (
        <Checkbox
          checked={selectedUsers.some(user => user._id === record._id)}
          onChange={() => handleSelectUser(record)}
        />
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Surveyor Name
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <div className="flex items-center">
         {record?.profileImage ? (
            <img 
              src={record.profileImage} 
              alt="User avatar" 
              className="w-8 h-8 rounded-full mr-2" 
            />
          ) : (
            <Avatar 
              icon={<UserOutlined />} 
              className="w-8 h-8 mr-2" 
            />
          )}
          <span className="text-[#313131] text-[12px] font-medium">{record.name}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Status
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <div className={`text-xs font-medium py-1 rounded-full ${status === 'active' ? 'text-green-600' :
          status === 'deactive' ? 'text-gray-600' :
            'text-red-600'
          }`}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Category
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      render: text => <span className="text-[#313131] text-[12px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Survey Locations
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'location',
      key: 'location',
      render: text => <span className="text-[#313131] text-[12px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Started Date
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        return <span className="text-[#313131] text-[12px] font-medium">{formattedDate}</span>;
      },
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Survey No
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: '_id',
      key: '_id',
      render: text => (
        <Tooltip title={text} placement="top" overlayClassName="custom-tooltip">
          <span className="text-[#313131] text-[12px] font-medium">
            {text.slice(0, 10)}
            {text.length > 10 && '...'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="flex space-x-2">
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} 
             onClick={() => handleSurveyorAction('edit', record._id)} 
             className="bg-[#1955EB] text-white hover:border-[#1955EB] hover:bg-[#1945E0]" />
          </Tooltip>
          <Tooltip title={
            record.status === 'delete' ? 'Disabled' :
              record.status === 'deactive' ? 'Activate' : 'Deactivate'
          }>
            <Button
              onClick={() => {
                if (record.status !== 'delete') {
                  handleUpdateStatus(record, record.status === 'deactive' ? 'active' : 'deactive')
                }
              }}
              icon={record.status === 'deactive' ? <CheckCircleOutlined /> : <MdBlockFlipped />}
              className={`text-white ${record.status === 'delete' ? 'bg-gray-400 cursor-not-allowed' :
                record.status === 'eactive'
                  ? 'bg-green-500 hover:bg-green-600 hover:border-green-600'
                  : 'bg-[#4A4A4C] hover:bg-[#3A3A3C] hover:border-[#4A4A4C]'
                }`}
              disabled={record.status === 'delete'}
            />
          </Tooltip>

          <Tooltip title={record.status === 'delete' ? 'Activate' : 'Delete'}>
            <Button
              onClick={() => handleUpdateStatus(record, record.status === 'delete' ? 'active' : 'delete')}
              icon={record.status === 'delete' ? <CheckCircleOutlined /> : <DeleteOutlined />}
              className={`text-white ${record.status === 'delete'
                  ? 'bg-green-500 hover:bg-green-600 hover:border-green-600'
                  : 'bg-[#FF4545] hover:bg-[#FF3535] hover:border-[#FF4545]'
                }`}
            />
          </Tooltip>
        </div>
      ),
    },
  ];



  return (
    <div className="">
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
          <Link to="/"><GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          <span className="ml-2 text-[#000000] text-base md:text-lg font-medium">
            / Surveys - Surveyors
          </span>
   
          <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
            {selectedUsers.length > 0 && (
              <>
                <button
                  icon={<IoMdDownload className='text-[#1955EB] text-[20px]' />}
                  onClick={handleDownloadData}
                  className='text-[#1955EB] underline text-[20px] font-medium'
                >
                  Download Selected ({selectedUsers.length})
                </button>
                <Button
                  onClick={handleCancelSelection}
                  className='text-[#FF4545] underline text-[20px] font-medium'
                >
                  Cancel
                </Button>
              </>
            )}
          </div>

        </div>
        <div className="flex justify-between items-center mb-4 flex-wrap gap-2">
          <div className="flex flex-wrap gap-2 border border-[#9FACF0] p-2 w-full lg:w-fit rounded-[8px] lg:justify-between">
            {tabs.map((tab) => (
              <button
                key={tab.value}
                className={`px-4 py-2 mb-2 md:mb-0 md:mr-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
                 ${activeTab === tab.value
                    ? "bg-[#1955EB] text-white font-medium"
                    : "bg-[#F6F8FF] text-black font-medium hover:bg-[#F6F8FF]"}`}
                onClick={() => {
                  setActiveTab(tab.value)
                  setTabFilter(tab.value)
                  setCurrentPage(1);

                }}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => handleSurveyorAction('create')}
              className="px-5 py-2 text-[16px] font-medium text-white bg-[#1955EB] hover:border-[#1955EB] border-[1px] rounded-md hover:bg-white hover:text-[#1955EB]">
              + Create Surveyor
            </button>
          </div>
        </div>
      </div>
      <Spin spinning={loader}>
        <div className="pt-[0px] space-y-10">

          <Table
            columns={columns}
            dataSource={user}
            pagination={false}
            className="custom-table-header lg:w-full w-[250px] overflow-auto custom-pagination"
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            totalItem={totalItems}
            onPageChange={handlePageChange}
            handleLimit={handleLimit}

          />
        </div>
      </Spin>
    </div>
  );
};

export default BuyersIndividual;
