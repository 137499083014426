import { useEffect, useState } from "react";
import { Button, message, Form, Spin } from "antd";
import PropTypes from "prop-types";
import { forgotPassword, otpVerification } from "../../../api/CommonRequest";
import OTPInput from "otp-input-react";
function EnterOTP({ setStep, emailID }) {
  const [email, setEmail] = useState(emailID);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [otp, setOtp] = useState([]);
  const [timer, setTimer] = useState(60); // Timer set to 60 seconds
  const [timerActive, setTimerActive] = useState(true);
  useEffect(() => {
    setEmail(emailID);
    let intervalId;


    if (timerActive) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }


    return () => {
      clearInterval(intervalId);
    };
  }, [timerActive, emailID]);
  const formatTimer = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };


  // Handle timer completion
  useEffect(() => {
    if (timer === 0) {
      setTimerActive(false); // Stop timer countdown
    }
  }, [timer]);


  const errorMessage = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };
  const successMessage = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };


  const handleReSendOTP = async () => {
    const emailData = { email: email };
    try {
      setLoader1(true);
      const { data } = await forgotPassword(emailData);
      if (data?.success) {
        successMessage("OTP Sended");
        setLoader1(false);
      } else {
        errorMessage(data.message || "Failed to send OTP");
        setLoader1(false);
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      errorMessage(error.message);


      setLoader1(false);
    }
  };


  const handleOtpSubmit = async () => {
    if (otp.length !== 6) {
      return errorMessage("Enter Full OTP");
    }
    const verificationData = { email, otp };
    try {
      setLoader(true);
      const { data } = await otpVerification(verificationData);
      console.log(data);
      if (data?.success) {
        successMessage("OTP Sended");
        setStep(3);
      } else {
        errorMessage(data.message);
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      errorMessage(error.response.data.message);


      setLoader(false);
    }
  };


  const handleBack = () => {
    setStep(1);
  };
  return (

    <Spin spinning={loader1}>
   
      <div>
        <h1 className="text-[30px] lg:text-start md:text-center text-center font-bold mb-3">
          Enter<span className="font-normal"> OTP</span>
        </h1>
        <p className="lg:text-start md:text-center font-normal text-[14px] text-[#313131]">
          Enter the verification code we sent to{" "}
          <span className="font-semibold text-[14px]">{email} </span>
        </p>
        <Form onFinish={handleOtpSubmit}>
          <div
            id="otp"
            className="flex flex-row  lg:justify-start md:justify-center justify-center text-center mt-4"
          >
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              inputStyles={{
                backgroundColor: "white",
                border: "1px solid #A3BBFF",
                borderRadius: "2px",
                padding: "14px",
                margin: "8px",
                width: "42px",
                height: "42px",
                textAlign: "center",
                fontSize: "16px",
                color: "#000000",
                outline: "none",
              }}
              focusStyles={{
                borderColor: "black",
                boxShadow: "0 0 0 2px rgba(404, 150, 54, 0.4)",
              }}
            />
          </div>
          {timer > 0 ? (
            <p className="mt-2 text-[#313131] font-normal  lg:text-start md:text-start">
              Please enter OTP within {formatTimer(timer)}
            </p>
          ) : (
            <p className="mt-2 text-[#313131] font-normal cursor-pointer lg:text-start md:text-center">
              Didn’t you receive the OTP?
              <span
                className="font-semibold underline"
                onClick={handleReSendOTP}
              >
                Resend OTP
              </span>
            </p>
          )}
          <div className="flex lg:mt-[50px] md:mt-[50px] mt-[30px] pb-3 lg:w-[80%] md:w-[80%] w-full gap-4 ">
            <Button
              onClick={handleBack}
              className="w-full  h-12 border bg-white border-blue-600 text-blue-600 text-base font-semibold hover:bg-slate-400"
            >
              Back
            </Button>


            <Button
              loading={loader}
              htmlType="submit"
              type="primary"
              className="w-full  h-12 bg-[#1955EB] text-white text-base font-semibold hover:bg-[#1955EB]"
            >
              Confirm & Next
            </Button>
          </div>
        </Form>
      </div>
      {contextHolder}
    </Spin>
  );
}


EnterOTP.propTypes = {
  setStep: PropTypes.func.isRequired,
  emailID: PropTypes.string.isRequired,
};
export default EnterOTP;