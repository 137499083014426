import React, { useEffect, useState } from "react";
import { Table, Checkbox, Button, Select, Rate, Tooltip, message, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { SearchOutlined} from "@ant-design/icons";
import { MdBlockFlipped } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import "../buyer/Indivisual.css";
import CreateManager from "./CreateManger";
import { IoMdDownload } from "react-icons/io";
import { GoHome } from "react-icons/go";
import { Link } from "react-router-dom";
import { getAllManager } from "../../../api/ManagerRequestApi";
import ErrorLogout from "../../../helpers/ErrorLogout";
import Pagination from "../../common/Pagination";
import * as XLSX from 'xlsx';

const Manager = () => {

  const [managerAction, setManagerAction] = useState({ type: null, id: null });
  const [loader, setLoader] = useState(false)

  const [totalPages, setTotalPages] = useState(5);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [trigger, setTrigger] = useState(false);


  const [user, setUser] = useState([]); // Update initial state to '
  const { Option } = Select;
  const [selectedUsers, setSelectedUsers] = useState([]);


  const handleManagerAction = (type, id = null) => {
    setManagerAction({ type, id });
  };

  const handleSelectUser = (user) => {
    setSelectedUsers(prevSelectedUsers => {
      const isSelected = prevSelectedUsers.some(selectedUser => selectedUser._id === user._id);
      if (isSelected) {
        return prevSelectedUsers.filter(selectedUser => selectedUser._id !== user._id);
      } else {
        return [...prevSelectedUsers, user];
      }
    });
  };


  const handleDownloadData = () => {
    if (selectedUsers.length === 0) {
      message.warning('Please select at least one manager to download.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(selectedUsers.map(manager => ({
      Name: manager.name,
      Email: manager.email,
      Phone: manager.phone || 'N/A',
      Roles: manager.role.join(', '),
      Location: manager.location.join(', '),
      Address: manager.address,
      Status: manager.status,
      'Created At': new Date(manager.createdAt).toLocaleDateString(),
      'Updated At': new Date(manager.updatedAt).toLocaleDateString()
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Managers");

    XLSX.writeFile(workbook, "selected_managers.xlsx");

    message.success(`Data for ${selectedUsers.length} manager(s) downloaded successfully.`);
  };


  const handleCancelSelection = () => {
    setSelectedUsers([]);
    message.info('Selection cancelled.');
  };


  useEffect(() => {
    const bidsCall = async () => {
      try {
        setLoader(true);
        const { data } = await getAllManager({
          // q: searchUser,
          page: currentPage,
          limit: limit,

        });

        if (data?.success) {
          setUser(data?.managerList);
          setTotalPages(data?.totalPages);
          setCurrentPage(data?.currentPage);
          setTotalItems(data?.totalItems);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        console.log(error);
        console.log(error.message);
        if (error?.response?.status === 401) {
          ErrorLogout(error);
        }
      }
    };
    bidsCall();
  }, [limit, currentPage, trigger]);


  if (managerAction.type) {
    return <CreateManager page={managerAction.type} id={managerAction.id} />;
  }

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleLimit = (e) => {
    const selectedValue = parseInt(e.target.value); // Convert value to integer if needed
    setLimit(selectedValue); // Update state with the selected value
    setCurrentPage(1);
  }


  const columns = [
    {
      title: 'Select',
      dataIndex: 'select',
      width: 60,
      render: (_, record) => (
        <Checkbox
          checked={selectedUsers.some(user => user._id === record._id)}
          onChange={() => handleSelectUser(record)}
        />
      ),
    },
    {
      title: (
        <div className="flex items-center">
          Manager Name
          <SearchOutlined className="ml-2 text-[#9FACF0]" />
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="flex items-center">
          <img
            src={record.profileImage}
            alt="User avatar"
            className="w-8 h-8 rounded-full mr-2"
          />
          <span className="text-[#313131] text-[13px] font-medium">{text}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center">
          Ranking
          <FaFilter className="ml-2 text-[#9FACF0]" />
        </div>
      ),
      dataIndex: "ranking",
      key: "ranking",
      render: (ranking) => (
        <div className="flex items-center">
          <Rate
            disabled
            defaultValue={ranking}
            style={{ fontSize: "18px", color: "#003C98" }}
          />
          <a href="#" className="ml-2 text-[#1955EB] text-[11px]">
            Reset
          </a>
        </div>
      ),
    },
    {
      title: (
        <div className="flex items-center">
          Roles
          <FaFilter className="ml-2 text-[#9FACF0]" />
        </div>
      ),
      dataIndex: "role",
      key: "role",
      render: (roles) => (
        <Select
          mode="multiple"
          className="w-40 text-[#4A4A4C] text-[14px] rounded placeholder-[#4A4A4C]"
          placeholder="Select an option"
          defaultValue={roles}
        >
          <Option value="supervisor">Supervisor</Option>
          <Option value="Manager">Manager</Option>
          <Option value="Survey Requests">Survey Requests</Option>
        </Select>
      ),
    },
    {
      title: (
        <div className="flex items-center">
          Location
          <FaFilter className="ml-2 text-[#9FACF0]" />
        </div>
      ),
      dataIndex: "location",
      key: "location",
      render: (locations) => (
        <Select
          mode="multiple"
          className="w-40 text-[#4A4A4C] text-[14px] rounded placeholder-[#4A4A4C]"
          placeholder="Select an option"
          defaultValue={locations}
        >
          <Option value="mangalore">Mangalore</Option>
          <Option value="bangalore">Bangalore</Option>
          <Option value="goa">Goa</Option>
        </Select>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="flex space-x-2">
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => handleManagerAction('edit', record._id)}
              className="bg-[#1955EB] text-white hover:border-[#1955EB] hover:bg-[#1945E0]"
            />
          </Tooltip>
          <Tooltip title="View">
            <Button
              icon={<MdBlockFlipped />}
              className="bg-[#4A4A4C] text-white hover:border-[#4A4A4C] hover:bg-[#3A3A3C]"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              icon={<DeleteOutlined />}
              className="bg-[#FF4545] text-white hover:border-[#FF4545] hover:bg-[#FF3535]"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="flex flex-wrap justify-between items-center mb-4 gap-4">
          <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
            <Link to="/">
              {" "}
              <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" />
            </Link>
            <span className="ml-2 text-[##000000] text-base md:text-lg font-medium">
              / Managers
            </span>

            <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
              {selectedUsers.length > 0 && (
                <>
                  <button
                    icon={<IoMdDownload className='text-[#1955EB] text-[20px]' />}
                    onClick={handleDownloadData}
                    className='text-[#1955EB] underline text-[20px] font-medium'
                  >
                    Download Selected ({selectedUsers.length})
                  </button>
                  <Button
                    onClick={handleCancelSelection}
                    className='text-[#FF4545] underline text-[20px] font-medium'
                  >
                    Cancel
                  </Button>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => handleManagerAction('create')}
              className="px-5 py-2 text-[16px] font-medium text-white bg-[#1955EB] hover:border-[#1955EB] border-[1px] rounded-md hover:bg-white hover:text-[#1955EB]"
            >
              + Create Group
            </button>
          </div>
        </div>
      </div>
      <Spin spinning={loader}>


        <Table
          pagination={false}
          columns={columns}
          dataSource={user}
          className="custom-table-header lg:w-full w-[250px] overflow-auto custom-pagination"
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalItem={totalItems}
          onPageChange={handlePageChange}
          handleLimit={handleLimit}

        />
      </Spin>

    </>
  );
};

export default Manager;
