import React, { useState } from 'react';
import { Table, Button, Checkbox, Tooltip, Modal, Input } from 'antd';
import { InfoCircleOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { FaFilter } from 'react-icons/fa';
import { LuArrowUpDown } from 'react-icons/lu';
import { GoHome } from "react-icons/go";
import { MdOutlineDone } from "react-icons/md";
import "./Indivisual.css";
import Userimg from "../../../img/userimg.svg"
import { MdBlockFlipped } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import GroupList from "./GroupList"
import { Link } from 'react-router-dom';
const BuyersGroups = () => {
  const [activeTab, setActiveTab] = useState('All 200');
  const tabs = ['All 200', 'Active 180', 'Inactive 19', 'Blocked 1'];
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [showGroupList, setShowGroupList] = useState(false);
  const handleOpenGroupList = () => {
    setShowGroupList(true);
  };

  if (showGroupList) {
    return <GroupList />;
  }
  const data = [
    {
      key: '1',
      groupName: 'Metasoft IT Group',
      status: 'Active',
      companyDomain: '@metasoftit.com',
      noOfMembers: 231,
      startedDate: '12 Sep 2021',
    },
    {
      key: '2',
      groupName: 'Tech Innovators',
      status: 'Inactive',
      companyDomain: '@techinnovators.com',
      noOfMembers: 120,
      startedDate: '15 Jan 2022',
    },
    {
      key: '3',
      groupName: 'Web Dev Warriors',
      status: 'Active',
      companyDomain: '@webdevwarriors.com',
      noOfMembers: 78,
      startedDate: '23 Feb 2022',
    },
    {
      key: '4',
      groupName: 'Marketing Mavericks',
      status: 'Blocked',
      companyDomain: '@marketingmavericks.com',
      noOfMembers: 45,
      startedDate: '01 Mar 2021',
    },
    {
      key: '5',
      groupName: 'Design Dynamos',
      status: 'Active',
      companyDomain: '@designdynamos.com',
      noOfMembers: 65,
      startedDate: '18 Apr 2021',
    },
    {
      key: '6',
      groupName: 'Data Science Squad',
      status: 'Inactive',
      companyDomain: '@datasciencesquad.com',
      noOfMembers: 95,
      startedDate: '30 May 2022',
    },
    {
      key: '7',
      groupName: 'AI Enthusiasts',
      status: 'Active',
      companyDomain: '@aienthusiasts.com',
      noOfMembers: 112,
      startedDate: '12 Jun 2022',
    },
    {
      key: '8',
      groupName: 'Product Managers',
      status: 'Blocked',
      companyDomain: '@productmanagers.com',
      noOfMembers: 33,
      startedDate: '22 Jul 2021',
    },
    {
      key: '9',
      groupName: 'Cybersecurity Experts',
      status: 'Active',
      companyDomain: '@cybersecurityexperts.com',
      noOfMembers: 84,
      startedDate: '05 Aug 2021',
    },
    {
      key: '10',
      groupName: 'Cloud Computing Crew',
      status: 'Inactive',
      companyDomain: '@cloudcomputingcrew.com',
      noOfMembers: 50,
      startedDate: '16 Sep 2022',
    },
  
  ];
  const columns = [
    {
      title: () => <Checkbox />,
      key: 'select',
      render: () => <Checkbox />,
      width: 50,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Group Name
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'groupName',
      key: 'groupName',
      render: text => (
        // onClick={handleOpenGroupList}
      //  <Link to="/buyer/GroupBuyer/GroupList">
         <div      onClick={handleOpenGroupList} className="flex items-center cursor-pointer"  >
          <img src={Userimg} alt="Group avatar" className="w-8 h-8 rounded-full mr-2"  />
          <span className="text-[#313131] text-[13px] font-medium">{text}</span>
        </div>
        // </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Status
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <span className={`px-2 py-1 rounded-full text-xs ${
          status === 'Active' ? 'text-green-600' : 
          status === 'Inactive' ? 'text-gray-600' : 
          'text-red-600'
        }`}>
          {status}
        </span>
      ),
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Company Domain
          <SearchOutlined className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'companyDomain',
      key: 'companyDomain',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          No of Members
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'noOfMembers',
      key: 'noOfMembers',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: (
        <div className="flex items-center gap-2">
          Started Date
          <LuArrowUpDown className="text-[#9FACF0]" />
        </div>
      ),
      dataIndex: 'startedDate',
      key: 'startedDate',
      render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: () => (
        <div className="flex space-x-2">
          <Tooltip title="Edit">
            <Button icon={<MdBlockFlipped />} className="bg-[#4A4A4C] text-white hover:border-[#0445C3]"/>
          </Tooltip>
          <Tooltip title="Delete">
            <Button icon={<DeleteOutlined />} className="bg-[#FF4545] text-white hover:border-[#FF4545]" />
          </Tooltip>
        </div>
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsSecondModalOpen(false); 
  };

  const handleCreate = () => {
    setIsModalOpen(false);
    setIsSecondModalOpen(true); 
  };

  return (
    <>
    <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
    <div className="mb-4  flex items-center text-sm md:text-base flex-wrap">
    <Link to="/">     <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
      <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
        / Buyers - Groups
      </span>
      <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
      <IoMdDownload className='text-[#1955EB] mt-1 text-[20px]' />
      <p className='text-[#1955EB] underline text-[20px] font-medium'>Download Data</p>
      </div>
    </div>

    <div className="flex flex-wrap justify-between items-center mb-4 gap-4">
      <div className="flex flex-wrap gap-2 border border-[#9FACF0] p-2 rounded-[8px]">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`px-4 py-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
              ${activeTab === tab 
                ? "bg-[#1955EB] text-white font-medium"
                : "bg-[#F6F8FF] text-black font-medium hover:bg-[#F6F8FF]"}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="flex flex-wrap gap-2">
        <button 
          onClick={showModal} 
          className="px-5 py-2 text-[16px] font-medium text-white bg-[#1955EB] hover:border-[#1955EB] border-[1px] rounded-md hover:bg-white hover:text-[#1955EB]">
          + Create Group
        </button>
      </div>
    </div>
</div>
    <div className="pt-[0px] space-y-10">
    {activeTab === 'All 200' && (
       <Table
       columns={columns}
       dataSource={data}
       pagination={{
         total: 55,
         current: current,
         pageSize: pageSize,
         showSizeChanger: false,
         itemRender: (page, type, originalElement) => {
           if (type === 'prev') {
             return <p className="custom-pagination">Prev</p>;
           }
           if (type === 'next') {
             return <p className="custom-pagination">Next</p>;
           }
           if (type === 'page') {
             return <p className="custom-pagination">{page}</p>;
           }
           return originalElement;
         },
         onChange: (page, size) => {
           setCurrent(page);
           setPageSize(size);
         },
         showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
         simple: true,
       }}
       className="custom-table-header lg:w-full w-[250px] overflow-x-auto custom-pagination"
     />
    )}
    </div>

      <Modal
        title={
          <div className="border-b border-[#E0E0E0] pb-3">
            <h2 className="text-[18px] font-semibold">Create Group</h2>
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="w-full max-w-md"
      >
        <div className="flex gap-4 mt-4">
          <div className="flex flex-col">
            <label className="text-[#4A4A4C] text-[14px] font-medium">Group Name</label>
            <Input placeholder="group name" className="w-full" />
          </div>
          <div className="flex flex-col">
            <label className="text-[#4A4A4C] text-[14px] font-medium">Domain</label>
            <Input placeholder="eg @gmail.com" className="w-full" />
          </div>
        </div>
        <div className="flex justify-between gap-3 mt-6">
          <Button 
            onClick={handleCancel} 
            className="bg-white border-[#313131] border-[1px] text-[16px] font-semibold text-[#313131] w-full"
          >
            Discard
          </Button>
          <Button 
            type="primary" 
            onClick={handleCreate} 
            className="text-white bg-[#1955EB] text-[16px] font-semibold w-full"
          >
            Create
          </Button>
        </div>
      </Modal>

      <Modal
        open={isSecondModalOpen}
        onCancel={handleCancel}
        footer={null}
        className=""
    
      >
        <div className="flex flex-col items-center py-3">
          <div className="flex items-center mb-4">
            <MdOutlineDone className="text-white p-1 rounded-full bg-[#3AB927] text-4xl" />
          </div>
          <div className="text-center text-[16px] font-medium">
            <p className="text-[#C7C7C7] text-[16px] font-medium">Metasoft IT Solution</p>
            <p className="text-[#313131] text-[24px] font-medium mt-2">Group Created Successfully</p>
          </div>
          <div className="flex justify-center mt-6">
            <Button onClick={handleCancel} className="bg-[#313131] h-10 text-white text-[18px] font-medium">
              Dismiss
            </Button>
          </div>
        </div>
      </Modal>
</>
  );
};

export default BuyersGroups;
