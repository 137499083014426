import { useState, useEffect, useRef } from 'react';
// import car1 from "../../assets/images/car1.webp";
// import car2 from "../../assets/images/car2.webp";
// import car3 from "../../assets/images/car3.webp";
// import car4 from "../../assets/images/car4.webp";
// import car5 from "../../assets/images/car5.webp";

import Leftarrowgallery from "../../../assets/icons/leftarrowgallery.svg";
import Rightarrowgallery from "../../../assets/icons/rightarrowgallery.svg";
import PropTypes from "prop-types";

// const images = [
//   { src: car1, alt: 'Orange sports car' },
//   { src: car2, alt: 'Car dashboard' },
//   { src: car3, alt: 'Blue sports car' },
//   { src: car4, alt: 'Side view of orange car' },
//   { src: car5, alt: 'Car interior' },
// ];

const BidGallery = ({ isOpen, onClose,images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const thumbnailsRef = useRef(null);
  const thumbnailWidth = 168;

  const nextImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % images.length;
      scrollThumbnails(newIndex);
      return newIndex;
    });
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + images.length) % images.length;
      scrollThumbnails(newIndex);
      return newIndex;
    });
  };

  const scrollThumbnails = (index) => {
    if (thumbnailsRef.current) {
      const newOffset = -thumbnailWidth * index + (window.innerWidth / 4) - (thumbnailWidth / 4);
      thumbnailsRef.current.style.transform = `translateX(${newOffset}px)`;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') prevImage();
      if (event.key === 'ArrowRight') nextImage();
      if (event.key === 'Escape') onClose();
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="rounded-lg p-4 max-w-4xl w-full mx-4">
        <div className="relative">
          <div className="absolute top-2 right-2 z-10">
            <button
              onClick={onClose}
              className="text-gray-500 bg-white rounded-full hover:text-gray-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <img
            src={images[currentIndex]}
            alt={`images${[currentIndex]}`}
            className="w-full h-[70vh] rounded-lg shadow-lg object-cover"
          />
        </div>
        <div className="relative mt-4 overflow-hidden">
          <div
            ref={thumbnailsRef}
            className="flex space-x-2 transition-transform duration-300 ease-in-out"
            style={{ width: `${images.length * thumbnailWidth}px` }}
          >
            {images.map((image, index) => (
              <div key={index} className="flex-shrink-0">
                <button
                  onClick={() => {
                    setCurrentIndex(index);
                    scrollThumbnails(index);
                  }}
                  className={`w-44 h-36 object-cover rounded-md overflow-hidden ${
                    index === currentIndex ? 'border-2 border-white' : ''
                  }`}
                >
                  <img
                    src={image}
                    className="object-cover w-44 h-36"
                  />
                </button>
              </div>
            ))}
          </div>
          <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex items-center justify-between w-40 ml-6 pointer-events-none">
            <button
              onClick={prevImage}
              className="hover:bg-opacity-75 pointer-events-auto absolute left-0 transform -translate-x-1/2"
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <img src={Leftarrowgallery} className="w-[45px] h-[45px]" alt="left" />
            </button>
            <button
              onClick={nextImage}
              className="hover:bg-opacity-75 pointer-events-auto absolute right-0 transform translate-x-1/2"
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <img src={Rightarrowgallery} className="w-[45px] h-[45px]" alt="right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

BidGallery.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  images: PropTypes.object,
};

export default BidGallery;
