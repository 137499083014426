import PropTypes from "prop-types";

const Pagination = ({ currentPage, totalPages,limit,totalItem, onPageChange,handleLimit }) => {
  return (
    <div className="flex items-center justify-between p-4">
      <div className="text-[16px] font-normal text-[#4C4C4C]">
        Showing {((currentPage-1)*limit)+1} to {(currentPage*limit)} of {totalItem} entries
      </div>
      <div className="flex items-center space-x-2">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
         className="px-2 py-1 text-sm text-[#313131] text-[16px] font-semibold  disabled:text-[#878787]"
        >
          Prev
        </button>
        <div className="flex items-center space-x-1">
            <span className='text-[#4C4C4C] font-normal text-[16px]'>Page</span>
          <input
            type="number"
            min="1"
            max={totalPages}
            value={currentPage}
            onChange={(e) => onPageChange(Number(e.target.value))}
            className="w-10 px-2 py-1 text-sm text-center border rounded"
          />
          <span>of {totalPages}</span>
        </div>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-2 py-1 text-sm text-[#313131] text-[16px] font-semibold  disabled:text-[#878787]"
        >
          Next
        </button>
      </div>
      <div className="text-sm">
        <select className="p-1 text-[16px] font-normal text-[#313131] border rounded" value={limit} onChange={handleLimit}>
          <option value={10}>10 / Page</option>
          <option value={20}>20 / Page</option>
          <option value={50}>50 / Page</option>
        </select>
      </div>
    </div>
  );
};

Pagination.propTypes = {
    totalPages: PropTypes.object,
    currentPage: PropTypes.object,
    onPageChange: PropTypes.object,
    limit: PropTypes.object,
    totalItem: PropTypes.object,
    handleLimit: PropTypes.object,
  };
export default Pagination;