import moment from 'moment'
import React, { useState } from 'react'
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai'
import { FaEllipsisV, FaGavel } from 'react-icons/fa'
import { LuCircleDashed } from 'react-icons/lu'
import { RiCameraLensFill } from 'react-icons/ri'
import { useTimer } from "react-timer-hook";
import BidGallery from './BidGallery'
import { updateBidStatus } from '../../../api/BidRequest'
import ErrorLogout from '../../../helpers/ErrorLogout'
import { Modal } from 'antd';


function ProductCard({ item, trigger, setTrigger, setShowSingleProduct, setBidID }) {
    const [dropdowns, setDropdowns] = useState({});
    const [isGalleryOpen, setGalleryOpen] = useState(false);
    const openGallery = () => setGalleryOpen(true);
    const closeGallery = () => setGalleryOpen(false);

    const expiryTimestamp = new Date(item?.bidEnd);
    const { seconds, minutes, hours, days, isRunning } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn("onExpire called"),
    });
    const handleToggleDropdown = (id) => {
        setDropdowns((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };


    // ------ HOLD CANCLE----


    const handleUpdateStatus = async (id, status) => {
        try {
            Modal.confirm({
                title: `Confirm ${status.charAt(0).toUpperCase() + status.slice(1)} Status`,
                content: `Are you sure you want to ${status} this bid? This action cannot be undone.`,
                onOk: async () => {
                    try {
                        const { data } = await updateBidStatus(id, status)
                        setTrigger(!trigger)
                        Modal.success({
                            title: 'Status Updated Successfully',
                            content: `The bid status has been updated to ${status} successfully.`,
                        });
                    } catch (error) {
                        console.log(error);
                        console.log(error.message);
                        if (error?.response?.status === 401) {
                            ErrorLogout(error);
                        }
                    }
                },
                onCancel: () => {
                    console.log('Cancel');
                },
            });
        } catch (error) {
            console.log(error);
            console.log(error.message);
            if (error?.response?.status === 401) {
                ErrorLogout(error);
            }
        }
    }
    return (
        <div key={item.id} className="flex flex-col relative md:flex-row justify-start items-center p-4 bg-white shadow-md rounded-lg border border-gray-200">
            {/* Image Section */}
            <div className='w-full md:w-1/4 mb-4 md:mb-0'>
                <div className="flex-shrink-0">
                    <img
                        src={item.thumbnailImage}
                        alt="Dodge Challenger Hellcat"
                        // className="w-full md:w-40 h-auto object-cover rounded-lg"
                        className="h-[200px] w-full object-cover rounded-lg "
                    />
                    <div className="mt-4">
                        <button onClick={openGallery} className="flex items-center justify-center px-4 py-2 bg-[#D2DEFF] w-full text-[20px] font-medium text-[#313131] rounded-lg">
                            <RiCameraLensFill className="w-5 h-5 mr-2" />
                            Photos
                        </button>
                        <BidGallery
                            isOpen={isGalleryOpen}
                            onClose={closeGallery}
                            images={item?.productImage}
                        />
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div className="flex-1 lg:w-2/3 px-0 md:px-6">
                <h2 onClick={() => {
                    setShowSingleProduct(true)
                    setBidID(item._id)
                }} className="text-[20px] md:text-[24px] font-semibold text-start text-[#313131] cursor-pointer">
                    {item.productName}
                </h2>
                <div className="flex flex-wrap space-x-6 mt-3">
                    <span className="bg-[#EAEFFF] text-[#0445C3] text-[12px] font-semibold px-2 py-1 rounded-[2px]">Lot No {item?._id}</span>
                    <span className="bg-[#EAEFFF] text-[#313131] text-[12px] font-semibold px-2 py-1 rounded-[2px]">{item?.productName}</span>
                    <span className="bg-[#EAEFFF] text-[#313131] text-[12px] font-semibold px-2 py-1 rounded-[2px]">{item?.category}</span>
                    {/* <span className="bg-[#EAEFFF] text-[#313131] text-[12px] font-semibold px-2 py-1 rounded-[2px]">{item.owner}</span> */}
                </div>

                <div className="w-full md:w-[400px] border-t-[1px] border-[#C7C7C7] mt-5">
                    <div className="flex flex-col md:flex-row items-start md:items-center justify-between space-x-4 mt-4 text-gray-600">
                        <div className="flex items-center space-x-3">
                            <AiOutlineCalendar className="w-5 h-5 text-[#313131] font-semibold" />
                            <span className='text-[#000000] text-[16px] font-semibold'>End Date:</span>
                            <span className="font-normal text-[16px] text-[#000000]">{moment(item?.bidEnd).format("DD-MM-YYYY")}
                            </span>
                        </div>
                        <div className="flex items-center space-x-3 mt-2 md:mt-0">
                            <FaGavel className="w-5 h-5 text-[#313131] font-semibold" />
                            <span className='text-[#000000] text-[16px] font-semibold'>Bids:</span>
                            <span className="font-normal text-[16px] text-[#000000]">{item.bidCount}</span>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row items-start md:items-center mt-5 justify-between space-x-4 text-gray-600">
                        <div className="flex items-center space-x-3">
                            <AiOutlineClockCircle className="w-4 h-4" />
                            <span className='text-[#000000] text-[16px] font-semibold'>Time Left:</span>
                            <span className="font-normal text-[16px] text-[#000000]">   {isRunning
                                ? `${days}d: ${hours}h: ${minutes}m: ${seconds}s`
                                : "Expired"}</span>
                        </div>
                        <div className="flex items-center space-x-3 mt-2 md:mt-0">
                            <LuCircleDashed className="w-5 text-[#000000] h-5" />
                            <span className="font-semibold text-[16px] text-[#01A208]">{item.bidStatus}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bid Section */}
            <div className="flex border-[#C7C7C7] lg:border-l-[1px] lg:w-1/5 flex-col items-center mt-4 md:mt-0">
                <div>
                    <div className="text-[#313131] text-start text-[14px] font-normal">
                        Current Bid
                    </div>
                    <div className="text-[#313131] text-start text-[32px] font-medium">
                        {item.bidLivePrice}
                    </div>
                </div>
                <div className='mt-5'>
                    <div className="mt-2 text-start text-[#878787] text-[14px] font-normal">
                        Starting Bid
                    </div>
                    <div className="text-[#878787] text-start text-[32px] font-medium">
                        {item.bidInitialPrice}
                    </div>
                </div>
            </div>

            <div className='absolute right-4 top-5'>
                <button
                    className="bg-[#F6F8FF] p-2 rounded-[4px] text-gray-500 hover:text-gray-700"
                    onClick={() => handleToggleDropdown(item._id)}
                >
                    <FaEllipsisV />
                </button>


                {dropdowns[item._id] && (
                    <div className="bg-white absolute right-0 shadow-md rounded-md">
                        <button onClick={() => console.log(item._id, "product id")
                        } className="flex items-center w-full px-3 py-2 text-[14px] border-b-[1px] font-semibold text-[313131] hover:bg-gray-100">
                            Edit
                        </button>
                   
                        <button
                            className="flex items-center w-full px-3 py-2 text-[14px] text-[313131] font-semibold border-b-[1px] hover:bg-gray-100"
                            onClick={() => handleUpdateStatus(item._id, item.bidStatus === "hold" ? "live" : "hold")}
                        >
                            {item.bidStatus === "hold" ? "Unhold" : "Hold"}
                        </button>
                        <button
                            className="flex items-center w-full px-3 py-2 text-[14px] text-[313131] font-semibold border-b-[1px] hover:bg-gray-100"
                            onClick={() => handleUpdateStatus(item._id, item.bidStatus === "cancel" ? "live" : "cancel")}
                        >
                            {item.bidStatus === "cancel" ? "Uncancel" : "Cancel"}
                        </button>
                   
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductCard